import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from 'components/ui/StrictModeDroppable';
import DynamicBoardTask from './Task';
import DragAndDrop from 'assets/js/classes/DragAndDrop';
import { stateUpdateBuckets, useDynamicBoardDispatch, useDynamicBoardState } from 'contexts/DynamicBoardContext';
import { stateUpdateDataset, useSearchBarDispatch, useSearchBarState } from 'contexts/SearchBarContext';
import { NavLink } from 'react-router-dom';
import { updateSweetAlert, useSweetAlertDispatch } from 'contexts/SweetAlertContext'
import SwalClass from 'assets/js/classes/SweetAlert'

class DropZoneHeight {
  static HEIGHT_THRESHOLD = 5;
  static MARGIN = 8;  // 8 compensates for bottom margin my-2
}

export default function DynamicBoardColumn({ bucket, index }) {
  const [editTitle, setEditTitle] = useState(false);
  const [bucketTitle, setBucketTitle] = useState(bucket.title);

  // CONTEXT PARAMS
  const sweetAlertDispatch = useSweetAlertDispatch();

  // DYNAMIC BOARD CONTEXT
  const { buckets } = useDynamicBoardState();
  const dynamicBoardDispatch = useDynamicBoardDispatch();
  
  // SEARCH BAR CONTEXT
  const { dataset } = useSearchBarState();
  const searchBarDispatch = useSearchBarDispatch();

  // DELETE BUCKET
  const handleDeleteBucket = () => {
    const dnd = new DragAndDrop(buckets);
    const idx = dnd.getBucketIndex(bucket.id);

    // TODO: CHECK IF BUCKET CONTAINS TASKS. ARCHIVE TASKS
    console.log(buckets[idx]);

    // REMOVE BUCKET FROM BUCKETS ARRAY
    buckets.splice(idx, 1);
    dataset.splice(idx, 1);
    stateUpdateBuckets(dynamicBoardDispatch, { buckets });
    stateUpdateDataset(searchBarDispatch, { dataset });

    // TODO: API CALL TO ARCHIVE TASKS
    // TODO: API CALL TO CHANGE BUCKET STATE
  }

  const throwAlert = e => {
    e.preventDefault();

    updateSweetAlert(sweetAlertDispatch, { alert: new SwalClass(
      'Delete this list?', 
      bucketTitle,
      { onConfirm: handleDeleteBucket, type: 'danger' })
    });
  }

  // EDIT TITLE
  const handleTitleBlurKeyup = e => {
    if(e.key === 'Escape') {
      // DONT SAVE CHANGES
      setBucketTitle(bucket.title);
      setEditTitle(false);
      return;
    }

    if(e.type === 'blur' || e.key === 'Enter') {
      // SAVE CHANGES
      const dnd = new DragAndDrop(buckets);
      const idx = dnd.getBucketIndex(bucket.id);
      buckets[idx].title = e.target.value;
      dataset[idx].title = e.target.value;

      stateUpdateBuckets(dynamicBoardDispatch, { buckets });
      stateUpdateDataset(searchBarDispatch, { dataset });
      setEditTitle(false);

      // TODO: API CALL
      return;
    }

    // UPDATE VALUE ON CHANGE
    setBucketTitle(e.target.value);
  }

  // BUCKET HEIGHT
  const handleResize = () => {
    const dz = document.getElementsByClassName('bucketDropZone');
    console.log({dz})
    Object.values(dz).map(dz => {
      const maxHeight = window.innerHeight - dz.getBoundingClientRect().top - DropZoneHeight.MARGIN;
      dz.style.maxHeight = `${maxHeight}px`;  // 8 compensates for bottom margin my-2
      dz.style.overflowY = dz.getBoundingClientRect().height < (maxHeight - DropZoneHeight.HEIGHT_THRESHOLD) ? 'hidden' : 'scroll';
    });
  }
  window.addEventListener('resize', handleResize);
  useEffect(handleResize);

  return (
    <Draggable draggableId={`draggable-bucket-${bucket.id}`} index={index}>
      {(provided, snapshot) => (
        <Col xs={12} md={6} lg={5} xl={4}
          {...provided.draggableProps}
          ref={provided.innerRef}>

          <Card className={`bucketCard border-0 pt-2 my-2 align-self-start ${snapshot.isDragging ? 'shadow' : 'shadow-sm'}`}>
            <span className="d-flex align-items-stretch">

              <div className="p-2 transparentBorder" {...provided.dragHandleProps}>
                <FontAwesomeIcon icon={['fas', 'grip-dots-vertical']} fixedWidth />
              </div>
              
              {editTitle
                ? <Form noValidate autoComplete="off" className="pr-2 w-100" onSubmit={e => e.preventDefault()}>
                    <Form.Control type="text" value={bucketTitle} className="noBorder border-0 shadow-sm" 
                      autoFocus={editTitle} tabIndex={0} onChange={handleTitleBlurKeyup} 
                      onBlur={handleTitleBlurKeyup} onKeyUp={handleTitleBlurKeyup} />
                  </Form>
                : <>
                    <div className="bucketTitle p-2 pl-0 flex-fill" onClick={() => setEditTitle(true)}>{bucket.title}</div>
                  
                    <Dropdown align="end" className="d-flex align-items-center">
                      <Dropdown.Toggle variant="link" className="rounded-0 align-self-stretch">
                        <FontAwesomeIcon icon={['fas', 'ellipsis']} color="#495057" fixedWidth />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>

                        <Dropdown.Item as={NavLink} end to={`/projects/new-task/${bucket.slug}`}>
                          <FontAwesomeIcon icon={['fas', 'rectangle-history-circle-plus']} fixedWidth className="mr-2" />New Task
                        </Dropdown.Item>

                        <Dropdown.Item onClick={throwAlert} className="text-danger">
                          <FontAwesomeIcon icon={['fas', 'trash']} fixedWidth className="mr-2" />Delete List
                        </Dropdown.Item>
                        
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
              }
            </span>

            <StrictModeDroppable droppableId={`droppable-bucket-${bucket.id}`} type={DragAndDrop.TASKS}>
              {(provided, snapshot) => (
                <div className="bucketDropZone py-2 pl-2 pr-1" style={snapshot.isDraggingOver ? { background: 'rgba(247, 185, 36, 0.1)' } : {}}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>

                  {bucket.tasks.map((task, index) => <DynamicBoardTask key={task.id} task={task} index={index} />)}
      
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </Card>
        </Col>
      )}
    </Draggable>
  )
}