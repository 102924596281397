import { Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ConfirmationAlert({ className, message, icon }) {
  return (
    <Alert show={Object.keys(message).length > 0} variant="success" className={String(className).concat(" d-flex align-items-center")}>
      <FontAwesomeIcon icon={['far', icon ?? 'circle-check']} fixedWidth size="2x" />
      <div className="ml-4">
        <p>{message?.title ?? 'Success!'}</p>
        <p className="m-0" style={{ whiteSpace: 'break-spaces' }}>{message?.body}</p>
      </div>
    </Alert>
  );
};