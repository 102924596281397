import React from 'react';
import { StaticBoardProvider } from 'contexts/StaticBoardContext';
import { SearchBarProvider } from 'contexts/SearchBarContext';

export default function StaticBoardWrapper(props) {
  return (
    <StaticBoardProvider>
      <SearchBarProvider>
        {props.children}
      </SearchBarProvider>
    </StaticBoardProvider>
  )
}