import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Form } from 'react-router-dom'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import FormButton from 'components/forms/FormButton'
import { FormControlProvider } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import { Actions } from 'assets/js/classes/MemberButton'

export default function MemberButton({ user }) {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  // STATE PARAMS
  const [addRemoveBtns, setAddRemoveBtns] = useState(false);

  return (
    <Form onSubmit={undefined}>
      { addRemoveBtns
        ? <span>
            <FormControlProvider>
              <FormButton
                disabledProp={!Boolean(Object.values(task).length) || Boolean(Number(task?.archived))}
                modelContext={{ user, action: ModalTaskClass.isUserAssignee(task, user) ? Actions.REMOVE : Actions.ADD, task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
                onClick={ModalTaskClass.handleUpdateAssignee}
                type="submit"
                variant={ModalTaskClass.isUserAssignee(task, user) ? 'warning' : 'primary'}
              >{ModalTaskClass.isUserAssignee(task, user) ? 'Remove' : 'Add'}
              </FormButton>
            </FormControlProvider>

            <Button 
              className="mx-1" 
              disabled={!Boolean(Object.values(task).length) || Boolean(Number(task?.archived))}
              onClick={() => setAddRemoveBtns(addRemoveBtns => !addRemoveBtns)}
              variant="light" 
            >Cancel
            </Button>
          </span>
        : <Button
            className="w-100 text-left"
            disabled={!Boolean(Object.values(task).length) || Boolean(Number(task?.archived))}
            onClick={() => setAddRemoveBtns(addRemoveBtns => !addRemoveBtns)}
            variant="light"
          >{user.name}
          </Button>
      }
      
    </Form>
  )
}