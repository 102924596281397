import { useEffect } from 'react'
import Swal from 'react-bootstrap-sweetalert';
import { updateSweetAlert, useSweetAlertDispatch, useSweetAlertState } from 'contexts/SweetAlertContext'
import SwalClass from 'assets/js/classes/SweetAlert'

export default function SweetAlert() {
  const { alert } = useSweetAlertState();
  const sweetAlertDispatch = useSweetAlertDispatch();

  useEffect(() => {

    updateSweetAlert(sweetAlertDispatch, { alert: new SwalClass('', '', { show: false }) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    if(typeof alert.onCancel === 'function') alert.onCancel();

    updateSweetAlert(sweetAlertDispatch, { alert: {...alert, show: false} });
  }

  const handleConfirm = () => {
    if(typeof alert.onConfirm === 'function') alert.onConfirm();

    updateSweetAlert(sweetAlertDispatch, { alert: {...alert, show: false} });
  }

  return (
    <>
      {alert.show && 
        <Swal 
          type={alert.type}
          title={alert.title}
          allowEscape={true}
          showCancel={alert.showCancel}
          confirmBtnBsStyle={alert.confirmBtnBsStyle}
          openAnim={alert.openAnim}
          onCancel={handleCancel}
          onConfirm={handleConfirm}>
          {alert.message}
        </Swal>
      }
    </>
  )
}