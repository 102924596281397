import { useEffect } from 'react';
import Toast from './Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import toastsData from 'data/toastAlerts.json';
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext';

export default function Toasts() {
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  useEffect(() => {
    // TODO: MAKE API CALL FOR TOASTS

    updateToasts(toastsDispatch, { toasts: toastsData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ToastContainer position="top-end" className="p-3" containerPosition="fixed">
      {toasts.map(toast => <Toast key={toast.id} toast={toast} />)}
    </ToastContainer>
  )
}