import { useEffect, useRef } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import { stateUpdateFormControl, useFormControlDispatch, useFormControlState } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import { useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import useStateCallback from 'hooks/useStateCallback'

export default function ClientEmail({  }) {
  // CONTEXT PARAMS - FORM CONTROL
  const { disabled } = useFormControlState();
  const formControlDispatch = useFormControlDispatch();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  // STATE PARAMS
  const [email, setEmail] = useStateCallback('');

  // REFERENCES
  const emailRef = useRef(null);

  useEffect(() => {
    task?.client?.email && setEmail(task.client.email);
  }, [task?.client?.email]);

  const handleKeyDown = e => {
    ModalTaskClass.handleEscapeKey(e, task?.client?.email, setEmail);
  }

  const handleSubmit = e => {
    if (! ModalTaskClass.handleEmptySubmit(e, emailRef, task?.client?.email, setEmail)) {
      ModalTaskClass.handleSubmit(e, emailRef, { task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }, { update: stateUpdateFormControl, dispatch: formControlDispatch }, { toasts, toastsDispatch });
    }
  }

  return (
    <>
      { task?.client
        ? <Form 
            autoComplete="off"
            className="my-1"
            noValidate 
            onSubmit={handleSubmit}
          >
            <InputGroup className="pl-2">
              <InputGroup.Text className="bg-white transparentBorder px-1">
                <FontAwesomeIcon icon={['far', 'at']} fixedWidth />
              </InputGroup.Text>
              <Form.Control
                className="transparentBorder rounded pl-1"
                disabled={Boolean(Number(task?.archived)) || disabled}
                name="client.email"
                onBlur={handleSubmit}
                onChange={e => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Client Email..."
                ref={emailRef}
                type="email"
                value={email}
              />
              <Button
                className="transparentBorder transparentBg rounded ml-1"
                disabled={[undefined, ''].includes(email)}
                onClick={undefined}
                variant="light"
              ><FontAwesomeIcon icon={['far', 'paper-plane']} fixedWidth />
              </Button>
            </InputGroup>
          </Form>
        : <Skeleton height={38} />
      }
    </>
  )
}