import { useRef } from 'react'
import { Form } from 'react-bootstrap'
import { stateUpdateFormControl, useFormControlDispatch, useFormControlState } from 'contexts/FormControlContext'
import { useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import useStateCallback from 'hooks/useStateCallback'

export default function FormCheck({ checked, className, disabledProp, modelContext, name, onChange, type }) {
  // CONTEXT PARAMS - FORM CONTROL
  const { disabled } = useFormControlState();
  const formControlDispatch = useFormControlDispatch();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  // STATE PARAMS
  const [val, setVal] = useStateCallback(checked);
  const ref = useRef(null);

  return (
    <Form.Check 
      className={className}
      checked={val}
      disabled={disabled || disabledProp}
      hidden={disabledProp}
      name={name}
      onChange={e => setVal(val => !val, () => onChange(e, ref, val, setVal, modelContext, { dispatch: formControlDispatch, update: stateUpdateFormControl }, { toasts, toastsDispatch }))}
      ref={ref}
      type={type ?? "checkbox"}
    />
  )
}