import Toast from 'assets/js/classes/Toast'

export default function handleToast(
  { title, message, autohide, sentiment, label, callback }, { toasts, toastsDispatch, updateToasts }
) {
  // THROW TOAST TO USER
  toasts.unshift(new Toast(
    title, 
    message, 
    { autohide, sentiment },
    { label, callback },
  ));
  updateToasts(toastsDispatch, { toasts });
}