import { Col, Row } from 'react-bootstrap'
import Main from 'layouts/Main'
import Balance from './Balance'
import TopUpForm from './Form'
import History from './History'

export default function TopUp() {
  return (
    <Main title="Top Up" subtitle="SMS" icon="message-sms">
      <Row className="mb-3">
        <Col sm={12} md={6} lg={4}>
          <Balance />
        </Col>
        <Col sm={12} md={6} lg={8}>
          <TopUpForm />
        </Col>
      </Row>

      <Row>
        <Col>
          <History />
        </Col>
      </Row>
    </Main>
  )
}