import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import { getCountryCallingCode, isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'assets/css/react-phone-number-input-override.css'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const InputComponent = React.forwardRef((props, ref) => <Form.Control {...props} ref={ref} />);

class ValidatePhone {
  static isValid(country, phone) {
    return phone === '' 
    || `+${country && getCountryCallingCode(country)}` === phone
    || isValidPhoneNumber(phone)
  }

  static isInvalid(country, phone) {
    return phone !== '' 
    && `+${country && getCountryCallingCode(country)}` !== phone
    && !isValidPhoneNumber(phone)
  }

  static resetValidity(target) {
    const element = target.form.elements.namedItem('phone_valid');
    element?.setCustomValidity('');
    element?.reportValidity();
  }
}

export default function InputPhone({ children, disabled, label, name, onBlur, onKeyDown, phone, setPhone, validated }) {
  const [country, setCountry] = useState('IE');

  return (
    <>
      <InputGroup className="mb-2 pl-2">
        <InputGroup.Text className="bg-white transparentBorder px-1">
          <FontAwesomeIcon icon={['far', 'phone']} fixedWidth />
        </InputGroup.Text>
        {/* <input 
          type="hidden" 
          ref={ref} 
          readOnly 
          value={phone.match(/\d/g)?.join('') ?? ''} 
          name={name ?? "phone"} 
        /> */}
        {children}
        <PhoneInput
          className="flex-fill"
          defaultCountry="IE"
          disabled={disabled}
          initialValueFormat={undefined}
          inputComponent={InputComponent}
          international
          limitMaxLength={true}
          numberInputProps={{
            className: `form-control rounded ${ValidatePhone.isValid(country, phone) ? 'transparentBorder' : ''}`,
            isValid: false && validated && ValidatePhone.isValid(country, phone),
            isInvalid: validated && ValidatePhone.isInvalid(country, phone),
            onKeyDown,
            onKeyUp: ({ target }) => ValidatePhone.resetValidity(target),
          }}
          onBlur={onBlur}
          onChange={value => setPhone(value ?? '')}
          onCountryChange={value => setCountry(value)}
          placeholder={label ?? "Enter phone number..."}
          value={phone}
        />
        <input 
          hidden
          name="phone_valid" 
          onChange={() => undefined}
          onInvalid={e => e.target.setCustomValidity('Invalid phone number.')}
          required 
          type="text" 
          value={ValidatePhone.isValid(country, phone) ? true : ''} 
        />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        <input type="hidden" readOnly value={JSON.stringify(phone && parsePhoneNumber(phone)) ?? ''} name="phone_country" />

        <Button
          className="transparentBorder transparentBg rounded ml-1"
          disabled={[undefined, ''].includes(phone) || !isValidPhoneNumber(phone)}
          // onClick={undefined}
          variant="light"
        ><FontAwesomeIcon icon={['far', 'comment-sms']} fixedWidth />
        </Button>

        <Button type="submit" hidden disabled={!isValidPhoneNumber(phone)}>
          Need for form submit on return.
          https://stackoverflow.com/a/35235768/6663785  
        </Button>
      </InputGroup>
    </>
  )
}