import { useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import BucketsClass from 'assets/js/classes/Buckets'
import DragAndDrop from 'assets/js/classes/DragAndDrop'
import { AxiosMethods } from 'assets/js/utilities/axios'
import { StrictModeDroppable } from 'components/ui/StrictModeDroppable'
import { stateSetScrollSnap, stateUpdateBuckets, useDynamicBoardDispatch, useDynamicBoardState } from 'contexts/DynamicBoardContext'
import { stateUpdateDataset, useSearchBarDispatch, useSearchBarState } from 'contexts/SearchBarContext'
import useApiCall from 'hooks/useApiCall'
import Main from 'layouts/Main'
import DynamicBoardColumn from './Column'
import scrollDrag from '../components/DynamicBoard/scrollDrag'
import DynamicBoardSettings from '../components/DynamicBoard/Settings'
import SearchBar from '../components/SearchBar'
import '../css/trello.css'
import DashboardSettings from './Settings'

export default function AllProjects() {
  // DYNAMIC BOARD CONTEXT
  const { buckets, scrollSnap } = useDynamicBoardState();
  const dynamicBoardDispatch = useDynamicBoardDispatch();
  
  // SEARCH BAR CONTEXT
  const { dataset } = useSearchBarState();
  const searchBarDispatch = useSearchBarDispatch();

  // STATE PARAMS
  const [loading, setLoading] = useState(true);

  const onDragEnd = ({ destination, source, type, draggableId }) => {
    stateSetScrollSnap(dynamicBoardDispatch, true);  // ENABLE SCROLL SNAP

    if(!destination) return;

    if(
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) return;

    const dnd = new DragAndDrop(buckets);
    const dndDataset = new DragAndDrop(dataset);

    switch(type) {
      case DragAndDrop.BUCKETS:
        dnd.moveBucket(destination, source);
        dndDataset.moveBucket(destination, source);
        // TODO: API call to update order
        break;
      case DragAndDrop.TASKS:
        dnd.moveTask(destination, source);
        dndDataset.moveTask(destination, {
          index: dndDataset.getTaskIndex(draggableId, source.droppableId),
          droppableId: source.droppableId
        });
        // TODO: API call to update order
        break;
      default: break;
    }
    stateUpdateBuckets(dynamicBoardDispatch, { buckets: dnd.buckets });
    stateUpdateDataset(searchBarDispatch, { dataset: dndDataset.buckets });
  }
  
  const onDragStart = () => stateSetScrollSnap(dynamicBoardDispatch, false);  // DISABLE SCROLL SNAP

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: ApiRoutes.BUCKETS,
    callback: ({ buckets }) => {
      stateUpdateBuckets(dynamicBoardDispatch, { buckets });
      stateUpdateDataset(searchBarDispatch, { dataset: structuredClone(buckets) });
    },
    setLoading,
  });

  console.log({buckets})

  return (
    <Main title="All Projects" subtitle="Dashboard" icon="square-kanban">

      <Row>
        <Col xs={12} sm={2} className="d-flex align-items-center justify-content-center">
          {/* <DynamicBoardSettings /> */}
          <DashboardSettings />
        </Col>
        <Col xs={12} sm={10}>
          <SearchBar 
            dataset={dataset} 
            stateUpdate={stateUpdateBuckets} 
            stateKey="buckets" 
            stateType={Array} 
            dispatch={dynamicBoardDispatch} 
            filterCallback={BucketsClass.filterByQuery} 
          />
        </Col>
      </Row>

      {/* BUCKETS LOADER */}
      {loading &&
        <Row className="flex-nowrap">
          {Array.from(Array(3), (_,i) =>
            <Col key={i} xs={12} md={6} lg={5} xl={4}>
              <Skeleton height={210} />
            </Col>
          )}
        </Row>
      }

      {/* BUCKETS */}
      {!loading && buckets?.length > 0 &&
        <DragDropContext 
          onDragEnd={onDragEnd} 
          onDragStart={onDragStart}
        >
          <StrictModeDroppable 
            droppableId="allBuckets" 
            direction="horizontal" 
            type={DragAndDrop.BUCKETS}
          >
            {(provided, snapshot) => (
              <Row 
                className="d-flex flex-nowrap flex-fill" 
                id="trello"
                {...provided.droppableProps}
                ref={provided.innerRef}
                onMouseDown={e => scrollDrag(e, stateSetScrollSnap, dynamicBoardDispatch)}
                style={{ userSelect: 'none', scrollSnapType: scrollSnap ? 'x mandatory' : 'none' }}>

                {buckets.map((bucket, index) => 
                  <DynamicBoardColumn 
                    key={bucket.id} 
                    bucket={bucket} 
                    index={index} 
                  />
                )}
                
                {provided.placeholder}
              </Row>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      }

    </Main>
  )
}