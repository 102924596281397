import { useEffect, useState } from 'react'
import handleApiCall from 'assets/js/utilities/handleApiCall'
import { useToastsDispatch, useToastsState } from 'contexts/ToastsContext'

export default function useApiCall({ apiMethod, apiRoute, callback, dataProp, formData, setLoading }) {
  // CONTEXT PARAMS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  useEffect(() => {
    handleApiCall({ apiMethod, apiRoute, callback, dataProp, formData, toasts, toastsDispatch })
      .then(() => setLoading !== undefined && setLoading(false));
  }, []);
}