import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import FrontendRoutes from 'assets/js/classes/FrontendRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { AxiosMethods } from 'assets/js/utilities/axios'
import SubmitBtn from 'components/forms/SubmitBtn'
import { stateLogout, useAuthDispatch } from 'contexts/AuthContext'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'

export default function LogoutForm() {
  // REACT ROUTER
  const navigate = useNavigate();

  // AUTH CONTEXT
  const authDispatch = useAuthDispatch();

  // SUBMIT BUTTON CONTEXT
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  const handleSubmit = async e => {
    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.POST, route: ApiRoutes.ACCOUNT_LOGOUT },
      { navigate, navigateUrl: FrontendRoutes.ACCOUNT_LOGIN }, 
      { spinnerWait: false });

    await submitter.validate({});

    if(submitter.success) stateLogout(authDispatch);
  }

  return (
    <Form noValidate validated={false} onSubmit={handleSubmit} className="w-50">
      <SubmitBtn 
        variant="dark"
        size="lg"
        className="w-100 rounded-0 py-3"
        icon={['fas', 'power-off']} 
        state={btnState}
        response={response} />
    </Form>
  )
}