import Logo from 'components/ui/Logo'

export default function Footer() {
  return (
    <div className="app-wrapper-footer mt-4">
      <div className="app-footer">
        <div className="app-footer__inner">
          <div className="app-footer-left">
            <ul className="nav">
              <li className="nav-item">
                <a href="https://repairware.ie" className="nav-link" target="_blank">
                  <Logo className="mr-2" textColor="#2c3e50" size="24" />
                  © {new Date().getFullYear()} Collaboro
                </a>
              </li>
            </ul>
          </div>
          <div className="app-footer-right" hidden>
            <ul className="nav">
              <li className="nav-item">
                <a href="#" className="nav-link">
                  {/* TODO: UPDATE LINK */}
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}