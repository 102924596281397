import { FormControlProvider } from 'contexts/FormControlContext'
import Client from './Client'
import Members from './Members'
import Notes from './Notes'
import { Checklists } from './Checklists/'
import ActivityLog from './ActivityLog/'

export default function Details() {
  return (
    // CLIENT, MEMBERS, NOTES, CHECKLISTS, ACTIVITY LOG
    <>
      <Client />

      <Members />

      <FormControlProvider>
        <Notes />
      </FormControlProvider>

      <Checklists />
      
      <ActivityLog />
    </>
  )
}