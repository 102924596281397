import { useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Dashboard from 'assets/js/classes/Dashboard'
import { AxiosMethods } from 'assets/js/utilities/axios'
import { FormControlProvider } from 'contexts/FormControlContext'
import FormButton from 'components/forms/FormButton'
import { stateSetScrollSnap, stateUpdateBuckets, useDynamicBoardDispatch, useDynamicBoardState } from 'contexts/DynamicBoardContext'
import { stateUpdateDataset, useSearchBarDispatch, useSearchBarState } from 'contexts/SearchBarContext'

export default function DashboardSettings() {
  // STATE PARAMS
  const [showDropdown, setShowDropdown] = useState(false);

  // CONTEXT - DYNAMIC BOARD
  const { buckets } = useDynamicBoardState();
  const dynamicBoardDispatch = useDynamicBoardDispatch();
  
  // CONTEXT - SEARCH BAR
  const { dataset } = useSearchBarState();
  const searchBarDispatch = useSearchBarDispatch();

  // FUNCTIONS
  const newBucketEffect = () => {
    // HIDE THE DROPDOWN
    setShowDropdown(false);

    // REMOVE SCROLL SNAP
    stateSetScrollSnap(dynamicBoardDispatch, false);

    // SCROLL TRELLO CONTAINER
    const $trello = document.getElementById('trello');
    const scrLft = $trello?.querySelector('div').offsetWidth;
    const duration = 200;

    Array.from(Array(100).keys()).map(i => setTimeout(
      () => $trello.scrollLeft = scrLft - (scrLft * i / 100), i / 100 * duration
    ));

    // RESTORE SCROLL SNAP
    setTimeout(() => stateSetScrollSnap(dynamicBoardDispatch, true), duration);
  }

  return (
    <Dropdown
      align="start"
      autoClose="outside"
      className="flex-fill align-self-stretch d-flex mb-2"
      onToggle={(show, meta) => setShowDropdown(!meta.source === 'rootClose')}
      show={showDropdown}
    >
      <Dropdown.Toggle
        className="align-self-stretch shadow-sm w-100 noCaret"
        onClick={() => setShowDropdown(state => !state)}
        variant="primary"
      >
        <FontAwesomeIcon fixedWidth icon={['far', 'gear']} />
        <FontAwesomeIcon fixedWidth icon={['fas', 'caret-down']} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Form 
          autoComplete="false"
          onSubmit={e => e.preventDefault()}
        >
          <FormControlProvider>
            <FormButton
              className="dropdown-item rounded-0"
              eventListeners={[
                {
                  event: `${AxiosMethods.POST}::${ApiRoutes.BUCKETS}`,
                  listener: newBucketEffect,
                }
              ]}
              loadStatus="Creating..."
              modelContext={{ 
                dashboard: {
                  dataset: buckets, 
                  dispatch: dynamicBoardDispatch,
                  prop: 'buckets',
                  update: stateUpdateBuckets,
                },
                searchbar: {
                  dataset,
                  dispatch: searchBarDispatch,
                  prop: 'dataset',
                  update: stateUpdateDataset,
                },
              }}
              onClick={Dashboard.handleNewBucket}
              variant="link"
            >
              <FontAwesomeIcon fixedWidth icon={['far', 'square-kanban']} className="mr-1" />
              New List/Dashboard
            </FormButton>
          </FormControlProvider>
        </Form>
      </Dropdown.Menu>
    </Dropdown>
  )
}