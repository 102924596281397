import { stateLogout, useAuthDispatch } from 'contexts/AuthContext'
import LogoutForm from './LogoutForm'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'

export default function LogInOut() {
  const authDispatch = useAuthDispatch();

  const handleLogout = e => {


    if(false) stateLogout(authDispatch)
  }

  return (
    <div className="widget-content-right ml-3">
      <SubmitBtnProvider>
        <LogoutForm />
      </SubmitBtnProvider>
    </div>
  )
}