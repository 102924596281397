import { useEffect } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'

export default function useNavigateAuthenticated(user) {
  // REACT ROUTER DOM
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = createSearchParams(search ?? '');
  const redirect = searchParams.get('r') ? decodeURIComponent(searchParams.get('r')) : null;
  searchParams.delete('r');
  const queries = searchParams.toString();

  useEffect(() => {
    if(user) navigate(`${redirect ?? '/'}?${queries}`);
    // eslint-disable-next-line
  }, [user]);
}