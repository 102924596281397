import React from 'react';

const DELAY = 500;

const SubmitBtnStateContext = React.createContext();
const SubmitBtnDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useSubmitBtnState() {
  const context = React.useContext(SubmitBtnStateContext);
  if(!context) throw new Error('useSubmitBtnState must be used in SubmitBtnProvider');
  return context;
}

function useSubmitBtnDispatch() {
  const context = React.useContext(SubmitBtnDispatchContext);
  if(!context) throw new Error('useSubmitBtnDispatch must be used in SubmitBtnProvider');
  return context;
}

function SubmitBtnProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { btnState: 'default', response: {} });

  return (
    <SubmitBtnStateContext.Provider value={state}>
      <SubmitBtnDispatchContext.Provider value={dispatch}>
        {props.children}
      </SubmitBtnDispatchContext.Provider>
    </SubmitBtnStateContext.Provider>
  )
}

function stateUpdateSubmitBtn(dispatch, data) {
  dispatch({...data});

  return new Promise(resolve => {
    setTimeout(() => resolve(true), DELAY);
  });
}

export { SubmitBtnProvider, stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState };