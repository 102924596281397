import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Archive from './Archive'
import Checklist from './Checklist'
import DeadlineDate from './DeadlineDate'
import DeadlineTime from './DeadlineTime'
import Members from './Members'
import Priority from './Priority'
import QRCode from './QRCode'
import Status from './Status'

export default function Actions() {
  return (
    // STATUS, PRIORITY, ADD TO PROJECT, JOB HOURS
    <>
      <Status />
      <Priority />

      <section className="mb-3">
        <h6 className="mb-1">
          <FontAwesomeIcon icon={['far', 'sliders']} fixedWidth /> ADD TO PROJECT
        </h6>

        <Checklist />
        <DeadlineDate />
        <DeadlineTime />
        <Members />
        <QRCode />
        <Archive />
      </section>
    </>
  )
}