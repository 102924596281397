import { useLayoutEffect } from 'react' 
import titleFromPath from 'assets/js/utilities/titleFromPath'

export default function useUpdatePageTitle(location) {
  useLayoutEffect(() => {
    // UPDATE THE WINDOW TITLE
    const path = window.location.href.toString().split(window.location.host)[1].split('?')[0].split(/[/]/);

    const title = titleFromPath(path).trim();
    document.title = `${title.length > 0 ? title : 'Home'} | ${process.env.REACT_APP_TITLE}`;
  }, [location]);
}