import { useState } from 'react'
import { Badge, Card, Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Sentiment } from 'assets/js/classes/BootstrapStyles'
import PaymentIntentStatus from 'assets/js/classes/StripePaymentIntentStatus'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import useApiCall from 'hooks/useApiCall'
import ApiChannels from 'assets/js/classes/ApiChannels'
import ApiEvents from 'assets/js/classes/ApiEvents'
import handleToast from 'assets/js/utilities/handleToast'
import useWebSocket from 'hooks/useWebSocket'
import { useTenantState } from 'contexts/TenantContext'

class TableStyles {
  static STATUSES = {
    [PaymentIntentStatus.REQUIRES_PAYMENT_METHOD]: Sentiment.WARNING,
    [PaymentIntentStatus.REQUIRES_CONFIRMATION]: Sentiment.DANGER,
    [PaymentIntentStatus.REQUIRES_ACTION]: Sentiment.DANGER,
    [PaymentIntentStatus.REQUIRES_CAPTURE]: Sentiment.DANGER,
    [PaymentIntentStatus.PROCESSING]: Sentiment.INFO,
    [PaymentIntentStatus.CANCELED]: Sentiment.DARK,
    [PaymentIntentStatus.SUCCEEDED]: Sentiment.SUCCESS,
  }
}

const columns = [
  {
    name: 'Top Up #',
    selector: row => row.id,
    sortable: true,
  },
  {
    id: 'date',
    name: 'Date',
    selector: row => row.created,
    sortable: true,
    format: row => moment(row.created, 'X').format('DD/MM/YYYY'),
  },
  {
    name: 'Status',
    selector: row => row.status,
    sortable: true,
    cell: row => <Badge bg={TableStyles.STATUSES[PaymentIntentStatus.SUCCEEDED] ?? Sentiment.WARNING} data-tag="allowRowEvents">{PaymentIntentStatus.SUCCEEDED}</Badge>,
  },
  {
    name: 'Amount',
    selector: row => row.amount,
    sortable: true,
    format: row => `${(row.amount * -1 / 100).toFixed(2)} ${row.currency.toUpperCase()}`
  },
];

export default function History() {
  // REACT ROUTER
  const navigate = useNavigate();

  // STATE PARAMS
  const [tableData, setTableData] = useState(null);

  // CONTEXT PARAMS - TENANT
  const { tenant } = useTenantState();

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: ApiRoutes.CASHIER_CUSTOMER_BALANCE,
    callback: ({ credit_transactions }) => setTableData(credit_transactions),
  });

  useWebSocket(ApiChannels.PRIVATE_TENANT(tenant?.id), [
    {
      events: [ApiEvents.CUSTOMER_BALANCE_CREDITED],
      callback: ({ transaction }) => setTableData(credit_transactions => [transaction, ...credit_transactions]),
    },
  ]);

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={['far', 'file-invoice']} fixedWidth className="mr-1" /> Top Up History
      </Card.Header>
      <Card.Body className="px-0">
        <DataTable
          columns={columns}
          data={tableData ?? []}
          defaultSortAsc={false}
          defaultSortFieldId="date"
          highlightOnHover
          onRowClicked={row => navigate(`history/${row.id}`)}
          pagination
          pointerOnHover
          progressComponent={<Spinner animation="border" size="lg" />}
          progressPending={!tableData}
        />
      </Card.Body>
    </Card>
  )
}