import { useSidebarState } from 'contexts/SidebarContext'

export default function AppContainer(props) {
  const sidebarState = useSidebarState();

  return (
    <div className={`app-container app-theme-gray body-tabs-shadow fixed-sidebar fixed-header ${sidebarState.open ? 'sidebar-mobile-open' : 'closed-sidebar'}`}>
      {props.children}
    </div>
  )
}