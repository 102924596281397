// https://stackoverflow.com/a/61860802/6663785
// https://medium.com/@thevirtuoid/extending-multiple-classes-in-javascript-2f4752574e65
// https://stackoverflow.com/a/35581512/6663785

const extender = (bases) => {
  class Bases {
    constructor() {
      bases.forEach(base => Object.assign(this, new base()));
    }
  }

  // ADD OBJECT PROTOTYPE METHODS
  bases.forEach(base => {
    Object.getOwnPropertyNames(base.prototype)
      .filter(prop => prop != 'constructor')
      .forEach(prop => Bases.prototype[prop] = base.prototype[prop])
  });

  // ADD OBJECT STATIC METHODS
  bases.forEach(base => {
    Object.getOwnPropertyNames(base)
      .filter(prop => typeof base[prop] === 'function')
      .forEach(prop => Bases[prop] = base[prop])
  });

  return Bases;
}

export default extender;