import Main from 'layouts/Main'
import { Card } from 'react-bootstrap'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'
import BusinessDetailsForm from './BusinessDetailsForm'

export default function BusinessDetails() {
  return (
    <Main title="Business Details" subtitle="Settings" icon="building">
      <Card>
        <Card.Body>
          <SubmitBtnProvider>
            <BusinessDetailsForm />
          </SubmitBtnProvider>
        </Card.Body>
      </Card>
    </Main>
  )
}