import { useEffect } from 'react'
import eventFrontendVersionUpdated from 'assets/js/utilities/eventFrontendVersionUpdated'
import Responsive from 'assets/js/classes/Responsive'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import { stateToggleSidebar, useSidebarDispatch, useSidebarState } from 'contexts/SidebarContext'
import useFrontendVersionCheckAPI from 'hooks/useFrontendVersionCheckAPI'
import useFrontendVersionCheckLocal from 'hooks/useFrontendVersionCheckLocal'
import useScrollEventsListeners from 'hooks/useScrollEventsListeners'
import useWebSocket from 'hooks/useWebSocket'
import ApiChannels from 'assets/js/classes/ApiChannels'
import ApiEvents from 'assets/js/classes/ApiEvents'

export default function Page({ children }) {
  // CONTEXT PARAMS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  // SIDEBAR CONTEXT
  const sidebarState = useSidebarState();
  const sidebarDispatch = useSidebarDispatch();

  // HOOKS
  useFrontendVersionCheckLocal();  // Checks for updated frontend version flag from local storage
  useFrontendVersionCheckAPI();  // Checks for updated frontend version from backend
  useWebSocket(ApiChannels.PUBLIC, [
    { 
      events: [ApiEvents.FRONTEND_VERSION_UPDATED],
      callback: (data) => eventFrontendVersionUpdated(data, { toasts, toastsDispatch, updateToasts }),
    },
  ]);  // Notifies the client if an update is available
  useScrollEventsListeners({ stateToggleSidebar, sidebarDispatch, sidebarState });  // Binds to scroll & touch events to control sidebar
  
  useEffect(() => {
    // TOGGLE THE SIDEBAR AFTER NAVIGATING
    if(sidebarState.open && window.innerWidth < Responsive.MOBILE_WIDTH_MAX) stateToggleSidebar(sidebarDispatch, { open: false });
  }, [children]);
  
  return (
    <>{children}</>
  );
}