import handleToast from './handleToast'

export default function eventTenantUpdated({ author, user, tenant, callback = (args) => undefined } = {}, { toasts, toastsDispatch, updateToasts }) {
  if(author?.id === user?.id) return;

  handleToast({
    title: 'Tenant Updated', 
    message: `${tenant?.name} was updated by ${author?.name} (${author?.email}). Would you like to get the latest update?`,
    autohide: false, 
    label: 'Get update', callback,
  }, { toasts, toastsDispatch, updateToasts });
}