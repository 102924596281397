import { useState } from "react"
import { Form, InputGroup } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ProjectsDropdown({ buckets, defaultBucket }) {
  // STATE PARAMS
  const [selected, setSelected] = useState(defaultBucket?.id ?? 0);

  const handleChange = e => setSelected(e.target.value);

  return (
    <section className="mb-3">
      <Form.Label htmlFor="selectBucket">
        Project / Area <span className="text-danger">*</span> <small>(select a project/area)</small>
      </Form.Label>
      <InputGroup hasValidation>
        <InputGroup.Text className="bg-light">
          <FontAwesomeIcon icon={['far', 'square-kanban']} fixedWidth />
        </InputGroup.Text>

        <Form.Select 
          id="selectBucket" 
          name="bucket" 
          aria-label="Select project"
          disabled={buckets.length === 0}
          value={selected}
          required
          onChange={handleChange}
        >
          {buckets.map(bucket => <option key={bucket.id} value={bucket.id}>{bucket.title}</option>)}
        </Form.Select>
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </InputGroup>
    </section>
  )
}