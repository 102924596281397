import React from 'react';

const TenantStateContext = React.createContext();
const TenantDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useTenantState() {
  const context = React.useContext(TenantStateContext);
  if(!context) throw new Error('useTenantState must be used in TenantProvider');
  return context;
}

function useTenantDispatch() {
  const context = React.useContext(TenantDispatchContext);
  if(!context) throw new Error('useTenantDispatch must be used in TenantProvider');
  return context;
}

function TenantProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { tenant: null });

  return (
    <TenantStateContext.Provider value={state}>
      <TenantDispatchContext.Provider value={dispatch}>
        {props.children}
      </TenantDispatchContext.Provider>
    </TenantStateContext.Provider>
  )
}

function updateTenant(dispatch, { tenant }) {
  dispatch({ tenant });
}

export { TenantProvider, updateTenant, useTenantDispatch, useTenantState };