export default class TwilioSmsStatus {
  // https://www.twilio.com/docs/sms/tutorials/how-to-confirm-delivery-php

  static ACCEPTED = 'accepted';
  static QUEUED = 'queued';
  static SENDING = 'sending';
  static SENT = 'sent';
  static FAILED = 'failed';
  static DELIVERED = 'delivered';
  static UNDELIVERED = 'undelivered';
  static RECEIVING = 'receiving';
  static RECEIVED = 'received';
  static READ = 'read';  // (WhatsApp only)
}