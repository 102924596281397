import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { AxiosMethods } from 'assets/js/utilities/axios'
import { FormControlProvider } from 'contexts/FormControlContext'
import InputGroupButtonFloating from 'components/forms/InputGroupButtonFloating'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'
import ValidationAlert from 'components/ui/ValidationAlert'
import useStateCallback from 'hooks/useStateCallback'

export default function Client() {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();
  
  // SUBMIT BUTTON CONTEXT
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  // VALIDATION PARAMS
  const [formValidated, setFormValidated] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState({});

  // FORM PARAMS
  const [body, setBody] = useStateCallback('');
  const [invalidFeedback, setInvalidFeedback] = useState({});

  const handleSubmit = async e => {
    // RESET INVALID FEEDBACK
    setInvalidFeedback({});

    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.POST, route: ApiRoutes.TASK_COMMENTS(task.id) });

    await submitter.validate({setFormValidated, setShowValidationAlert, setValidationMessage, setInvalidFeedback});

    if(submitter?.success) {
      const comment = submitter?.response?.data?.comment;
    
      // UPDATE CONTEXT
      task.comments.push(comment);
      stateUpdateTaskModal(taskModalDispatch, { task });

      // RESET FORM
      [setBody].map(fn => fn(Math.random(), () => fn('')));
      
      document.activeElement.blur();
    }
  }

  return (
    <Form 
      autoComplete="off" 
      noValidate 
      onSubmit={handleSubmit}
      validated={Object.keys(invalidFeedback).length === 0 && formValidated} 
    >
      <FormControlProvider>
        <InputGroupButtonFloating 
          as="textarea"
          disabled={!Boolean(Object.values(task).length) || Boolean(Number(task?.archived))}
          icon={['far', 'paper-plane']}
          invalidFeedback={invalidFeedback}
          label="New Comment..."
          name="body"
          variant="light"
          btnState={btnState}
          response={response}
          value={body}
        />
      </FormControlProvider>

      <ValidationAlert 
        showValidationAlert={showValidationAlert} 
        validationMessage={validationMessage} />
    </Form>
  )
}