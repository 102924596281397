import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function useFrontendVersionCheckLocal() {
  const location = useLocation();

  useLayoutEffect(() => {
    const version = window.localStorage.getItem('frontend-version-update');
    
    if( !location.state && version ) {  // IF THERE IS AN UPDATE AVAILABLE, AND NO STATE PASSED TO ROUTE
      window.localStorage.removeItem('frontend-version-update');  // REMOVE FROM LOCAL STORAGE
      
      if( version !== process.env.REACT_APP_VERSION) {
        window.location.reload();  // REFRESH PAGE
      }
    }
  }, [location]);
}