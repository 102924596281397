export default class SweetAlert {
  constructor(title, message, { allowEscape = true, confirmBtnBsStyle = 'danger', onCancel = undefined, onConfirm = undefined, show = true, showCancel = true, type = 'warning' } = {}) {
    this.title = title;
    this.message = message;
    this.allowEscape = allowEscape;
    this.confirmBtnBsStyle = confirmBtnBsStyle;
    this.onCancel = onCancel;
    this.onConfirm = onConfirm;
    this.show = show;
    this.showCancel = showCancel;
    this.type = type;
    this.openAnim = false;
  }
}