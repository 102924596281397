import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { stripNewTaskFromPath } from 'assets/js/utilities/stripPaths'
import NewTaskForm from './NewTaskForm'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'

export default function NewTask() {
  // REACT ROUTER DOM
  const navigate = useNavigate();

  // CLOSE MODAL REDIRECT
  const handleClose = () => navigate(stripNewTaskFromPath());

  return (
    <Modal id="newTaskModal" show={true} onHide={handleClose} size="lg" keyboard={false}>
      <Modal.Header closeButton className="bg-white align-items-center">
        <Modal.Title className="flex-fill">
          <FontAwesomeIcon icon={['far', 'rectangle-history-circle-plus']} fixedWidth className="mr-2" />
          Create New Task
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <SubmitBtnProvider>
          <NewTaskForm />
        </SubmitBtnProvider>
        
      </Modal.Body>
    </Modal>
  )
}