import { useState } from 'react'
import { Badge, Button, Card, Col, FloatingLabel, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { Sentiment } from 'assets/js/classes/BootstrapStyles'
import SmsStatus from 'assets/js/classes/TwilioSmsStatus'
import { AxiosMethods } from 'assets/js/utilities/axios'
import { escapeInput } from 'assets/js/utilities/regex'
import useApiCall from 'hooks/useApiCall'
import useDebounce from 'hooks/useDebounce'
import Main from 'layouts/Main'

class TableStyles {
  static STATUSES = {
    [SmsStatus.QUEUED]: Sentiment.INFO,
    [SmsStatus.DELIVERED]: Sentiment.SUCCESS,
    [SmsStatus.FAILED]: Sentiment.DANGER,
  }
}

const columns = [
  {
    name: 'SMS #',
    selector: row => row.id,
    sortable: true,
  },
  {
    name: 'Task #',
    selector: row => row.task_id,
    sortable: true,
  },
  {
    id: 'date',
    name: 'Date',
    selector: row => row.created_at,
    sortable: true,
    format: row => moment(row.created_at).format('DD/MM/YYYY'),
  },
  {
    name: 'Status',
    selector: row => row.status,
    sortable: true,
    cell: row => <Badge bg={TableStyles.STATUSES[row.status] ?? Sentiment.WARNING} data-tag="allowRowEvents">{row.status}</Badge>,
  },
  {
    name: 'Recipient',
    selector: row => row.recipient,
    sortable: true,
  },
];

export default function History() {
  // REACT ROUTER
  const navigate = useNavigate();

  // STATE PARAMS
  const [smsHistory, setSmsHistory] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: ApiRoutes.SMS,
    callback: ({ sms_messages }) => (setSmsHistory(sms_messages), setTableData(sms_messages)),
  });

  useDebounce(() => {
    if(searchQuery === '') {
      setTableData(smsHistory);
      return;
    }

    const filteredDataset = smsHistory?.filter(item => {
      const propsToSearch = [item.status, item.recipient];

      return propsToSearch.some(val => val && val.toLowerCase().includes(escapeInput(searchQuery).toLowerCase()));
    });
    setTableData(filteredDataset);

  }, 100, [searchQuery]);

  return (
    <Main title="History" subtitle="SMS" icon="message-sms">
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <FontAwesomeIcon icon={['far', 'clock-rotate-left']}fixedWidth className="mr-1" /> SMS History
            </Card.Header>
            <Card.Body className="px-0">
              <Row className="flex-row-reverse px-3">
                <Col xs={12} sm={8} md={6} lg={4} xl={3}>
                  <Form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <InputGroup>
                      <FloatingLabel label="Search">
                        <Form.Control 
                          type="text" 
                          placeholder="Search..." 
                          value={searchQuery} 
                          onChange={e => setSearchQuery(e.target.value.trim())} 
                          disabled={!tableData} />
                      </FloatingLabel>

                      <Button variant={searchQuery.length > 0 ? 'warning' : 'light'} onClick={() => setSearchQuery('')} disabled={!tableData}>
                        <FontAwesomeIcon icon={['far', searchQuery.length > 0 ? 'rectangle-xmark' : 'magnifying-glass']} fixedWidth />
                      </Button>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>

              <DataTable
                columns={columns}
                data={tableData ?? []}
                defaultSortAsc={false}
                defaultSortFieldId="date"
                progressPending={!tableData}
                progressComponent={<Spinner animation="border" size="lg" />}
                highlightOnHover
                pointerOnHover
                onRowClicked={row => navigate(`${row.sid}`)}
                pagination
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Main>
  )
}