const urlBackend = () => {
  return [
    `${window.location.protocol}//${window.location.hostname}`,
    `${window.location.protocol === 'http:' && process.env.NODE_ENV === 'development' ? `:${process.env.REACT_APP_BACKEND_PORT ?? 8000}` : ''}`,
    '/',
  ].join('')
}

const urlFrontend = () => {
  return [
    `${window.location.protocol}//${window.location.hostname}`,
    `${process.env.NODE_ENV === 'development' ? `:${process.env.REACT_APP_FRONTEND_PORT ?? 3000}` : ''}`,
    '/',
  ].join('')
}

const urlSanctumCsrf = () => {
  return [
    `${window.location.protocol}//${window.location.hostname}`,
    `${window.location.protocol === 'http:' && process.env.NODE_ENV === 'development' ? '' : '/api'}/sanctum/csrf-cookie`,
  ].join('')
}

export { urlBackend, urlFrontend, urlSanctumCsrf }