import './css/trello.css'
import projectBuckets from 'data/projectBuckets.json'
import { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { stateUpdateBucket, useStaticBoardDispatch, useStaticBoardState } from 'contexts/StaticBoardContext'
import { stateUpdateDataset, useSearchBarDispatch, useSearchBarState } from 'contexts/SearchBarContext'
import { useParams } from 'react-router-dom'
import StaticBoardColumn from './components/StaticBoard/Column'
import Title from 'layouts/Main/Title'
import SearchBar from './components/SearchBar'
import Buckets from 'assets/js/classes/Buckets'
import Skeleton from 'react-loading-skeleton'

export default function StaticBoard() {
  // ROUTER DOM PARAMS
  const params = useParams();

  // HEADERS
  const bucketHeaders = [
    'Overdue',
    'Today',
    'Future',
  ];

  // STATIC BOARD CONTEXT
  const { bucket } = useStaticBoardState();
  const dispatch = useStaticBoardDispatch();
  // SEARCH BAR CONTEXT
  const { dataset } = useSearchBarState();
  const dispatchSearchBar = useSearchBarDispatch();
  
  useEffect(() => {
    let bucket = {};
    stateUpdateBucket(dispatch, { bucket });
    stateUpdateDataset(dispatchSearchBar, { dataset: [bucket] });

    setTimeout(() => {  // TODO: remove timeout
      // TODO: API call with params.project
      // const bucket = projectBuckets[0];
      bucket = projectBuckets.find(bucket => bucket.slug === params.project);
      stateUpdateBucket(dispatch, { bucket });
      stateUpdateDataset(dispatchSearchBar, { dataset: [bucket] });
    }, 500)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.project]);
  
  return (
    <>
      <div className="app-main__inner p-0 d-flex flex-column">
        <Title title={bucket.title} subtitle="Dashboard" icon={'square-kanban'} />

        <Row className="px-3">
          <Col>
            <SearchBar dataset={dataset} stateUpdate={stateUpdateBucket} stateKey="bucket" stateType={Object} dispatch={dispatch} filterCallback={Buckets.filterByQuery} />
          </Col>
        </Row>

        {Object.keys(bucket).length === 0
          ? <Row className="px-3 flex-nowrap">
              {Array.from(Array(3), (_,i) =>
                <Col key={i} xs={12} md={6} lg={5} xl={4}>
                  <Skeleton height={210} />
                </Col>
              )}
            </Row>
          : <div className="d-flex flex-nowrap flex-fill" id="trello"
              style={{ userSelect: 'none', scrollSnapType: 'x mandatory' }}>
              {bucketHeaders.map((header, index) => <StaticBoardColumn key={index} order={index} bucket={bucket} header={header} />)}
            </div>
        }

      </div>
    </>
  )
}