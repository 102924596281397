import { useEffect } from 'react'
import { Card, Form } from 'react-bootstrap'
import { createSearchParams, useLocation } from 'react-router-dom'
import { AxiosMethods } from 'assets/js/utilities/axios'
import handleApiCall from 'assets/js/utilities/handleApiCall'
import handleToast from 'assets/js/utilities/handleToast'
import InputGroupFloating from 'components/forms/InputGroupFloating'
import { useAuthState } from 'contexts/AuthContext'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import Main from 'layouts/Main'

export default function EmailVerify() {
  // AUTH USER CONTEXT PARAMS
  const { user } = useAuthState();

  // TOASTS CONTEXT PARAMS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  // FORM PARAMS
  const email = user?.email ?? '';

  // REACT ROUTER DOM
  const { search } = useLocation();
  const searchParams = createSearchParams(search ?? '');

  const handleSubmit = async e => {
    const response = await handleApiCall({ 
      apiMethod: AxiosMethods.PATCH, 
      apiRoute: decodeURIComponent(searchParams.get('apiRoute')),
      formData: { email }, 
      toasts,
      toastsDispatch,
    });
    
    if(response?.status === 200) {
      // THROW TOAST
      handleToast({
        title: 'Email Verified',
        message: `Your email address ${email} has been successfully verified.`,
        autohide: false
      }, { toasts, toastsDispatch, updateToasts });
    }
  }

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <Main title="Email Verification" subtitle="Account" icon={'badge-check'}>
      <Card>
        <Card.Body>
          <Form autoComplete="off">
            <InputGroupFloating 
              className="my-3" 
              disabled
              icon={['far', 'at']} 
              label="Email Address" 
              type="email" 
              name="email" 
              value={email} 
              required />
          </Form>
        </Card.Body>
      </Card>
    </Main>
  )
}