import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import { getCountryCallingCode, isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'assets/css/react-phone-number-input-override.css'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const InputComponent = React.forwardRef((props, ref) => <Form.Control {...props} ref={ref} />);

class ValidatePhone {
  static isValid(country, phone) {
    return phone === '' 
    || `+${country && getCountryCallingCode(country)}` === phone
    || isValidPhoneNumber(phone)
  }

  static isInvalid(country, phone) {
    return phone !== '' 
    && `+${country && getCountryCallingCode(country)}` !== phone
    && !isValidPhoneNumber(phone)
  }

  static resetValidity(target) {
    const element = target.form.elements.namedItem('phone_valid');
    element?.setCustomValidity('');
    element?.reportValidity();
  }
}

export default function InputPhoneLabelled({ label, validated }) {
  const [country, setCountry] = useState('IE');
  const [phone, setPhone] = useState(`+${getCountryCallingCode('IE')}`);

  return (
    <>
      <Form.Label htmlFor={label.match(/\w/g)?.join('')}>
        {label}
      </Form.Label>
      <InputGroup className="mb-2">
        <InputGroup.Text className="bg-light">
          <FontAwesomeIcon icon={['fas', 'phone']} fixedWidth />
        </InputGroup.Text>
        <PhoneInput
          className="flex-fill"
          defaultCountry="IE"
          initialValueFormat={undefined}
          inputComponent={InputComponent}
          international
          limitMaxLength={true}
          numberInputProps={{
            className: "form-control rounded-start-0 rounded-end-2",
            id: label.match(/\w/g)?.join(''),
            isValid: validated && ValidatePhone.isValid(country, phone),
            isInvalid: validated && ValidatePhone.isInvalid(country, phone),
            onKeyUp: ({ target }) => ValidatePhone.resetValidity(target),
          }}
          onChange={value => setPhone(value ?? '')}
          onCountryChange={value => setCountry(value)}
          placeholder="Enter phone number"
          value={phone}
        />
        <input 
          hidden
          name="phone_valid" 
          onChange={() => undefined}
          onInvalid={e => e.target.setCustomValidity('Invalid phone number.')}
          required 
          type="text" 
          value={ValidatePhone.isValid(country, phone) ? true : ''} 
        />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
        <input type="hidden" readOnly value={phone.match(/\d/g)?.join('') ?? ''} name="phone" />
        <input type="hidden" readOnly value={JSON.stringify(phone && parsePhoneNumber(phone)) ?? ''} name="phone_country" />
      </InputGroup>
    </>
  )
}