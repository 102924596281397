import moment from 'moment'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { stripTopUpHistoryModalFromPath } from 'assets/js/utilities/stripPaths'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import useApiCall from 'hooks/useApiCall'

export default function HistoryModal() {
  // ROUTER DOM PARAMS
  const params = useParams();

  // CLOSE MODAL REDIRECT
  const navigate = useNavigate();
  const handleClose = () => navigate(stripTopUpHistoryModalFromPath());

  // STATE PARAMS
  const [transaction, setTransaction] = useState(undefined);

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: `${ApiRoutes.CASHIER_CUSTOMER_BALANCE_TRANSACTION}/${params.transactionId}`,
    callback: ({ transaction }) => setTransaction(transaction),
  });

  return (
    <Modal id="paymentDetailsModal" show={true} onHide={handleClose} size="lg" keyboard={true}>
      <Modal.Header closeButton className="bg-white align-items-center">
        <Modal.Title className="flex-fill">
          <FontAwesomeIcon icon={['far', 'file-invoice']} fixedWidth /> Transaction
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-column">
          {/* TRANSACTION ID */}
          <div className="d-flex flex-row align-items-center mb-3">
            <div className="page-title-icon bg-crystal-blue p-3 mr-3 rounded text-white">
              <FontAwesomeIcon icon={['far', 'hashtag']} fixedWidth size="2x" />
            </div>
            <span className="w-100">
              { transaction
                ? transaction?.id
                : <Skeleton />
              }
            </span>
          </div>

          {/* PAYMENT INTENT ID */}
          <div className="d-flex flex-row align-items-center mb-3">
            <div className="page-title-icon bg-crystal-blue p-3 mr-3 rounded text-white">
              <FontAwesomeIcon icon={['far', 'credit-card']} fixedWidth size="2x" />
            </div>
            <span className="w-100">
              { transaction
                ? transaction?.metadata?.payment_intent_id
                : <Skeleton />
              }
            </span>
          </div>
          
          {/* AMOUNT */}
          <div className="d-flex flex-row align-items-center mb-3">
            <div className="page-title-icon bg-crystal-blue p-3 mr-3 rounded text-white">
              <FontAwesomeIcon icon={['far', 'euro-sign']} fixedWidth size="2x" />
            </div>
            <span className="w-100">
              { transaction
                ? `${(transaction?.amount*-1/100).toFixed(2)} ${transaction?.currency?.toUpperCase()}`
                : <Skeleton />
              }
            </span>
          </div>

          {/* DESCRIPTION */}
          <div className="d-flex flex-row align-items-center mb-3">
            <div className="page-title-icon bg-crystal-blue p-3 mr-3 rounded text-white">
              <FontAwesomeIcon icon={['far', 'file-invoice']} fixedWidth size="2x" />
            </div>
            <span className="w-100">
              { transaction
                ? transaction?.description
                : <Skeleton />
              }
            </span>
          </div>

          {/* DATE */}
          <div className="d-flex flex-row align-items-center mb-3">
            <div className="page-title-icon bg-crystal-blue p-3 mr-3 rounded text-white">
              <FontAwesomeIcon icon={['far', 'calendar-day']} fixedWidth size="2x" />
            </div>
            <span className="w-100">
              { transaction
                ? moment(transaction?.created, 'X').format('Do MMMM, YYYY [at] HH:mm Z')
                : <Skeleton />
              }
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}