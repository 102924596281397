import InputGroupLabelled from 'components/forms/InputGroupLabelled'

export default function Notes() {
  return (
    <section className="mb-3">

      <InputGroupLabelled
        as="textarea"
        icon={['far', 'note']}
        label="Notes"
        name="notes"
        required
        style={{height: '12rem'}}
      />

    </section>
  )
}