import React from 'react';
import dbUser from 'data/authenticatedUser.json';

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if(!context) throw new Error('useAuthState must be used in AuthProvider');
  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if(!context) throw new Error('useAuthDispatch must be used in AuthProvider');
  return context;
}

function AuthProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { user: null });

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

function stateLogin(dispatch, { user }) {
  dispatch({ user });
}

function stateLogout(dispatch) {
  dispatch({ user: null });
}

export { AuthProvider, stateLogin, stateLogout, useAuthDispatch, useAuthState };