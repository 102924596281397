import { Button, Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import FormControl from 'components/forms/FormControl'
import { FormControlProvider } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import FormButton from 'components/forms/FormButton'
import FormCheck from 'components/forms/FormCheck'

export default function ChecklistItem({ checklistIndex, item }) {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();
  
  // CONSTANTS
  const itemIndex = ModalTaskClass.getChecklistItemIndex(task, item?.id);

  return (
    <Form 
      autoComplete="off"
      className="my-1"
      onSubmit={e => e.preventDefault()}
    >
      <InputGroup>
        <InputGroup.Text className="bg-white transparentBorder">
          <FormControlProvider>
            <FormCheck 
              className="d-flex"
              checked={item?.complete ? true : false}
              disabledProp={Boolean(Number(task?.archived))}
              hidden={Boolean(Number(task?.archived))}
              modelContext={{ task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
              name={`checklists.${checklistIndex}.items.${itemIndex}.complete`}
              onChange={ModalTaskClass.handleInputSubmit}
              type="checkbox" 
            />
          </FormControlProvider>
        </InputGroup.Text>
        <FormControlProvider>
          <FormControl
            className="transparentBorder rounded pl-1"
            disabledProp={Boolean(Number(task?.archived))}
            modelContext={{ task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
            name={`checklists.${checklistIndex}.items.${itemIndex}.title`}
            onBlur={ModalTaskClass.handleInputSubmit}
            onKeyDown={ModalTaskClass.handleKeyDown}
            placeholder="Checklist Item..."
            required
            value={item.title}
          />
        </FormControlProvider>
        <FormControlProvider>
          <FormButton
            className="transparentBorder transparentBg rounded ml-1"
            disabledProp={Boolean(Number(task?.archived))}
            modelContext={{ task, checklistIndex, itemIndex, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
            hidden={Boolean(Number(task?.archived))}
            onClick={ModalTaskClass.handleDeleteChecklistItem}
            variant="light"
          ><FontAwesomeIcon icon={['fas', 'circle-xmark']} fixedWidth />
          </FormButton>
        </FormControlProvider>
      </InputGroup>
    </Form>
  )
}