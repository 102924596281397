import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreateCommentForm from './CreateCommentForm'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'
import Comments from './Comments'

export default function ActivityLog() {
  return (
    <section className="mb-3">
      <h6 className="mb-1">
        <FontAwesomeIcon icon={['far', 'comments']} fixedWidth /> ACTIVITY LOG
      </h6>

      <SubmitBtnProvider>
        <CreateCommentForm />
      </SubmitBtnProvider>

      <Comments />
    </section>
  )
}