import { useState } from 'react'
import { Alert, Card, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Elements as ElementsProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Checkout from './Checkout'
import useCreatePaymentIntent from 'hooks/stripe/useCreatePaymentIntent'
import useUpdatePaymentIntent from 'hooks/stripe/useUpdatePaymentIntent'
import ConfirmationAlert from 'components/ui/ConfirmationAlert'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class TopUpCfg {
  static MIN_AMOUNT = 2000;  // 20 euro
  static STEP = 4000;  // 40 euro
  static NUM_OPTIONS = 8;
  static AMOUNTS = Array.from(Array(TopUpCfg.NUM_OPTIONS).keys()).map(idx => idx * TopUpCfg.STEP + TopUpCfg.MIN_AMOUNT);
}

export default function TopUpForm() {
  // STATE PARAMS
  const [selected, setSelected] = useState(TopUpCfg.AMOUNTS[0]);
  const [amount, setAmount] = useState(TopUpCfg.AMOUNTS[0]);
  
  // CONFIRMATION PARAMS
  const [confirmationMessage, setConfirmationMessage] = useState({});

  // STRIPE PARAMS
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  useCreatePaymentIntent({ amount, description: "SMS Top Up", setClientSecret, setPaymentIntentId });
  useUpdatePaymentIntent({ amount, paymentIntentId });

  // UPDATE DROPDOWN TOP UP AMOUNT
  const handleChange = e => {
    setSelected(e.target.value);
    setAmount(Number(e.target.value));
  }

  // CONFIGURE STRIPE
  const stripeOptions = {
    clientSecret,  // passing the client secret obtained from the server
    appearance: {
      rules: {
        '.Input:focus': {
          borderColor: '#34495e',
          boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' /* https://getcssscan.com/css-box-shadow-examples */
        },
        '.Tab--selected:focus': {
          boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' /* https://getcssscan.com/css-box-shadow-examples */
        }
      },
    },
  }

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={['far', 'credit-card']} fixedWidth className="mr-1" /> Top Up
      </Card.Header>
      <Card.Body>
        { !Object.keys(confirmationMessage).length > 0 &&
          <Form.Group>
            <Form.Label className="w-100 mb-3">Select top up amount
              <Form.Select 
                aria-label="Select top up amount" 
                disabled={!stripeOptions?.clientSecret}
                onChange={handleChange}
                value={selected} 
              >
                {TopUpCfg.AMOUNTS.map((amount, idx) => <option key={idx} value={amount}>&euro;{Math.floor(amount / 100)}</option>)}
              </Form.Select>
            </Form.Label>
          </Form.Group>
        }
        
        { !Object.keys(confirmationMessage).length > 0 && 
          stripeOptions?.clientSecret &&
          <ElementsProvider stripe={stripePromise} options={stripeOptions}>
            <SubmitBtnProvider>
              <Checkout 
                amount={amount} 
                clientSecret={clientSecret} 
                setConfirmationMessage={setConfirmationMessage} 
              />
            </SubmitBtnProvider>
          </ElementsProvider>
        }

        <ConfirmationAlert className="mb-0" message={confirmationMessage} />
      </Card.Body>
    </Card>
  )
}