import React from 'react';

const UserEditStateContext = React.createContext();
const UserEditDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useUserEditState() {
  const context = React.useContext(UserEditStateContext);
  if(!context) throw new Error('useUserEditState must be used in UserEditProvider');
  return context;
}

function useUserEditDispatch() {
  const context = React.useContext(UserEditDispatchContext);
  if(!context) throw new Error('useUserEditDispatch must be used in UserEditProvider');
  return context;
}

function UserEditProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { user: null });

  return (
    <UserEditStateContext.Provider value={state}>
      <UserEditDispatchContext.Provider value={dispatch}>
        {props.children}
      </UserEditDispatchContext.Provider>
    </UserEditStateContext.Provider>
  )
}

function updateUserEdit(dispatch, { user }) {
  dispatch({ user });
}

export { UserEditProvider, updateUserEdit, useUserEditDispatch, useUserEditState };