import { useState } from 'react'
import { Button, Toast as T } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import 'moment-timezone'
import { updateToasts, useToastsDispatch, useToastsState } from '../../contexts/ToastsContext'

export default function Toast({ toast }) {
  const [show, setShow] = useState(true);
  
  const { toasts } = useToastsState();
  const dispatch = useToastsDispatch();

  const closeToast = id => {
    // HIDE TOAST
    setShow(false);

    // REMOVE TOAST FROM ARRAY
    toasts.splice(toasts.findIndex(toast => Number(toast.id) === Number(id)), 1);
    updateToasts(dispatch, { toasts });
  }

  return (
    <T show={show} onClose={() => closeToast(toast.id)} delay={5000} autohide={toast.autohide}>
      <T.Header closeButton={true || !toast.autohide}>
        <FontAwesomeIcon icon={['fad', 'bell']} fixedWidth className={`text-${toast?.sentiment ?? 'info'}`} />
        <strong className="me-auto">{toast.title}</strong>
        <small className="text-muted"><Moment date={toast.created_at} interval={1000} trim durationFromNow /> ago</small>
      </T.Header>
      <T.Body style={{ whiteSpace: 'break-spaces' }}>
        {toast.message}
        { toast?.action && !Object.values(toast?.action).some(v => v === undefined)
          && <Button variant="link" className="d-block pl-0" onClick={() => (closeToast(toast.id), toast?.action?.callback())}>{toast?.action?.label}</Button>
        }
      </T.Body>
    </T>
  )
}