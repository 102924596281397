import { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import ApiChannels from 'assets/js/classes/ApiChannels'
import ApiEvents from 'assets/js/classes/ApiEvents'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { AxiosMethods } from 'assets/js/utilities/axios'
import eventTenantUpdated from 'assets/js/utilities/eventTenantUpdated'
import ValidationAlert from 'components/ui/ValidationAlert'
import InputGroupFloating from 'components/forms/InputGroupFloating'
import SubmitBtn from 'components/forms/SubmitBtn'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'
import { stateLogin, useAuthDispatch, useAuthState } from 'contexts/AuthContext'
import { updateTenant, useTenantDispatch, useTenantState } from 'contexts/TenantContext'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import useApiCall from 'hooks/useApiCall'
import useWebSocket from 'hooks/useWebSocket'

export default function BusinessDetailsForm() {
  // CONTEXT PARAMS - AUTH USER
  const { user } = useAuthState();
  const authDispatch = useAuthDispatch();

  // CONTEXT PARAMS - TENANT
  const { tenant } = useTenantState();
  const tenantDispatch = useTenantDispatch();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();
  
  // CONTEXT PARAMS - SUBMIT BUTTON
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  // VALIDATION PARAMS
  const [formValidated, setFormValidated] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState({});

  // FORM PARAMS
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState(tenant?.name ?? '');
  const [email, setEmail] = useState(tenant?.email ?? '');
  const [address, setAddress] = useState(tenant?.address?.shipping ?? {});
  const [invalidFeedback, setInvalidFeedback] = useState({});

  const handleSubmit = async e => {
    // RESET INVALID FEEDBACK
    setInvalidFeedback({});

    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.PATCH, route: ApiRoutes.TENANT });

    await submitter.validate({setFormValidated, setShowValidationAlert, setValidationMessage, setInvalidFeedback});

    if(submitter.success) {
      updateTenant(tenantDispatch, { tenant: { ...tenant, name, email, address: { shipping: address }, }, });
    }

    document.activeElement.blur()
  }

  useEffect(() => {
    setName(tenant?.name ?? '');
    setEmail(tenant?.email ?? '');
    setAddress(tenant?.address?.shipping ?? {});
  }, [tenant]);

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: ApiRoutes.TENANT,
    callback: ({ tenant }) => updateTenant(tenantDispatch, { tenant }),
    setLoading,
  });

  useWebSocket(ApiChannels.PRIVATE_TENANT(tenant?.id), [
    {
      events: [ApiEvents.TENANT_UPDATED],
      callback: ({ author, tenant }) => eventTenantUpdated(
        { author, user, tenant, callback: () => updateTenant(tenantDispatch, { tenant }) }, 
        { toasts, toastsDispatch, updateToasts }),
    },
  ]);

  const handleSetAddress = e => setAddress(oldVal => ({ ...oldVal, [e.target.name]: e.target.value }));

  return (
    <Form noValidate validated={invalidFeedback.length > 0 || formValidated} autoComplete="off" onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} md={6}>
          { loading
            ? <Skeleton className="mb-4" height={54} />
            : <InputGroupFloating 
                className="mb-4" 
                icon={['far', 'id-badge']} 
                invalidFeedback={invalidFeedback}
                label="Business Name" 
                name="name" 
                onChange={e => setName(e.target.value)} 
                required 
                type="text" 
                value={name} />
          }
          
          { loading
            ? <Skeleton className="mb-4" height={54} />
            : <InputGroupFloating 
                className="mb-4" 
                icon={['far', 'at']} 
                invalidFeedback={invalidFeedback}
                label="Business Email Address" 
                name="email" 
                onChange={e => setEmail(e.target.value.trim())}
                required 
                type="email" 
                value={email} />
          }
        </Col>
        <Col xs={12} md={6}>
          { loading
            ? <Skeleton className="mb-4" height={54} />
            : <InputGroupFloating
                className="mb-4" 
                icon={['far', 'location-dot']} 
                invalidFeedback={invalidFeedback}
                label="Business Address: Line 1" 
                name="line1" 
                onChange={handleSetAddress} 
                required 
                type="text" 
                value={address?.line1 ?? ''} />
          }

          { loading
            ? <Skeleton className="mb-4" height={54} />
            : <InputGroupFloating 
                className="mb-4" 
                icon={['far', 'location-dot']} 
                invalidFeedback={invalidFeedback}
                label="Business Address: Line 2" 
                name="line2" 
                onChange={handleSetAddress} 
                required 
                type="text" 
                value={address?.line2 ?? ''} />
          }

          { loading
            ? <Skeleton className="mb-4" height={54} />
            : <InputGroupFloating 
                className="mb-4" 
                icon={['far', 'location-dot']} 
                invalidFeedback={invalidFeedback}
                label="Business Address: City/Town" 
                name="city" 
                onChange={handleSetAddress} 
                required 
                type="text" 
                value={address?.city ?? ''} />
          }

          { loading
            ? <Skeleton className="mb-4" height={54} />
            : <InputGroupFloating 
                className="mb-4" 
                icon={['far', 'location-dot']} 
                invalidFeedback={invalidFeedback}
                label="Business Address: County/State" 
                name="state" 
                onChange={handleSetAddress} 
                required 
                type="text" 
                value={address?.state ?? ''} />
          }

          { loading
            ? <Skeleton className="mb-4" height={54} />
            : <InputGroupFloating 
                className="mb-4" 
                icon={['far', 'location-dot']} 
                invalidFeedback={invalidFeedback}
                label="Business Address: Eircode / Postal Code" 
                name="postal_code" 
                onChange={handleSetAddress} 
                required 
                type="text" 
                value={address?.postal_code ?? ''} />
          }

          { loading
            ? <Skeleton className="mb-4" height={54} />
            : <InputGroupFloating  // TODO: change to stripe address element
                className="mb-4" 
                icon={['far', 'location-dot']} 
                invalidFeedback={invalidFeedback}
                label="Business Address: Country" 
                name="country" 
                onChange={handleSetAddress} 
                required 
                type="text" 
                value={address?.country ?? ''} />
          }
        </Col>
      </Row>

      <ValidationAlert 
        showValidationAlert={showValidationAlert} 
        validationMessage={validationMessage} />

      <SubmitBtn 
        disabled={loading}
        icon={['fas', 'floppy-disk']} 
        text="Save"
        state={btnState}
        response={response} />
    </Form>
  )
}