import SB from 'assets/js/classes/SubmitBtn'
import { axiosClient, AxiosMethods } from 'assets/js/utilities/axios'
import formatApiError from 'assets/js/utilities/formatApiError'
import collectFormData from 'assets/js/utilities/collectFormData'

export default class Submitter {
  success = false;
  errors = undefined;

  constructor(e,
    { stateUpdateSubmitBtn, submitBtnDispatch },
    { method = AxiosMethods.GET, route } = {},
    { navigate = null, navigateUrl = null } = {},
    { spinnerWait = true } = {},
  ) {
    e.preventDefault();
    this.form = e.target;
    this.button = { stateUpdateSubmitBtn, submitBtnDispatch };
    this.apiProps = { method, route };
    this.spinnerWait = spinnerWait;
    this.navigateProps = { navigate, navigateUrl };
  }

  async validate({ setFormValidated, setShowValidationAlert, setValidationMessage, setInvalidFeedback }) {
    return new Promise(async resolve => {
      this.setFormValidated = setFormValidated ?? (args => undefined);
      this.setShowValidationAlert = setShowValidationAlert ?? (args => undefined);
      this.setValidationMessage = setValidationMessage ?? (args => undefined);
      this.setInvalidFeedback = setInvalidFeedback ?? (args => undefined);

      await this.#resetValidation();
      const promise = await this.#checkValidation();
      resolve(promise);
    })
  }

  #resetValidation() {
    return new Promise(resolve => {
      this.setFormValidated(false);
      this.setShowValidationAlert(false);
      this.setValidationMessage(null);

      resolve();
    })
  }

  #checkValidation() {
    return new Promise(resolve => {
      let response;
      if(this.form.checkValidity() === false) {
        response = this.#giveFeedback();
      } else {
        response = this.#submit();
      }
      resolve(response);
    })
  }

  #giveFeedback() {
    // SET VALIDATED STATE
    this.setFormValidated(true);

    // CHECK EACH FORM ELEMENT
    [...this.form.elements].forEach(input => {
      if( !['input', 'select', 'textarea'].find(tag => tag === input.tagName.toLowerCase()))
        return;

      // UPDATE VALIDATION MESSAGE
      if( !input.checkValidity()) {
        const feedback = input.nextSibling;
        feedback.innerHTML = input.validationMessage;
      }
    });
  }

  async #submit() {
    // DISABLE SUBMIT BUTTON
    await this.button.stateUpdateSubmitBtn(this.button.submitBtnDispatch, { btnState: SB.LOADING });

    // GATHER FORM DATA
    const formData = collectFormData(this.form);

    // MAKE API CALL
    console.log(this.apiProps, formData);
    switch(this.apiProps.method) {
      case AxiosMethods.GET:
        this.response = await axiosClient.apiCall(() => axiosClient.get(this.apiProps.route));
      break;
      case AxiosMethods.DELETE:
        this.response = await axiosClient.apiCall(() => axiosClient.delete(this.apiProps.route));
      break;
      case AxiosMethods.HEAD:
        this.response = await axiosClient.apiCall(() => axiosClient.head(this.apiProps.route));
      break;
      case AxiosMethods.OPTIONS:
        this.response = await axiosClient.apiCall(() => axiosClient.options(this.apiProps.route));
      break;
      case AxiosMethods.POST:
        this.response = await axiosClient.apiCall(() => axiosClient.post(this.apiProps.route, formData));
      break;
      case AxiosMethods.PUT:
        this.response = await axiosClient.apiCall(() => axiosClient.put(this.apiProps.route, formData));
      break;
      case AxiosMethods.PATCH:
        this.response = await axiosClient.apiCall(() => axiosClient.patch(this.apiProps.route, formData));
      break;
    }
    console.log(this.response);
    this.success = this.response.status >= 200 && this.response.status < 300;

    // CHECK FOR ERROS AND GIVE FEEDBACK TO USER
    this.errors = this.response?.error?.response?.data?.errors;
    this.#validateErrors();

    // UPDATE SUBMIT BUTTON
    if(this.spinnerWait) {
      await this.button.stateUpdateSubmitBtn(this.button.submitBtnDispatch, { btnState: SB.RESPONSE, response: this.response });
    } else {
      this.button.stateUpdateSubmitBtn(this.button.submitBtnDispatch, { btnState: SB.RESPONSE, response: this.response });
    }

    // RESTORE BUTTON
    this.button.stateUpdateSubmitBtn(this.button.submitBtnDispatch, { btnState: SB.DEFAULT });

    if(this.success) {
      this.setShowValidationAlert(false);
      this.setValidationMessage(null);
      if(this.navigateProps.navigateUrl)
        return this.navigateProps.navigate(this.navigateProps.navigateUrl);
    } else {
      this.setShowValidationAlert(true);

      if(this.response?.error) {
        this.setValidationMessage({
          title: this.response?.error?.code,
          body: formatApiError(this.response?.error).join('\n\n'),
        });
      } else {
        this.setValidationMessage(this.response?.data?.message || this.response?.message || `${this.response?.status} ${this.response?.statusText}`);
      }
    }
  }

  #validateErrors() {
    Object.entries(this.errors ?? {})
      .map(([name, messages]) => this.setInvalidFeedback(state => ({ ...state, [name]: messages?.join(' ') })));
  }
}