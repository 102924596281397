import { useEffect } from 'react'
import cookie from 'react-cookies'
import Pusher from 'pusher-js'
import { urlBackend } from 'assets/js/utilities/helpers'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import handleApiError from 'assets/js/utilities/handleApiError'

Pusher.Runtime.createXHR = function () {  // https://github.com/pusher/pusher-js/issues/471#issuecomment-659107992
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

const pusherClient = new Pusher('app-key', {
  cluster: 'eu',
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  encrypted: true,
  forceTLS: window.location.protocol.includes('https'),
  wsHost: window.location.hostname,
  wsPort: process.env.REACT_APP_SOKETI_PORT ?? 6001,
  channelAuthorization: {
    endpoint: urlBackend() + ApiRoutes.BROADCASTING_CHANNEL_AUTH,
    params: {},
    headers: {},
    paramsProvider: null,
    headersProvider: () => {
      return {
        'Accept': 'application/json',
        'X-XSRF-TOKEN': cookie.load('XSRF-TOKEN'),
      }
    },
    customHandler: null,
  }
});

export default function useWebSocket(channelName, events) {
  // CONTEXT PARAMS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  pusherClient.connection.bind('disconnected', e => {
    console.log(`Disconnected with ${channelName}: `, e);
  });

  pusherClient.connection.bind('error', e => {
    console.log(`Error with ${channelName}: `, e);
  });

  pusherClient.connection.bind('connected', e => {
    console.log(`Connected with ${channelName}: `, e);
  });

  const unsubscribe = () => {
    if (channelName.includes('undefined')) return;

    pusherClient.unsubscribe(channelName);
    console.log('Unsubscribing from: ', {channelName});
  }

  events.push({
    events: ['pusher:subscription_error'],
    callback: (data) => handleApiError(data, { toasts, toastsDispatch, updateToasts }),
  });

  const subscribe = () => {
    if (channelName.includes('undefined')) return;

    const channel = pusherClient.subscribe(channelName);
    console.log('Subscribing to: ', {channelName})
    
    events.map(ev => ev.events.map(
      event => channel.bind(event, data => ev?.callback(data, { toasts, toastsDispatch, updateToasts }))
    ));
  }

  useEffect(() => {
    subscribe();
    
    return unsubscribe;
  }, [])
}