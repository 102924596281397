import { useState } from 'react'
import { Button } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControlProvider } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import Comment from './Comment'

export default function Comments() {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  return (
    <>
      {/* COMMENTS LIST */}
      {
        task.comments?.sort((a, b) => a.created_at < b.created_at ? 1 : -1)  // SORT BY DATE
          .map(comment => <Comment key={comment.id} comment={comment} />)
        ??  Array.from(Array(3), (_,i) =>
              <div key={i} className="d-flex flex-row mb-1">
                <span>
                  <Skeleton count={1} circle inline="true" height={40} width={40} style={{ marginRight: '0.25rem' }} />
                </span>
                <span className="w-100">
                  <Skeleton count={1} inline="true" height={70} />
                </span>
              </div>
            )
      }
      
    </>
  )
}