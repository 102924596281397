import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faGear, faSquareKanban } from "@fortawesome/pro-regular-svg-icons"
// import { faGear, faSquareKanban } from "assets/fontawesome/svgs/regular";
// import { faCaretDown } from "@fortawesome/pro-solid-svg-icons"
// import { faCaretDown } from "assets/fontawesome/svgs/solid";
import Submitter from "components/forms/Submitter"
import { stateUpdateDataset, useSearchBarDispatch, useSearchBarState } from 'contexts/SearchBarContext';
import { stateSetScrollSnap, stateUpdateBuckets, useDynamicBoardDispatch, useDynamicBoardState } from 'contexts/DynamicBoardContext';

export default function DynamicBoardSettings() {
  const [showDropdown, setShowDropdown] = useState(false);

  // DYNAMIC BOARD CONTEXT
  const { buckets, scrollSnap } = useDynamicBoardState();
  const dynamicBoardDispatch = useDynamicBoardDispatch();
  
  // SEARCH BAR CONTEXT
  const { dataset } = useSearchBarState();
  const dispatchSearchBar = useSearchBarDispatch();

  const handleNewProject = e => {
    e.preventDefault();

    const submitter = new Submitter(e.target, { status: 'Creating...', btnClass: 'light' });

    // TODO: API CALL
    const newBucket = {
      id: Math.floor(Math.random() * 99999),
      title: 'New Project',
      tasks: [],
    };  // TODO: THIS IS A PLACEHOLDER, REPLACE WITH NEW BUCKET FROM API

    setTimeout(async () => {  // TODO: REMOVE TIMEOUT
      await submitter.eval({ status: 200 });
      setShowDropdown(false);  // HIDE THE DROPDOWN

      // UPDATE BUCKETS STATE
      buckets.splice(0, 0, newBucket);  // ADD NEW BUCKET TO THE START OF THE STATE ARRAY
      stateUpdateBuckets(dynamicBoardDispatch, { buckets });  // UPDATE STATE
      dataset.splice(0, 0, newBucket);  // ADD NEW BUCKET TO THE START OF THE STATE ARRAY
      stateUpdateDataset(dispatchSearchBar, { dataset });  // UPDATE STATE

      // REMOVE SCROLL SNAP
      stateSetScrollSnap(dynamicBoardDispatch, false);

      // SCROLL TRELLO CONTAINER
      const $trello = document.getElementById('trello');
      const scrLft = $trello?.querySelector('div').offsetWidth;
      const duration = 200;

      Array.from(Array(100).keys()).map(i => setTimeout(
        () => $trello.scrollLeft = scrLft - (scrLft * i / 100), i / 100 * duration)
      );

      // RESTORE SCROLL SNAP
      setTimeout(() => stateSetScrollSnap(dynamicBoardDispatch, true), duration);

    }, 500);
  }

  const handleOnToggle = (show, meta) => {
    setShowDropdown(! meta.source === 'rootClose');
  }

  return (
    <Dropdown align="start" show={showDropdown} onToggle={handleOnToggle} autoClose="outside" className="flex-fill align-self-stretch d-flex mb-2">
      <Dropdown.Toggle variant="primary" onClick={() => setShowDropdown(!showDropdown)} className="align-self-stretch shadow-sm w-100 noCaret">
        <FontAwesomeIcon icon={['far', 'gear']} fixedWidth />
        <FontAwesomeIcon icon={['fas', 'caret-down']} fixedWidth />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <form onSubmit={handleNewProject}>
          <Dropdown.Item as="button" type="submit" className="rounded-0">
            <FontAwesomeIcon icon={['far', 'square-kanban']} fixedWidth className="mr-2" />
            New List
          </Dropdown.Item>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  )
}