import { Col, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import Logo from 'components/ui/Logo'
import { useAuthState } from 'contexts/AuthContext'
import { SidebarProvider } from 'contexts/SidebarContext'
import useNavigateAuthenticated from 'hooks/useNavigateAuthenticated'

export default function Unauthenticated() {
  // USER AUTH CONTEXT
  const { user } = useAuthState();

  return useNavigateAuthenticated(user) || (
    <div className="app-container app-theme-white body-tabs-shadow">
      <div className="app-container">
        <div className="vh-100">
          <Row className="vh-100 no-gutters">
            <Col className="d-none d-lg-flex align-items-center justify-content-center flex-column" lg={4}>
              <Logo textColor="#2c3e50" size="75%" />
              <span className="text-muted">
                v.{process.env.REACT_APP_VERSION.substring(0, 6)}
              </span>
            </Col>

            <Col className="vh-100 d-flex bg-white justify-content-center align-items-center overflow-auto" md={12} lg={8}>
              <Col className="mx-auto app-login-box" sm={12} md={10} lg={9}>
                <Logo textColor="#2c3e50" size="3rem" className="d-sm-block d-lg-none mb-3" />
                <Outlet />
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}