import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FloatingLabel, Form, InputGroup } from 'react-bootstrap'

export default function InputGroupFloating({ as, className, icon, label, type, name, value, invalidFeedback, onChange, required, disabled }) {
  return (
    <InputGroup className={className ?? 'mb-4'}>
      <InputGroup.Text>
        <FontAwesomeIcon icon={icon} fixedWidth />
      </InputGroup.Text>
      <FloatingLabel label={label}>
        <Form.Control
          as={as}
          disabled={disabled} 
          isInvalid={(Object.keys(invalidFeedback ?? {}).length > 0 && invalidFeedback?.[name] !== undefined) ?? null}
          isValid={(Object.keys(invalidFeedback ?? {}).length > 0 && invalidFeedback?.[name] === undefined) ?? null}
          name={name} 
          onChange={onChange}
          placeholder={label} 
          required={required} 
          type={type ?? 'text'} 
          value={value ?? ''}
        /> 
        <Form.Control.Feedback type="invalid" tooltip>{invalidFeedback?.[name]}</Form.Control.Feedback>
      </FloatingLabel>
    </InputGroup>
  )
}