class Sentiment {
  static DANGER = 'danger';
  static DARK = 'dark';
  static FOCUS = 'focus';
  static INFO = 'info';
  static LIGHT = 'light';
  static MUTED = 'muted';
  static PRIMARY = 'primary';
  static SECONDARY = 'secondary';
  static SUCCESS = 'success';
  static WARNING = 'warning';
}

export { Sentiment }