export default function formatApiError(error) {
  switch (error?.code) {
    case undefined:
      var body = [
        error?.error,
      ];
    break;
    default:
      var body = [
        `${error?.type} — ${error?.message}`,
        error?.param,
        error?.request_log_url,
      ];
  }

  return body.join('\n\n');
}