import moment from 'moment'
import DateTime from './DateTime'
import { Sentiment } from './BootstrapStyles'

class DeadlineStatus {
  static OVERDUE = 'Overdue';
  static TODAY = 'Today';
  static FUTURE = 'Future';
}

class Priority {
  static LOW = 'Low';
  static MEDIUM = 'Medium';
  static HIGH = 'High';
}

class Status {
  static NEW = 'New';
  static IN_PROGRESS = 'In Progress';
  static ON_HOLD = 'On Hold';
  static COMPLETE = 'Complete';
}

class Task {
  static DEADLINE_STATUSES = {
    [DeadlineStatus.OVERDUE]: Sentiment.DANGER,
    [DeadlineStatus.TODAY]: Sentiment.WARNING,
    [DeadlineStatus.FUTURE]: Sentiment.LIGHT + ' text-' + Sentiment.FOCUS,
  };

  static STATUSES = {
    [Status.NEW]: Sentiment.WARNING,
    [Status.IN_PROGRESS]: Sentiment.INFO,
    [Status.ON_HOLD]: Sentiment.FOCUS,
    [Status.COMPLETE]: Sentiment.SUCCESS,
  };

  static PRIORITIES = {
    [Priority.LOW]: Sentiment.MUTED,
    [Priority.MEDIUM]: Sentiment.WARNING,
    [Priority.HIGH]: Sentiment.DANGER,
  };

  constructor(task) {
    this.task = task;
    this.format = {
      deadline: moment(task.deadline).format('Do MMM'),
    }

    this.style = {
      deadline: this.#getDeadlineSentiment(),
      priority: Task.PRIORITIES[this.task.priority] ?? Sentiment.INFO,
      status: Task.STATUSES[this.task.status] ?? Sentiment.INFO,
    }

    this.tally = {
      checklistsItems: {
        complete: this.task.checklists.reduce((acc, { items }) => acc + items.reduce((acc, { complete }) => acc + Number(complete), 0), 0),
        total: this.task.checklists.reduce((acc, { items }) => acc + items.length, 0),
      },
    }
  }

  static getAssigneeInitials(username) {
    if(username.split('.').length > 1)
      return (username.split('.')[0].substring(0,1) + username.split('.')[1].substring(0,1)).toUpperCase();

    return username.substring(0, 1).toUpperCase();
  }

  #getDeadlineSentiment() {
    const evalDate = [
      DateTime.isPast(this.task.deadline),
      DateTime.isPresent(this.task.deadline),
      DateTime.isFuture(this.task.deadline),
    ]

    return Object.entries(Task.DEADLINE_STATUSES).reduce((acc, [ key, val ], idx) => evalDate[idx] ? acc + val : acc, '');
  }
}

export { Priority, Status, Task }