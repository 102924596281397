import { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Toast from 'assets/js/classes/Toast'
import Submitter from 'components/forms/Submitter'
import ValidationAlert from 'components/ui/ValidationAlert'
import { stateUpdateClientEmailModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import { useTenantState } from 'contexts/TenantContext'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'

export default function ModalSendEmail({ task }) {
  return (
    <></>
  )
}