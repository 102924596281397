import { Form, InputGroup } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import FormControl from 'components/forms/FormControl'
import { FormControlProvider } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'

export default function Title() {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  return (
    <>
      { task?.title
        ? <Form autoComplete="off" onSubmit={undefined}>
            <InputGroup className="pr-2">
              <InputGroup.Text className="bg-white transparentBorder">
                <FontAwesomeIcon icon={['far', 'input-text']} fixedWidth />
              </InputGroup.Text>
              <FormControlProvider>
                <FormControl
                  className="transparentBorder rounded"
                  disabledProp={Boolean(Number(task?.archived))}
                  modelContext={{ task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
                  name="title"
                  onBlur={ModalTaskClass.handleInputSubmit}
                  onKeyDown={ModalTaskClass.handleKeyDown}
                  placeholder="Title..."
                  required
                  value={task.title}
                />
              </FormControlProvider>
            </InputGroup>
          </Form>
        : <Skeleton height={32} />
      }
    </>
  )
}