import { useAuthState } from 'contexts/AuthContext'

export default function LoggedInAs() {
  const { user } = useAuthState();

  return (
    <div className="widget-content-right ml-3 header-user-info">
      <div className="widget-heading text-light">{user?.name ?? ''}</div>
    </div>
  )
}