import React from 'react';

const StaticBoardStateContext = React.createContext();
const StaticBoardDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useStaticBoardState() {
  const context = React.useContext(StaticBoardStateContext);
  if(!context) throw new Error('useStaticBoardState must be used in StaticBoardProvider');
  return context;
}

function useStaticBoardDispatch() {
  const context = React.useContext(StaticBoardDispatchContext);
  if(!context) throw new Error('useStaticBoardDispatch must be used in StaticBoardProvider');
  return context;
}

function StaticBoardProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { bucket: {} });

  return (
    <StaticBoardStateContext.Provider value={state}>
      <StaticBoardDispatchContext.Provider value={dispatch}>
        {props.children}
      </StaticBoardDispatchContext.Provider>
    </StaticBoardStateContext.Provider>
  )
}

function stateUpdateBucket(dispatch, { bucket }) {
  dispatch({ bucket });
}

export { StaticBoardProvider, stateUpdateBucket, useStaticBoardDispatch, useStaticBoardState };