import React from 'react';

const ToastsStateContext = React.createContext();
const ToastsDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useToastsState() {
  const context = React.useContext(ToastsStateContext);
  if(!context) throw new Error('useToastsState must be used in ToastsProvider');
  return context;
}

function useToastsDispatch() {
  const context = React.useContext(ToastsDispatchContext);
  if(!context) throw new Error('useToastsDispatch must be used in ToastsProvider');
  return context;
}

function ToastsProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { toasts: [] });

  return (
    <ToastsStateContext.Provider value={state}>
      <ToastsDispatchContext.Provider value={dispatch}>
        {props.children}
      </ToastsDispatchContext.Provider>
    </ToastsStateContext.Provider>
  )
}

function updateToasts(dispatch, { toasts }) {
  dispatch({ toasts });
}

export { ToastsProvider, updateToasts, useToastsDispatch, useToastsState };