import { useState } from 'react'
import { Button } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControlProvider } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import Checklist from './Checklist'

export default function Checklists() {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  return (
    <>
      { task?.checklists === undefined
        ? <section className="mb-3">
            <h6 className="mb-1">
              <FontAwesomeIcon icon={['far', 'list-check']} fixedWidth /> CHECKLIST
            </h6>
            <Skeleton />  {/* PROGRESS BAR */}
            <Skeleton count={3} height={35} />  {/* CHECKLIST ITEMS */}
          </section>
        : <>
            { task?.checklists?.map(checklist => <Checklist checklist={checklist} key={checklist.id} />) }
          </>
      }
      
    </>
  )
}