import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Title({ icon, subtitle, title }) {
  return (
    <div className="app-page-title pb-0">
      <div className="page-title-wrapper">
        <div className="page-title-heading p-4">
          <div className="page-title-icon bg-crystal-blue">
            <FontAwesomeIcon icon={['fad', icon]} color="white" fixedWidth />
          </div>
          <div>
            {title}
            <div className="page-title-subheading">
              {subtitle}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}