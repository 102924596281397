export default function formatApiError(error) {
  switch (error?.code) {
    case undefined:
      var body = [
        error?.error,
      ];
    break;
    default:
      var body = error?.request?.statusText === ''
        ? [
            `${error?.name} — ${error?.message}`,
            error?.config?.baseURL + error?.config?.url,
          ]
        : [
            `${error?.request?.statusText} — ${error?.message}`,
            error?.response?.data?.message || null,
            error?.request?.responseURL,
          ];
  }

  return body.filter(x => x);  // https://stackoverflow.com/a/48163228/6663785
}