import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import AssigneeInitials from 'pages/dashboards/components/AssigneeInitials'

export default function Members() {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();

  return (
    <section className="mb-3">
      <h6 className="mb-1">
        <FontAwesomeIcon icon={['far', 'circle-user']} fixedWidth /> MEMBERS
      </h6>

      <p className="pl-2">
        { task.assignees?.map(assignee => <AssigneeInitials key={assignee.id} assignee={assignee} className="py-2" />)
          ??  <Skeleton 
                circle 
                count={4} 
                inline="true" 
                height={38} 
                width={38} 
                style={{ marginRight: '0.25rem' }} 
              />
        }
      </p>
    </section>
  )
}