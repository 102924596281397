import './modal-task.css';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import projectBuckets from 'data/projectBuckets.json';
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext';
import { stripTaskModalFromPath } from 'assets/js/utilities/stripPaths';
// import Title from '../../components/TaskModal/Title';
import Title from './components/Title';
// import Actions from '../../components/TaskModal/Actions';
import Actions from './components/Actions';
// import Details from '../../components/TaskModal/Details';
import Details from './components/Details';
import TM from 'assets/js/classes/TaskModal';
import ModalQrCode from '../QrCode';
import ModalSendEmail from '../SendEmail';
import ModalSendSms from '../SendSms';
import projectsArchived from 'data/projectsArchived'
import useApiCall from 'hooks/useApiCall'
import useWebSocket from 'hooks/useWebSocket'
import ApiChannels from 'assets/js/classes/ApiChannels'
import ApiEvents from 'assets/js/classes/ApiEvents'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import { FormControlProvider } from 'contexts/FormControlContext'

export default function ModalTask({ archive }) {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  // FORM PARAMS
  const [loading, setLoading] = useState(true);

  // ROUTER DOM PARAMS
  const params = useParams();

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: ApiRoutes.TASK(params.taskId),
    callback: ({ task }) => stateUpdateTaskModal(taskModalDispatch, { task }),
    setLoading,
  });
  // TODO: SUBSCRIBE TO TASK LISTENER

  // useWebSocket(ApiChannels.PRIVATE_TENANT(tenant?.id), [
  //   {
  //     events: [ApiEvents.TENANT_UPDATED],
  //     callback: ({ author, tenant }) => eventTenantUpdated(
  //       { author, user, tenant, callback: () => updateTenant(tenantDispatch, { tenant }) }, 
  //       { toasts, toastsDispatch, updateToasts }),
  //   },
  // ]);

  
  // CLOSE MODAL REDIRECT
  const navigate = useNavigate();
  const handleClose = () => navigate(stripTaskModalFromPath());

  return (
    <>
      <Modal id="ModalTask" show={true} onHide={handleClose} size="lg" keyboard={false}>
        <Modal.Header closeButton className="bg-white align-items-center">
          <Modal.Title className="flex-fill">
            <FormControlProvider>
              <Title task={task} />
            </FormControlProvider>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xs={12} lg={8}>
              <Details task={task} />
            </Col>
            <Col xs={12} lg={4}>
              <Actions task={task} />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ModalSendEmail task={task} />
      <ModalSendSms task={task} />
      <ModalQrCode task={task} />
    </>
  )
}