import axios from 'axios'
import { urlBackend, urlSanctumCsrf } from './helpers'

class AxiosMethods {
  static GET = 'GET';
  static DELETE = 'DELETE';
  static HEAD = 'HEAD';
  static OPTIONS = 'OPTIONS';
  static POST = 'POST';
  static PUT = 'PUT';
  static PATCH = 'PATCH';
}

const axiosClient = axios.create({
  baseURL: urlBackend(),
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  withXSRFToken: true,
});

const getCsrfCookie = async () => {
  let response;
  try {
    response = await axiosClient.get(urlSanctumCsrf());
  } catch (error) {
    return { error };
  }
  return response;
}

axiosClient.apiCall = async (callback) => {
  let response;  // DECLARE VARS

  try {  // GET XSRF TOKEN
    response = await getCsrfCookie();
  } catch (error) {
    return { error };
  }

  try {  // THEN MAKE API CALL
    response = await callback();
  } catch (error) {
    return { error };
  }
  return response;
}

export { axiosClient, AxiosMethods };