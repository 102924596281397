import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faCalendarDay, faClock } from "@fortawesome/pro-regular-svg-icons"
// import { faCalendarDay, faClock } from "assets/fontawesome/svgs/regular";
import DatePicker, { registerLocale } from 'react-datepicker'
import moment from 'moment'
import enIE from 'date-fns/locale/en-IE'
import { useState } from "react"
import { Col, Row } from "react-bootstrap"
registerLocale('en-IE', enIE)

export default function Deadline() {
  // STATE PARAMS
  const [selected, setSelected] = useState(new Date((new Date()).setHours(12, 0, 0, 0)));

  return (
    <>
      <Row>
        <Col md={8} lg={6}>
          <section className="mb-3">
            <h6 className="mb-1">
              <FontAwesomeIcon icon={['far', 'calendar-day']} fixedWidth /> Deadline Date <span className="text-danger">*</span>
            </h6>

            <DatePicker 
              calendarClassName="shadow-sm" 
              dateFormat="dd/MMM/yyyy" 
              inline
              locale="en-IE" 
              minDate={new Date()}
              onChange={date => setSelected(date)}
              selected={selected}
              // showMonthDropdown showYearDropdown
            />
          </section>
        </Col>

        <Col md={4} lg={6}>
          <section className="mb-3">
            <h6 className="mb-1">
              <FontAwesomeIcon icon={['far', 'clock']} fixedWidth /> Deadline Time
            </h6>

            <DatePicker 
              calendarClassName="shadow-sm" 
              dateFormat="HH:mm" 
              inline
              locale="en-IE" 
              onChange={date => setSelected(date)}
              selected={selected}
              showTimeSelect 
              showTimeSelectOnly
              timeIntervals={15}
            />
          </section>
        </Col>
      </Row>

      <input type="hidden" id="deadline" name="deadline" value={moment(selected).format('YYYY-MM-DD HH:mm:ss')} />
    </>
  )
}