import React from 'react';

const DELAY = 500;

const FormControlStateContext = React.createContext();
const FormControlDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useFormControlState() {
  const context = React.useContext(FormControlStateContext);
  if(!context) throw new Error('useFormControlState must be used in FormControlProvider');
  return context;
}

function useFormControlDispatch() {
  const context = React.useContext(FormControlDispatchContext);
  if(!context) throw new Error('useFormControlDispatch must be used in FormControlProvider');
  return context;
}

function FormControlProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { disabled: false, response: {} });

  return (
    <FormControlStateContext.Provider value={state}>
      <FormControlDispatchContext.Provider value={dispatch}>
        {props.children}
      </FormControlDispatchContext.Provider>
    </FormControlStateContext.Provider>
  )
}

function stateUpdateFormControl(dispatch, data) {
  dispatch({...data});

  return new Promise(resolve => {
    setTimeout(() => resolve(true), DELAY);
  });
}

export { FormControlProvider, stateUpdateFormControl, useFormControlDispatch, useFormControlState };