import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TaskCard from '../TaskCard';

export default function StaticBoardTask(props) {
  return (
    <Link role="button" to={`task/${props.task.id}`} className="mb-2 d-block text-decoration-none text-dark">
      <Card className="taskCard p-2 border-0 shadow-sm">
        <TaskCard task={props.task} />
      </Card>
    </Link>
  )
}