import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, FloatingLabel, Form, InputGroup } from 'react-bootstrap'
import useStateCallback from 'hooks/useStateCallback'
import SubmitBtn from 'components/forms/SubmitBtn'
import { useEffect } from 'react';

export default function InputGroupButtonFloating({ as, btnState, btnType, className, disabled, icon, invalidFeedback, label, name, onChange, required, response, type, value, variant }) {
  // STATE PARAMS
  const [val, setVal] = useStateCallback(value ?? '');

  useEffect(() => setVal(value), [value]);
  
  return (
    <InputGroup className={className ?? 'mb-4'}>
      <FloatingLabel label={label}>
        <Form.Control
          as={as}
          disabled={disabled} 
          isInvalid={(Object.keys(invalidFeedback ?? {}).length > 0 && invalidFeedback?.[name] !== undefined) ?? null}
          isValid={(Object.keys(invalidFeedback ?? {}).length > 0 && invalidFeedback?.[name] === undefined) ?? null}
          name={name} 
          onChange={e => setVal(e.target.value, onChange)}
          placeholder={label} 
          required={required} 
          type={type ?? 'text'} 
          value={val} 
        /> 
        <Form.Control.Feedback type="invalid" tooltip>{invalidFeedback?.[name]}</Form.Control.Feedback>
      </FloatingLabel>
      <SubmitBtn 
        className="border rounded-start-0 rounded-end-2"
        disabled={disabled}
        icon={icon}
        response={response}
        state={btnState}
        variant={variant ?? 'primary'}
      />
      {/* <Button
        className="border"
        disabled={disabled}
        type={btnType ?? 'submit'}
        variant={variant ?? 'primary'}
        state={btnState}
        response={response}
      ><FontAwesomeIcon icon={icon} fixedWidth />
      </Button> */}
    </InputGroup>
  )
}