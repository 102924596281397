import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControlProvider } from 'contexts/FormControlContext'
import ClientEmail from './ClientEmail'
import ClientName from './ClientName'
import ClientPhone from './ClientPhone'

export default function Client() {
  // STATE PARAMS
  const [showClientDetails, setShowClientDetails] = useState(true);

  return (
    <>
      <section className="mb-3">
        <span className="d-flex align-items-top">
          <h6 className="mb-1 d-inline">
            <FontAwesomeIcon icon={['far', 'id-card']} fixedWidth /> CLIENT
          </h6>

          <Button id="clientDetailsToggle" variant="outline-light" className="rounded-circle ml-1" onClick={() => setShowClientDetails(v => !v)}>
            <FontAwesomeIcon icon={['fas', showClientDetails ? 'minus' : 'plus']} fixedWidth size="xs" className="py-1" />
          </Button>
        </span>
        { showClientDetails &&
          <>
            <FormControlProvider>
              <ClientName />
            </FormControlProvider>

            <FormControlProvider>
              <ClientEmail />
            </FormControlProvider>

            <FormControlProvider>
              <ClientPhone />
            </FormControlProvider>
          </>
        }
      </section>
    </>
  )
}