import handleToast from './handleToast'

export default function eventAuthUserUpdated({ author, user, callback = (args) => undefined } = {}, { toasts, toastsDispatch, updateToasts }) {
  if(author?.id === user?.id) return;

  handleToast({
    title: 'Account Updated', 
    message: `${user?.name} (${user?.email}) was updated by ${author?.name} (${author?.email}). Would you like to get the latest update?`,
    autohide: false, 
    label: 'Get update', callback,
  }, { toasts, toastsDispatch, updateToasts });
}