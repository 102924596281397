import moment from 'moment'
import { useState } from 'react'
import { Badge, Modal } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { stripTopUpConfirmationModalFromPath } from 'assets/js/utilities/stripPaths'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import useApiCall from 'hooks/useApiCall'

export default function ConfirmationModal() {
  // REACT ROUTER DOM
  const { search } = useLocation();
  const searchParams = createSearchParams(search ?? '');

  // CLOSE MODAL REDIRECT
  const navigate = useNavigate();
  const handleClose = () => navigate(stripTopUpConfirmationModalFromPath());

  // STATE PARAMS
  const [paymentIntent, setPaymentIntent] = useState(undefined);

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: [
      ApiRoutes.CASHIER_PAYMENT_INTENT,
      searchParams.get('payment_intent'),
    ].join('/'),
    callback: ({ payment_intent }) => setPaymentIntent(payment_intent),
  });

  return (
    <Modal id="topUpConfirmationModal" show={true} onHide={handleClose} size="lg" keyboard={true}>
      <Modal.Header closeButton className="bg-white align-items-center">
        <Modal.Title className="flex-fill">
          <FontAwesomeIcon icon={['far', 'file-invoice']} fixedWidth /> Payment <Badge bg="success">{paymentIntent?.status}</Badge>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-column">
          {/* PAYMENTINTENT ID */}
          <div className="d-flex flex-row align-items-center mb-3">
            <div className="page-title-icon bg-crystal-blue p-3 mr-3 rounded text-white">
              <FontAwesomeIcon icon={['far', 'hashtag']} fixedWidth size="2x" />
            </div>
            <span className="w-100">
              { paymentIntent
                ? paymentIntent?.id
                : <Skeleton />
              }
            </span>
          </div>
          
          {/* AMOUNT */}
          <div className="d-flex flex-row align-items-center mb-3">
            <div className="page-title-icon bg-crystal-blue p-3 mr-3 rounded text-white">
              <FontAwesomeIcon icon={['far', 'euro-sign']} fixedWidth size="2x" />
            </div>
            <span className="w-100">
              { paymentIntent
                ? `${(paymentIntent?.amount/ 100).toFixed(2)} ${paymentIntent?.currency?.toUpperCase()}`
                : <Skeleton />
              }
            </span>
          </div>

          {/* DESCRIPTION */}
          <div className="d-flex flex-row align-items-center mb-3">
            <div className="page-title-icon bg-crystal-blue p-3 mr-3 rounded text-white">
              <FontAwesomeIcon icon={['far', 'file-invoice']} fixedWidth size="2x" />
            </div>
            <span className="w-100">
              { paymentIntent
                ? paymentIntent?.description
                : <Skeleton />
              }
            </span>
          </div>

          {/* DATE */}
          <div className="d-flex flex-row align-items-center mb-3">
            <div className="page-title-icon bg-crystal-blue p-3 mr-3 rounded text-white">
              <FontAwesomeIcon icon={['far', 'calendar-day']} fixedWidth size="2x" />
            </div>
            <span className="w-100">
              { paymentIntent
                ? moment(paymentIntent?.created, 'X').format('Do MMMM, YYYY [at] HH:mm Z')
                : <Skeleton />
              }
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}