import { forwardRef, useRef, useState } from 'react'
import { Accordion, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { Form } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import { Priority as Prio } from 'assets/js/classes/Task'
import FormButton from 'components/forms/FormButton'
import FormSelect from 'components/forms/FormSelect'
import { FormControlProvider } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import DatePicker, { registerLocale } from 'react-datepicker'
import enIE from 'date-fns/locale/en-IE'
registerLocale('en-IE', enIE)

// ACCORDION PARAMS
const accordionEventKey = 0;
const AccordionButton = forwardRef((props, ref) => {
  const decoratedOnClick = useAccordionButton(accordionEventKey);
  
  return (
    <Button {...props} ref={ref} onClick={decoratedOnClick} />
  )
});

export default function DeadlineDate() {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  // STATE PARAMS
  const [deadlineDate, setDeadlineDate] = useState(new Date());

  return (
    <section className="mb-1">
      <Form
        onReset={() => setDeadlineDate(new Date(task?.deadline))}
        onSubmit={undefined}
      >
        <Accordion>
          <OverlayTrigger 
            overlay={<Tooltip>Deadline Date</Tooltip>}
            placement="top" 
          >
            <AccordionButton
              className="border-0 w-100 text-left"
              disabled={!Boolean(Object.values(task).length) || Boolean(Number(task?.archived))}
              type="reset" 
              variant="light"
            >
              <FontAwesomeIcon icon={['far', 'calendar-day']} fixedWidth className="mr-1" />
              { task?.deadline === undefined
                ? 'Deadline Date'
                : moment(task.deadline).format('Do MMM')
              }
            </AccordionButton>
          </OverlayTrigger>

          <Accordion.Collapse
            className="d-flex justify-content-center"
            eventKey={accordionEventKey}
          >
            <div className="mt-1"> {/* Accordion Collapse requires one child only. */}
              <DatePicker 
                calendarClassName="shadow-0"
                dateFormat="dd/MMM/yyyy"
                inline
                locale="en-IE"
                name="deadlineDate"
                onChange={date => setDeadlineDate(date)}
                selected={deadlineDate}
              />
              <input 
                name="deadline" 
                type="hidden" 
                value={moment(deadlineDate).format('YYYY-MM-DD HH:mm:ss')}
              />
              <span className="d-flex justify-content-center">
                <FormControlProvider>
                  <FormButton
                    className="mx-1"
                    disabledProp={task?.archived || task?.deadline === undefined}
                    modelContext={{ task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
                    onClick={ModalTaskClass.handleUpdateDeadline}
                    type="submit"
                    variant="primary"
                  >Update
                  </FormButton>
                </FormControlProvider>
                <AccordionButton
                  disabled={task?.archived || task?.deadline === undefined}
                  type="reset" 
                  variant="light"
                >Cancel</AccordionButton>
              </span>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </Form>
    </section>
  )
}