import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRight, faToolbox, faSquareKanban } from "@fortawesome/pro-duotone-svg-icons";
// import { faArrowRight, faToolbox, faSquareKanban } from "assets/fontawesome-6/svgs/duotone";
import Main from "layouts/Main";
import { Card, ListGroup } from "react-bootstrap";
import { NavLink } from 'react-router-dom';

export default function Landing() {
  return (
    <Main title="Collaboro" subtitle="Repairware" icon={'toolbox'}>
      <Card>
        <Card.Body className="p-0">
          <ListGroup variant="flush">
            <NavLink end to="./projects" className="text-decoration-none">
              <ListGroup.Item action className="p-4">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <FontAwesomeIcon icon={['fad', 'square-kanban']} fixedWidth size="2x" className="mr-4 text-crystal-blue" />
                  <h5 className="d-inline w-100 m-0 pl-3">Dashboards</h5>
                  <FontAwesomeIcon icon={['fad', 'arrow-right']} fixedWidth size="2x" className="ml-4 text-crystal-blue" />
                </div>
              </ListGroup.Item>
            </NavLink>
          </ListGroup>
        </Card.Body>
      </Card>
    </Main>
  )
}