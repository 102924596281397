import Main from 'layouts/Main'
import { Card } from 'react-bootstrap'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'
import MyDetailsForm from './MyDetailsForm'

export default function MyDetails() {
  return (
    <Main title="My Details" subtitle="Account" icon="id-card">
      <Card>
        <Card.Body>
          <SubmitBtnProvider>
            <MyDetailsForm />
          </SubmitBtnProvider>
        </Card.Body>
      </Card>
    </Main>
  )
}