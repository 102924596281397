import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
// import { faSquareKanban } from '@fortawesome/pro-duotone-svg-icons'
// import { faSquareKanban } from "assets/fontawesome-6/svgs/duotone";
import { DragDropContext } from 'react-beautiful-dnd'
import { StrictModeDroppable } from 'components/ui/StrictModeDroppable'
import projectBuckets from 'data/projectBuckets.json'
import DragAndDrop from 'assets/js/classes/DragAndDrop'
import DynamicBoardColumn from './components/DynamicBoard/Column'
import { stateSetScrollSnap, stateUpdateBuckets, useDynamicBoardDispatch, useDynamicBoardState } from 'contexts/DynamicBoardContext'
import { stateUpdateDataset, useSearchBarDispatch, useSearchBarState } from 'contexts/SearchBarContext'
import './css/trello.css'
import Title from 'layouts/Main/Title'
import SearchBar from './components/SearchBar'
import DynamicBoardSettings from './components/DynamicBoard/Settings'
import Buckets from 'assets/js/classes/Buckets'
import scrollDrag from './components/DynamicBoard/scrollDrag'
import Skeleton from 'react-loading-skeleton'

export default function DynamicBoard() {
  // DYNAMIC BOARD CONTEXT
  const { buckets, scrollSnap } = useDynamicBoardState();
  const dynamicBoardDispatch = useDynamicBoardDispatch();
  
  // SEARCH BAR CONTEXT
  const { dataset } = useSearchBarState();
  const searchBarDispatch = useSearchBarDispatch();
  
  useEffect(() => {
    setTimeout(() => {  // TODO: remove timeout
      const buckets = projectBuckets;  // TODO: API CALL TO GET BUCKETS
      stateUpdateBuckets(dynamicBoardDispatch, { buckets: structuredClone(buckets) });
      stateUpdateDataset(searchBarDispatch, { dataset: structuredClone(buckets) });
    }, 500)
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragEnd = ({ destination, source, type, draggableId }) => {
    stateSetScrollSnap(dynamicBoardDispatch, true);  // ENABLE SCROLL SNAP

    if(!destination) return;

    if(
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) return;

    const dnd = new DragAndDrop(buckets);
    const dndDataset = new DragAndDrop(dataset);

    switch(type) {
      case DragAndDrop.BUCKETS:
        dnd.moveBucket(destination, source);
        dndDataset.moveBucket(destination, source);
        // TODO: API call to update order
        break;
      case DragAndDrop.TASKS:
        dnd.moveTask(destination, source);
        dndDataset.moveTask(destination, {
          index: dndDataset.getTaskIndex(draggableId, source.droppableId),
          droppableId: source.droppableId
        });
        // TODO: API call to update order
        break;
      default: break;
    }
    stateUpdateBuckets(dynamicBoardDispatch, { buckets: dnd.buckets });
    stateUpdateDataset(searchBarDispatch, { dataset: dndDataset.buckets });
  }

  const onDragStart = () => stateSetScrollSnap(dynamicBoardDispatch, false);  // DISABLE SCROLL SNAP
  
  return (
    <>
      <div className="app-main__inner p-0 d-flex flex-column">
        <Title title="All Projects" subtitle="Dashboard" icon={'square-kanban'} />

        <Row className="px-3">
          <Col xs={12} sm={2} className="d-flex align-items-center justify-content-center">
            <DynamicBoardSettings />
          </Col>
          <Col xs={12} sm={10}>
            <SearchBar dataset={dataset} stateUpdate={stateUpdateBuckets} stateKey="buckets" stateType={Array} dispatch={dynamicBoardDispatch} filterCallback={Buckets.filterByQuery} />
          </Col>
        </Row>

        {buckets.length === 0
          ? <Row className="px-3 flex-nowrap">
              {Array.from(Array(3), (_,i) =>
                <Col key={i} xs={12} md={6} lg={5} xl={4}>
                  <Skeleton height={210} />
                </Col>
              )}
            </Row>
          : <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <StrictModeDroppable droppableId="allBuckets" direction="horizontal" type={DragAndDrop.BUCKETS}>
                {(provided, snapshot) => (
                  <div className="d-flex flex-nowrap flex-fill" id="trello"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    onMouseDown={e => scrollDrag(e, stateSetScrollSnap, dynamicBoardDispatch)}
                    style={{ userSelect: 'none', scrollSnapType: scrollSnap ? 'x mandatory' : 'none' }}>

                    {buckets.length > 0 
                      && buckets.map((bucket, index) => <DynamicBoardColumn key={bucket.id} bucket={bucket} index={index} />)
                    }
                    
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </DragDropContext>
        }
      </div>
    </>
  )
}