import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import FormControl from 'components/forms/FormControl'
import moment from 'moment'
import AssigneeInitials from 'pages/dashboards/components/AssigneeInitials'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { FormControlProvider } from 'contexts/FormControlContext'
import FormButton from 'components/forms/FormButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStateCallback from 'hooks/useStateCallback'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import { useAuthState } from 'contexts/AuthContext'

const stretchTextarea = (textareaRef) => {
  const padding = 2;  // Need padding to remove vertical scroll bar
  textareaRef.current.style.height = 'auto';  // Need to set to auto instead of 0px because the transition timing makes the scrollHeight calculation incorrect when shrinking.
  textareaRef.current.style.height = textareaRef.current.scrollHeight + padding + 'px';
}

export default function Comment({ comment }) {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  // CONTEXT PARAMS - AUTH USER
  const { user } = useAuthState();

  // STATE PARAMS
  const [show, setShow] = useState(false);
  const [body, setBody] = useStateCallback(comment?.body ?? '');
  const [showButtons, setShowButtons] = useStateCallback(false);

  // REFS
  const textareaRef = useRef(null);
  
  // CONSTANTS
  const commentIndex = ModalTaskClass.getCommentIndex(task, comment?.id);
  const authorised = comment.user.id === user.id;

  useEffect(() => {
    setShow(true);
    stretchTextarea(textareaRef);
  }, []);

  useEffect(() => {
    stretchTextarea(textareaRef);
  }, [comment]);
  
  return (
    <div className={`d-flex flex-row mb-1 fade ${show ? "show" : ""}`}>
      <span>
        <AssigneeInitials assignee={comment.user} />
      </span>
      <span className="w-100 bg-light rounded p-2 overflow-hidden">
        <h6>
          <strong>{comment.user.username}</strong> at { moment(comment.created_at).format('HH:mm [on] DD/MMM/YYYY') } 
          { comment.created_at !== comment.updated_at &&
            <OverlayTrigger 
              overlay={<Tooltip>{moment(comment.updated_at).format('HH:mm [on] DD/MMM/YYYY')}</Tooltip>}
              placement="top" 
            ><span className="mx-1 badge badge-pill badge-light border text-lowercase fst-italic">edited</span>
            </OverlayTrigger>
          }
        </h6>
        <Form 
          autoComplete="off"
          onSubmit={e => e.preventDefault()}
        >
          <FormControlProvider>
            <FormControl 
              as="textarea"
              className="border-light bg-light"
              name={`comments.${commentIndex}.body`}
              onBlur={e => undefined}
              onFocus={() => setShowButtons(true)}
              onKeyDown={e => undefined}
              _ref={textareaRef}
              readOnly={!authorised}
              value={body}
              setValue={setBody}
            />
          </FormControlProvider>
            { showButtons && authorised && 
              <div className="d-flex flex-row justify-content-end mt-1">

                {/* CANCEL */}
                <OverlayTrigger 
                  overlay={<Tooltip>Cancel</Tooltip>}
                  placement="top" 
                >
                  <Button
                    onClick={() => { setShowButtons(false); setBody(Math.random(), () => setBody(comment.body)) }}
                    variant="light"
                  ><FontAwesomeIcon fixedWidth icon={['far', 'xmark']} />
                  </Button>
                </OverlayTrigger>

                {/* DELETE */}
                <OverlayTrigger 
                  overlay={<Tooltip>Delete Comment</Tooltip>}
                  placement="top" 
                >
                  <span
                    className="ml-1"
                  >{/* Need span here because function components cannot be given refs. */}
                    <FormControlProvider>
                      <FormButton
                        alert={{
                          title: 'Delete this comment?',
                          message: null,
                          options: {
                            confirmBtnBsStyle: 'danger',
                            type: 'danger',
                          }
                        }}
                        className="btn-outline-danger"
                        modelContext={{ task, comment, commentIndex, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
                        onClick={ModalTaskClass.handleDeleteComment}
                        variant="light"
                      ><FontAwesomeIcon fixedWidth icon={['far', 'trash-can']} />
                      </FormButton>
                    </FormControlProvider>
                  </span>
                </OverlayTrigger>

                {/* SAVE */}
                <OverlayTrigger 
                  overlay={<Tooltip>Save Comment</Tooltip>}
                  placement="top" 
                >
                  <span
                    className="ml-1"
                  >{/* Need span here because function components cannot be given refs. */}
                    <FormControlProvider>
                      <FormButton
                        className="btn-outline-primary"
                        modelContext={{ task, comment, commentIndex, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
                        onClick={ModalTaskClass.handleUpdateComment}
                        variant="light"
                      ><FontAwesomeIcon fixedWidth icon={['far', 'paper-plane']} />
                      </FormButton>
                    </FormControlProvider>
                  </span>
                </OverlayTrigger>
              </div>
            }
          </Form>
      </span>
    </div>
  )
}