function stripNewTaskFromPath() {
  const host = window.location.host;  //eslint-disable-next-line
  const path = window.location.href.toString().split(host)[1].split('?')[0].match(/[\/\w-]+(?=\/new-task)/gi)?.[0];  // POSITIVE LOOKAHEAD
  return path;
}

function stripSmsHistoryModalFromPath() {
  const host = window.location.host;  //eslint-disable-next-line
  const path = window.location.href.toString().split(host)[1].split('?')[0].match(/[\/\w-]+(?=\/\w+)/gi)?.[0];  // POSITIVE LOOKAHEAD
  return path;
}

function stripTaskModalFromPath() {
  const host = window.location.host;  //eslint-disable-next-line
  const path = window.location.href.toString().split(host)[1].split('?')[0].match(/[\/\w-]+(?=\/task\/\d+)/gi)?.[0];  // POSITIVE LOOKAHEAD
  return path;
}

function stripTopUpHistoryModalFromPath() {
  const host = window.location.host;  //eslint-disable-next-line
  const path = window.location.href.toString().split(host)[1].split('?')[0].match(/[\/\w-]+(?=history\/cbtxn_\w*)/gi)?.[0];  // POSITIVE LOOKAHEAD
  return path;
}

function stripTopUpConfirmationModalFromPath() {
  const host = window.location.host;  //eslint-disable-next-line
  const path = window.location.href.toString().split(host)[1].split('?')[0].match(/[\/\w-]+(?=confirmation.*)/gi)?.[0];  // POSITIVE LOOKAHEAD
  return path;
}

function stripEmailVerificationTokenFromPath() {};  // TODO
function stripPasswordResetTokenFromPath() {};  // TODO

export { 
  stripNewTaskFromPath, 
  stripSmsHistoryModalFromPath, 
  stripTaskModalFromPath, 
  stripTopUpConfirmationModalFromPath, 
  stripTopUpHistoryModalFromPath, 
}