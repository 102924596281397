import ReactDOMServer from 'react-dom/server';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Submitter {
  btnDanger = 'btn-danger';
  btnSuccess = 'btn-success';
  #button;
  delay = 1000;
  #originalText;

  constructor(form, { status = 'Saving...', success = 'Success!', failure = 'Failed!', btnClass = 'btn-primary' } = {}, event = undefined) {
    this.#button = event !== undefined ? event.nativeEvent.submitter : form.querySelector('[type=submit]');
    this.#originalText = this.#button.innerHTML;
    this.status = status;
    this.success = success;
    this.failure = failure;
    this.btnClass = btnClass;

    this.#disable();
  }

  #disable() {
    this.#button.setAttribute('disabled', true);
    this.#button.innerHTML = ReactDOMServer.renderToStaticMarkup(
      <>
        <Spinner animation="border" size="sm" className={`${this.status === '' ? '' : 'mr-1'}`} /> {this.status}
      </>
    );
  }

  #enable() {
    this.#button.removeAttribute('disabled');
    this.#button.innerHTML = this.#originalText;

    // RE-ADD DEFAULT CLASS
    this.#button.classList.remove(this.btnDanger, this.btnSuccess);
    this.#button.classList.add(this.btnClass)
  }

  eval(response) {
    return new Promise(resolve => {
      setTimeout(() => { // DELAY TO GIVE TIME TO SHOW THE LOADER
        // REMOVE DEFAULT CLASS
        this.#button.classList.remove(this.btnClass);

        // PROCESS THE ASYNC RESPONSE
        if(response.status >= 200 && response.status < 300) {
          // SET ICON & ADD CLASS
          this.#button.innerHTML = ReactDOMServer.renderToStaticMarkup(
            <>
              <FontAwesomeIcon icon={['far', 'circle-check']} fixedWidth className={`${this.success === '' ? '' : 'mr-1'}`} /> {this.success}
            </>
          );
          this.#button.classList.add(this.btnSuccess);
        } else {
          // SET ICON & ADD CLASS
          this.#button.innerHTML = ReactDOMServer.renderToStaticMarkup(
            <>
              <FontAwesomeIcon icon={['far', 'circle-xmark']} fixedWidth className={`${this.failure === '' ? '' : 'mr-1'}`} /> {this.failure}
            </>
          );
          this.#button.classList.add(this.btnDanger);
        }

        setTimeout(() => { this.#enable(); resolve() }, this.delay); // DELAY TO GIVE TIME TO SHOW SUCCESS/FAILURE
      }, this.delay / 2);
    });
  }
}

export default Submitter;