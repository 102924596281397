import { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Card, Col, Dropdown, Form } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DragAndDrop from 'assets/js/classes/DragAndDrop'
import { StrictModeDroppable } from 'components/ui/StrictModeDroppable'
import DynamicBoardTask from '../components/DynamicBoard/Task';

class DropZoneHeight {
  static HEIGHT_THRESHOLD = 5;
  static MARGIN = 8;  // 8 compensates for bottom margin my-2
}

export default function DynamicBoardColumn({ bucket, index }) {
  return (
    <Draggable draggableId={`draggable-bucket-${bucket.id}`} index={index}>
      {(provided, snapshot) => (
        <Col xs={12} md={6} lg={5} xl={4}
          {...provided.draggableProps}
          ref={provided.innerRef}>

          <Card className={`bucketCard border-0 pt-2 my-2 align-self-start ${snapshot.isDragging ? 'shadow' : 'shadow-sm'}`}>
            <span className="d-flex align-items-stretch">

              <div className="p-2 transparentBorder" {...provided.dragHandleProps}>
                <FontAwesomeIcon icon={['fas', 'grip-dots-vertical']} fixedWidth />
              </div>
              
              <>
                <div className="bucketTitle p-2 pl-0 flex-fill" onClick={undefined}>{bucket.title}</div>
              
                <Dropdown align="end" className="d-flex align-items-center">
                  <Dropdown.Toggle variant="link" className="rounded-0 align-self-stretch">
                    <FontAwesomeIcon icon={['fas', 'ellipsis']} color="#495057" fixedWidth />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>

                    <Dropdown.Item as={NavLink} end to={`/projects/new-task/${bucket.slug}`}>
                      <FontAwesomeIcon icon={['fas', 'rectangle-history-circle-plus']} fixedWidth className="mr-2" />New Task
                    </Dropdown.Item>

                    <Dropdown.Item onClick={undefined} className="text-danger">
                      <FontAwesomeIcon icon={['fas', 'trash']} fixedWidth className="mr-2" />Delete List
                    </Dropdown.Item>
                    
                  </Dropdown.Menu>
                </Dropdown>
              </>
            </span>

            <StrictModeDroppable droppableId={`droppable-bucket-${bucket.id}`} type={DragAndDrop.TASKS}>
              {(provided, snapshot) => (
                <div className="bucketDropZone py-2 pl-2 pr-1" style={snapshot.isDraggingOver ? { background: 'rgba(247, 185, 36, 0.1)' } : {}}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>

                  {bucket.tasks?.map((task, index) => <DynamicBoardTask key={task.id} task={task} index={index} />)}
                  {/* {bucket.tasks?.map((task, index) => task.id)} */}
      
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </Card>
        </Col>
      )}
    </Draggable>
  )
}