import * as React from "react"

const Logo = ({ className, size, textColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={size ?? 32}
    height={size ?? 32}
    viewBox="0 0 500 500"
    className={className}
  >

    <path
      d="M-101.3-101.3h202.6v202.6h-202.6z"
      style={{
        stroke: "#bcf541",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: "#5ab1bb",
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="rotate(-44.999 504.273 10.315) scale(.19743 1.72757)"
    />

    <path
      d="M-101.3-101.3h202.6v202.6h-202.6z"
      style={{
        stroke: "#bcf541",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: textColor ?? "#fff",
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="matrix(.1396 -.1396 .71548 .71548 191.664 308.336)"
    />

    <path
      d="M-101.3-101.3h202.6v202.6h-202.6z"
      style={{
        stroke: "#bcf541",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: textColor ?? "#fff",
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="matrix(.1396 .1396 -.71548 .71548 191.664 191.664)"
    />

    <path
      d="M-101.3-101.3h202.6v202.6h-202.6z"
      style={{
        stroke: "#bcf541",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: textColor ?? "#fff",
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="matrix(.1396 .1396 -.71548 .71548 308.336 308.336)"
    />

    <path
      d="M-101.3-101.3h202.6v202.6h-202.6z"
      style={{
        stroke: "#bcf541",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: "#5ab1bb",
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="matrix(.1396 .1396 -1.22156 1.22156 359.602 359.602)"
    />

    <path
      d="M-101.3-101.3h202.6v202.6h-202.6z"
      style={{
        stroke: "#bcf541",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: "#5ab1bb",
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="rotate(135.001 150.722 144.674) scale(.19743 1.72757)"
    />

    <path
      d="M-101.3-101.3h202.6v202.6h-202.6z"
      style={{
        stroke: "#bcf541",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeDashoffset: 0,
        strokeLinejoin: "miter",
        strokeMiterlimit: 4,
        fill: "#5ab1bb",
        fillRule: "nonzero",
        opacity: 1,
      }}
      transform="rotate(-135.001 99.276 41.121) scale(.19743 1.72757)"
    />
  </svg>
)

export default Logo