import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { axiosClient } from 'assets/js/utilities/axios'
import eventFrontendVersionUpdated from 'assets/js/utilities/eventFrontendVersionUpdated'
import handleApiError from 'assets/js/utilities/handleApiError'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'

const checkFrontendVersion = async (toasts, toastsDispatch) => {
  // IF LOCAL STORAGE FLAG IS NOT SET
  if( !window.localStorage.getItem('frontend-version-update') ) {

    // MAKE API CALL
    const response = await axiosClient.apiCall(() => axiosClient.get(ApiRoutes.FRONTEND_VERSION));

    if(response?.error) {
      handleApiError(response.error, { toasts, toastsDispatch, updateToasts });
      
    } else if( response?.data?.version 
      && response?.data?.version !== process.env.REACT_APP_VERSION ) {
      eventFrontendVersionUpdated(response?.data, { toasts, toastsDispatch, updateToasts });

      // SET LOCAL STORAGE SO REFRESH OCCURS AFTER NEXT NAVIGATION
      window.localStorage.setItem('frontend-version-update', response?.data?.version);
    }
  }
}

export default function useFrontendVersionCheckAPI() {
  // CONTEXT PARAMS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  const location = useLocation();

  useEffect(() => {
    checkFrontendVersion(toasts, toastsDispatch);
  }, [location]);
}