export default class Toast {
  constructor(title, message, { autohide = true, sentiment = 'info' } = {}, { label, callback } = {}) {
    this.id = Math.floor(Math.random() * 1e9);
    this.title = title;
    this.message = message;
    this.autohide = autohide;
    this.sentiment = sentiment;
    this.action = { label, callback };
    this.created_at = new Date().toISOString();
  }
}