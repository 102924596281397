import { useState } from 'react'
import { Form } from 'react-bootstrap'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { AxiosMethods } from 'assets/js/utilities/axios'
import SwalClass from 'assets/js/classes/SweetAlert'
import { Sentiment } from 'assets/js/classes/BootstrapStyles'
import SubmitBtn from 'components/forms/SubmitBtn'
import ValidationAlert from 'components/ui/ValidationAlert'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'
import { updateSweetAlert, useSweetAlertDispatch } from 'contexts/SweetAlertContext'
import { updateUserEdit, useUserEditDispatch, useUserEditState } from 'contexts/UserEditContext'

export default function DeleteUserForm() {
  // CONTEXT PARAMS - SweetAlert, Toasts
  const sweetAlertDispatch = useSweetAlertDispatch();

  // CONTEXT PARAMS - UserEdit
  const { user } = useUserEditState();
  const userEditDispatch = useUserEditDispatch();

  // VALIDATION PARAMS
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState({});
  
  // SUBMIT BUTTON CONTEXT
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch()

  const throwAlert = e => {
    e.preventDefault();

    updateSweetAlert(sweetAlertDispatch, { alert: new SwalClass(
      'Delete this user account?', 
      user?.username,
      { onConfirm: () => handleDelete(e), type: Sentiment.DANGER }
    )});
  }

  const handleDelete = async e => {
    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.DELETE, route: `${ApiRoutes.USERS}/${user?.id}` });
    
    await submitter.validate({setShowValidationAlert, setValidationMessage});
    
    if(submitter?.success) {
      // RESET FORM/CONTEXT
      updateUserEdit(userEditDispatch, { user: null });
    }
  }

  return (
    <Form onSubmit={throwAlert}>
      <ValidationAlert 
        showValidationAlert={showValidationAlert} 
        validationMessage={validationMessage} />

      <SubmitBtn 
        disabled={user?.id === undefined}
        grid={false}
        icon={['fas', 'user-xmark']} 
        loadStatus="Deleting..."
        response={response} 
        state={btnState}
        text="Delete User"
        variant={Sentiment.DANGER}
      />
    </Form>
  )
}