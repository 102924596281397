import { Badge, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faComments, faExclamationCircle, faPaperclip, faTasks } from '@fortawesome/pro-solid-svg-icons';
// import { faComments, faExclamationCircle, faPaperclip, faTasks } from "assets/fontawesome-6/svgs/solid";
import { Priority, Task } from 'assets/js/classes/Task';
import AssigneeInitials from './AssigneeInitials';

export default function TaskCard({ task }) {
  const t = new Task(task);

  return (
    <Col className="px-2">
      <Row>
        <Col xs={7} className="pl-2 pr-0">
          <Badge bg="secondary" className="mr-1">#{task.id}</Badge>
          <Badge bg={t.style.status} className="px-1">{task.status}</Badge>
        </Col>
        <Col xs={5} className="pl-1 pr-2 text-right">
          <Badge bg={t.style.deadline}>{t.format.deadline}</Badge>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="pl-2">{task.title}</Col>
      </Row>

      <Row>
        <Col xs={6} className="pl-2">
          {task.assignees?.map(assignee => <AssigneeInitials key={assignee.id} assignee={assignee} className="my-2" />)}
        </Col>
        <Col xs={6} className="pr-2 text-right">
          {task.priority !== Priority.LOW &&

            <OverlayTrigger 
              placement="top"
              overlay={<Tooltip>{task.priority} priority</Tooltip>}>
              <FontAwesomeIcon icon={['fas', 'circle-exclamation']} className={`mr-1 text-${t.style.priority}`} />
            </OverlayTrigger>
          }
          
          {task.attachments?.length > 0 &&

            <OverlayTrigger 
              placement="top"
              overlay={<Tooltip>{task.attachments.length} attachment{task.attachments.length > 1 ? 's' : ''}</Tooltip>}>
              <FontAwesomeIcon icon={['fas', 'paperclip']} className="mr-1" />
            </OverlayTrigger>
          }

          {task.comments?.length > 0 &&
          
            <OverlayTrigger 
              placement="top"
              overlay={<Tooltip>{task.comments.length} comment{task.comments.length > 1 ? 's' : ''}</Tooltip>}>
              <FontAwesomeIcon icon={['fas', 'comments']} className="mr-1" />
            </OverlayTrigger>
          }

          {task.checklists?.length > 0 &&
            
            <OverlayTrigger 
              placement="top"
              overlay={<Tooltip>{`${t.tally.checklistsItems.complete}/${t.tally.checklistsItems.total} complete`}</Tooltip>}>
              <FontAwesomeIcon icon={['fas', 'list-check']} className="mr-1" />
            </OverlayTrigger>
          }
        </Col>
      </Row>
    </Col>
  )
}