import { Outlet, useLocation } from 'react-router-dom'
import Toasts from 'components/ui/Toasts'
import { ToastsProvider } from 'contexts/ToastsContext'
import useAuthUserCheck from 'hooks/useAuthUserCheck'
import useUpdatePageTitle from 'hooks/useUpdatePageTitle'

export default function Global() {
  // REACT ROUTER DOM
  const location = useLocation();

  useAuthUserCheck();  // Checks the backend for user authentication
  useUpdatePageTitle(location);  // Updates the page title based on URI path

  return (
    <ToastsProvider>
      <Toasts />
      <Outlet />
    </ToastsProvider>
  );
}