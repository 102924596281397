import { useState } from 'react'
import { Alert, Form, ProgressBar } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { Sentiment } from 'assets/js/classes/BootstrapStyles'
import { AxiosMethods } from 'assets/js/utilities/axios'
import SubmitBtn from 'components/forms/SubmitBtn'
import PasswordStrength from 'assets/js/classes/PasswordStrength'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ValidationAlert from 'components/ui/ValidationAlert'
import { stateLogin, useAuthDispatch } from 'contexts/AuthContext'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'

export default function ResetPasswordForm() {
  // USER AUTH CONTEXT
  const authDispatch = useAuthDispatch();

  // REACT ROUTER DOM
  // const navigate = useNavigate();
  const params = useParams();

  // SUBMIT BUTTON CONTEXT
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  // FORM PARAMS
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  
  // VALIDATION PARAMS
  const [formValidated, setFormValidated] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  
  // PASSWORD STRENGTH PARAMS
  const [pwStrength, setPwStrength] = useState(0);
  const [pwStrengthVariant, setPwStrengthVariant] = useState(null);
  const [passwordStrengthMessage, setPwStrengthMessage] = useState([]);

  const calcPwStrength = e => {
    const password = e.target.value;
    setPassword(password);

    if(password.length > 0) {
      const PC = new PasswordStrength(password, passwordConfirm);
      setPwStrength(PC.strength);
      setPwStrengthMessage(PC.pwMsg);
  
      if(PC.strength < 25) {
        setPwStrengthVariant(Sentiment.DANGER);
      } else if(PC.strength < 50) {
        setPwStrengthVariant(Sentiment.WARNING);
      } else if(PC.strength < 75) {
        setPwStrengthVariant(Sentiment.INFO);
      } else {
        setPwStrengthVariant(Sentiment.SUCCESS);
      }
    } else {
      setPwStrength(0);
      setPwStrengthMessage([]);
    }
  }
  
  const handleSubmit = async e => {
    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.PATCH, route: ApiRoutes.ACCOUNT_PASSWORD_RESET });

    await submitter.validate({setFormValidated, setShowValidationAlert, setValidationMessage});

    const user = submitter?.response?.data?.user;
    if(user) stateLogin(authDispatch, { user: { ...user } });
  }

  return (
    <Form noValidate validated={formValidated} onSubmit={handleSubmit} autoComplete="on">
      {/* PASSWORD */}
      <Form.Group controlId="formPassword" className="mb-3">
        <Form.Label>New Password</Form.Label>
        <Form.Control required 
          autoComplete="new-password"
          type="password" 
          name="password" 
          placeholder="Password" 
          maxLength={255}
          minLength={8}
          onChange={calcPwStrength} />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>

      {/* PASSWORD CONFIRMATION */}
      <Form.Group controlId="formPasswordConfirm" className="mb-3">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control required 
          autoComplete="new-password"
          type="password" 
          name="password_confirmation" 
          placeholder="Password"
          maxLength={255}
          minLength={8}
          onChange={e => setPasswordConfirm(e.target.value)} />
        <Form.Control.Feedback type="invalid">Passwords do not match.</Form.Control.Feedback>
      </Form.Group>

      {/* RESET TOKEN */}
      <Form.Control required disabled 
        type="hidden"
        name="token"
        value={params.token ?? ''} />

      {/* EMAIL */}
      <Form.Control required disabled 
        type="hidden"
        name="email"
        value={params.email ?? ''} />

      {/* PASSWORD STRENGTH */}
      <ProgressBar className="mb-3" now={pwStrength} variant={pwStrengthVariant} />

      {/* PASSWORD STRENGTH ALERT */}
      <Alert show={(pwStrength > 0 && pwStrength < 100) || password !== passwordConfirm} variant="warning" className="my-1 d-flex align-items-center">
        <FontAwesomeIcon icon={['far', 'shield-keyhole']} fixedWidth size="2x" />
        <div className="ml-4">
          <ul className="m-0 list-group">
            {passwordStrengthMessage.map((msg, idx) => (
              <li key={idx} className="list-group-item bg-transparent border-0 p-2">{msg}</li>
            ))}
          </ul>
        </div>
      </Alert>

      <ValidationAlert showValidationAlert={showValidationAlert} validationMessage={validationMessage} />
      
      <SubmitBtn 
        disabled={pwStrength < 100 || password !== passwordConfirm}
        text="Save New Password"
        state={btnState}
        response={response} />
    </Form>
  );
};