import Toast from 'assets/js/classes/Toast'
import { Sentiment } from 'assets/js/classes/BootstrapStyles'
import formatApiError from './formatApiError'

export default function handleApiError(error, { toasts, toastsDispatch, updateToasts }) {
  if(error?.response?.status === 401) return;  // Unauthenticated - don't throw toast because client will redirect to login

  const body = formatApiError(error);

  // THROW TOAST TO USER
  toasts.unshift(new Toast(
    error?.code ?? error?.type, 
    body.join('\n\n'),
    { autohide: false, sentiment: Sentiment.DANGER },
  ));
  updateToasts(toastsDispatch, { toasts });
}