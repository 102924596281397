import { Form, InputGroup, ProgressBar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import ChecklistItem from './ChecklistItem'
import FormButton from 'components/forms/FormButton'
import FormControl from 'components/forms/FormControl'
import { FormControlProvider } from 'contexts/FormControlContext'

function checklistCompletionRate(checklist) {
  const complete = checklist?.items?.reduce((acc, { complete }) => acc + Number(complete), 0) ?? 0;
  const total = checklist?.items?.length ?? 1;
  return 100 * complete / total;
}

export default function Checklist({ checklist }) {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  // CONSTANTS
  const checklistIndex = ModalTaskClass.getChecklistIndex(task, checklist.id);

  return (
    <section className="mb-3">
      <Form 
        autoComplete="off"
        className="my-1"
        onSubmit={e => e.preventDefault()}
      >
        <InputGroup>
          <InputGroup.Text className="bg-white transparentBorder px-0">
            <FontAwesomeIcon icon={['far', 'list-check']} fixedWidth />
          </InputGroup.Text>
          <FormControlProvider>
            <FormControl
              className="transparentBorder rounded pl-1"
              disabledProp={Boolean(Number(task?.archived))}
              modelContext={{ task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
              name={`checklists.${checklistIndex}.title`}
              onBlur={ModalTaskClass.handleInputSubmit}
              onKeyDown={ModalTaskClass.handleKeyDown}
              placeholder="Checklist Name..."
              required
              value={checklist.title}
            />
          </FormControlProvider>
          <FormControlProvider>
            <FormButton
              alert={{
                title: 'Delete this checklist?',
                message: checklist?.title,
                options: {
                  confirmBtnBsStyle: 'danger',
                  type: 'danger',
                }
              }}
              className="transparentBorder transparentBg rounded ml-1"
              disabledProp={task?.archived}
              modelContext={{ task, checklistIndex, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
              hidden={task?.archived}
              onClick={ModalTaskClass.handleDeleteChecklist}
              variant="light"
            ><FontAwesomeIcon icon={['far', 'circle-xmark']} fixedWidth />
            </FormButton>
          </FormControlProvider>
        </InputGroup>
      </Form>

      <ProgressBar
        className="my-1"
        now={checklistCompletionRate(checklist)}
        variant={checklistCompletionRate(checklist) === 100 ? 'success' : 'info'}
      />

      {/* CHECKLIST ITEMS */}
      { checklist?.items?.map(item => <ChecklistItem key={item.id} item={item} checklistIndex={checklistIndex} />) }

      {/* ADD ITEM */}
      <Form onSubmit={e => e.preventDefault()}>
        <FormControlProvider>
          <FormButton
            disabledProp={task?.archived}
            modelContext={{ task, checklistIndex, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
            hidden={task?.archived}
            onClick={ModalTaskClass.handleAddChecklistItem}
            variant="light"
          ><FontAwesomeIcon icon={['far', 'list-ul']} fixedWidth /> Add Item
          </FormButton>
        </FormControlProvider>
      </Form>
      
    </section>
  )
}
