import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function InputGroupLabelled({ as, icon, label, name, required, style, type }) {
  return (
    <>
      <Form.Label htmlFor={label.match(/\w/g)?.join('')}>
        {label} {required 
          ? <OverlayTrigger overlay={<Tooltip>Required</Tooltip>} placement="top">
              <span className="text-danger">*</span>
            </OverlayTrigger>
          : ''}
      </Form.Label>
      <InputGroup className="mb-2" hasValidation>
        <InputGroup.Text className="bg-light">
          <FontAwesomeIcon icon={icon} fixedWidth />
        </InputGroup.Text>
        <Form.Control 
          as={as}
          id={label.match(/\w/g)?.join('')}
          name={name}
          placeholder={label}
          required={required}
          style={style}
          type={type ?? 'text'}
        />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </InputGroup>
    </>
  )
}