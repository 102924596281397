import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { AxiosMethods } from 'assets/js/utilities/axios'
import SubmitBtn from 'components/forms/SubmitBtn'
import ValidationAlert from 'components/ui/ValidationAlert'
import { stateLogin, useAuthDispatch } from 'contexts/AuthContext'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'

export default function LogInForm() {
  // USER AUTH CONTEXT
  const authDispatch = useAuthDispatch();

  // SUBMIT BUTTON CONTEXT
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  // VALIDATION PARAMS
  const [formValidated, setFormValidated] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);

  // FORM PARAMS
  const [rememberCheckbox, setRememberCheckbox] = useState(true);

  const handleSubmit = async e => {
    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.POST, route: ApiRoutes.ACCOUNT_LOGIN });

    await submitter.validate({setFormValidated, setShowValidationAlert, setValidationMessage});

    const user = submitter?.response?.data?.user;
    if(user) stateLogin(authDispatch, { user: { ...user } });
  }

  return (
    <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
      {/* USERNAME */}
      <Form.Group controlId="formUsername" className="mb-3">
        <Form.Label>Username or Email</Form.Label>
        <Form.Control required type="text" name="credential" placeholder="Username or Email" />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>

      {/* PASSWORD */}
      <Form.Group controlId="formPassword" className="mb-3">
        <Form.Label>Password</Form.Label>
        <Form.Control required type="password" name="password" placeholder="Password" />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>

      {/* REMEMBER ME */}
      <Form.Group controlId="formRemember" className="mb-0 ml-3">
        <Form.Switch 
          name="remember" 
          label="Keep me logged in for 14 days" 
          defaultChecked={rememberCheckbox} 
          onChange={e => setRememberCheckbox(e.target.checked)}>
        </Form.Switch>
      </Form.Group>

      <ValidationAlert showValidationAlert={showValidationAlert} validationMessage={validationMessage} />

      <div className="divider row"></div>
      
      <SubmitBtn 
        loadStatus="Logging In ..."
        text="Log In"
        state={btnState}
        response={response} />
    </Form>
  );
};