import { useEffect, useLayoutEffect, useRef, useState } from 'react'

class SidebarSwipe {
  static AVOID_TARGETS = ['svg'];
  static BIND_ELEMENT = 'html';
  static TOUCH_START_THRESHOLD_X = 15;  // % from viewport edges
  static TOUCH_START_THRESHOLD_Y = 60;  // px from top
  static TOUCH_START = 'touchstart';
  static TOUCH_MOVE = 'touchend';
  static TOUCH_END = 'touchmove';
}

export default function useScrollEventsListeners({ stateToggleSidebar, sidebarDispatch, sidebarState }) {
  const touchStart = useRef(false);

  useEffect(() => {
    const scrollEventsListeners = {
      [SidebarSwipe.TOUCH_START]: e => {
        const x = e.touches[0].clientX;
        const y = e.touches[0].clientY;
        
        if(x < window.innerWidth / SidebarSwipe.TOUCH_START_THRESHOLD_X && y > SidebarSwipe.TOUCH_START_THRESHOLD_Y) {
          e.preventDefault();  // Prevents navigation action on iOS
          touchStart.current = true;
        }
      },
      [SidebarSwipe.TOUCH_MOVE]: (e) => {
        if(SidebarSwipe.AVOID_TARGETS.includes(e.target.tagName) || e.target.closest('[draggable]')) return;

        if(touchStart.current && !sidebarState.open) {
          stateToggleSidebar(sidebarDispatch, { open: true });
          touchStart.current = false;
        }
      },
      [SidebarSwipe.TOUCH_END]: (e) => {
        touchStart.current = false;
      },
    };

    document.querySelector(SidebarSwipe.BIND_ELEMENT).addEventListener(SidebarSwipe.TOUCH_START, scrollEventsListeners.touchstart, { passive: false });
    document.querySelector(SidebarSwipe.BIND_ELEMENT).addEventListener(SidebarSwipe.TOUCH_MOVE, scrollEventsListeners.touchmove, { passive: false });
    document.querySelector(SidebarSwipe.BIND_ELEMENT).addEventListener(SidebarSwipe.TOUCH_END, scrollEventsListeners.touchend, { passive: false });

    return () => {
      Object.entries(scrollEventsListeners).map(
        ([k,v]) => document.querySelector(SidebarSwipe.BIND_ELEMENT).removeEventListener(k, v)
      );
    }
  }, [sidebarState.open]);
}