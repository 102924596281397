import { createSearchParams, Navigate, useLocation } from 'react-router-dom'
import FrontendRoutes from 'assets/js/classes/FrontendRoutes'

export default function useNavigateUnauthenticated(user) {
  const { pathname, search } = useLocation();
  const searchParams = createSearchParams(search ?? '');
  searchParams.delete('r');
  const redirect = `?r=${encodeURIComponent(pathname)}&${searchParams.toString()}`;
  
  if(!user) return <Navigate to={[
      FrontendRoutes.ACCOUNT_LOGIN,
      pathname !== '/' || searchParams.toString() !== '' ? redirect : ''
    ].join('')} replace="false" />;
}