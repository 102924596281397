import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import { Priority as Prio } from 'assets/js/classes/Task'
import FormSelect from 'components/forms/FormSelect'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import { FormControlProvider } from 'contexts/FormControlContext'

export default function Priority() {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();
  
  return (
    <section className="mb-3">
      <h6 className="mb-1">
        <FontAwesomeIcon icon={['far', 'exclamation']} fixedWidth /> PRIORITY
      </h6>
      
      <Form 
        autoComplete="off"
        noValidate 
        onSubmit={undefined}
      >
        <FormControlProvider>
          <FormSelect
            disabledProp={!Boolean(Object.values(task).length) || Boolean(Number(task?.archived))}
            modelContext={{ task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
            name="priority"
            onChange={ModalTaskClass.handleInputSubmit}
            value={task?.priority}
          >
            <option value={Prio.LOW}>{Prio.LOW}</option>
            <option value={Prio.MEDIUM}>{Prio.MEDIUM}</option>
            <option value={Prio.HIGH}>{Prio.HIGH}</option>
          </FormSelect>
        </FormControlProvider>
      </Form>
    </section>
  )
}