import { useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApiChannels from 'assets/js/classes/ApiChannels'
import ApiEvents from 'assets/js/classes/ApiEvents'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import handleApiCall from 'assets/js/utilities/handleApiCall'
import handleToast from 'assets/js/utilities/handleToast'
import { useTenantState } from 'contexts/TenantContext'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import { updateUserEdit, useUserEditDispatch, useUserEditState } from 'contexts/UserEditContext'
import useApiCall from 'hooks/useApiCall'
import useWebSocket from 'hooks/useWebSocket'

export default function SelectUserForm() {
  // STATE PARAMS
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState('');

  // CONTEXT PARAMS - USER EDIT
  const { user } = useUserEditState();
  const userEditDispatch = useUserEditDispatch();

  // CONTEXT PARAMS - TENANT
  const { tenant } = useTenantState();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  const handleChange = e => {
    updateUserEdit(userEditDispatch, { user: users?.find(user => Number(user.id) === Number(e.target.value)) });
  }

  const getUsersOptions = {
    apiMethod: AxiosMethods.GET, 
    apiRoute: ApiRoutes.USERS, 
    callback: ({ users }) => setUsers(users ?? []), 
  }

  useApiCall(getUsersOptions);

  useWebSocket(ApiChannels.PRIVATE_TENANT(tenant?.id), [
    {
      events: [ApiEvents.USER_CREATED, ApiEvents.USER_DELETED, ApiEvents.USER_UPDATED],
      callback: () => handleApiCall({ ...getUsersOptions, toasts, toastsDispatch }),
    },
    {
      events: [ApiEvents.USER_CREATED],
      callback: ({ author, user }) => handleToast({
          title: 'User Created',
          message: `${user?.name} (${user?.email}) was created by ${author?.name} (${author?.email}).`,
          autohide: true
        }, { toasts, toastsDispatch, updateToasts }),
    },
    {
      events: [ApiEvents.USER_DELETED],
      callback: ({ author, user }) => handleToast({
          title: 'User Deleted',
          message: `${user?.name} (${user?.email}) was deleted by ${author?.name} (${author?.email}).`,
          autohide: true
        }, { toasts, toastsDispatch, updateToasts }),
    },
    {
      events: [ApiEvents.USER_UPDATED],
      callback: ({ author, user }) => handleToast({
          title: 'User Updated',
          message: `${user?.name} (${user?.email}) was updated by ${author?.name} (${author?.email}).`,
          autohide: true
        }, { toasts, toastsDispatch, updateToasts }),
    },
  ]);

  useEffect(() => {  // This clears the select dropdown after the form is submitted (useEdit context updates)
    setSelected(user?.id ?? '');
  }, [user]);

  useEffect(() => {
    updateUserEdit(userEditDispatch, { user: users?.find(user => Number(user.id) === Number(selected)) })
  }, [users]);

  return (
    <Form onSubmit={e => e.preventDefault()}>
      <InputGroup className="mb-2 shadow-sm">
        <InputGroup.Text>
          <FontAwesomeIcon icon={['far', 'users']} fixedWidth />
        </InputGroup.Text>
        <Form.Select 
          aria-label="Select user" 
          disabled={users?.length === 0} 
          value={selected}  // Must be controlled otherwise select will default to an available option
          onChange={handleChange} 
        >
          <option disabled value="">Select user</option>
          {users?.map(user => <option key={user.id} value={user.id}>{`${user.name} (${user.username})`}</option>)}
        </Form.Select>
      </InputGroup>
    </Form>
  )
}