import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

export default function SidebarItem({ icon, link, title }) {
  return (
    <li>
      <NavLink end to={link} className="h-auto text-wrap pr-1">
        {icon ? <FontAwesomeIcon icon={['fad', icon]} fixedWidth className="metismenu-icon" /> : null}
        <span>{title}</span>
      </NavLink>
    </li>
  )
}