import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faRectangleXmark, faSearch } from '@fortawesome/pro-regular-svg-icons';
// import { faRectangleXmark, faSearch } from "assets/fontawesome-6/svgs/regular";
import useDebounce from 'hooks/useDebounce';

export default function SearchBar({ dataset, stateUpdate, stateKey, stateType, dispatch, filterCallback }) {
  const [searchQuery, setSearchQuery] = useState('');

  useDebounce(() => {
    const filteredDataset = filterCallback(dataset, searchQuery);
    stateUpdate(dispatch, { [stateKey]: stateType === Array ? filteredDataset : filteredDataset[0] });  // Don't use Array.shift()
  }, 100, [searchQuery]);  // Short debounce timeout because search is client-side

  const handleOnChange = e => setSearchQuery(e.target.value);

  return (
    <Form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
      <InputGroup className="shadow-sm rounded mb-2">
        <Form.Control onChange={handleOnChange} type="text" value={searchQuery} name="search" className="transparentBorder" placeholder="Search..." />
        <Button variant={searchQuery.length > 0 ? 'warning' : 'primary'} onClick={() => setSearchQuery('')}>
          <FontAwesomeIcon icon={['far', searchQuery.length > 0 ? 'rectangle-xmark' : 'magnifying-glass']} color="white" fixedWidth />
        </Button>
      </InputGroup>
    </Form>
  )
}