import { stateToggleSidebar, useSidebarDispatch } from 'contexts/SidebarContext'

export default function SidebarOverlay() {
  const sidebarDispatch = useSidebarDispatch();
  
  const handleSidebarOverlayClick = () => stateToggleSidebar(sidebarDispatch, { open: false });
  
  return (
    <div className="app-sidebar-mobile-overlay" 
      onClick={handleSidebarOverlayClick} 
      onTouchStart={handleSidebarOverlayClick}>
    </div>
  )
}