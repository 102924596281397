import { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStateCallback from 'hooks/useStateCallback'
import AssigneeInitials from 'pages/dashboards/components/AssigneeInitials'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import { stateUpdateFormControl, useFormControlDispatch, useFormControlState } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import { useToastsDispatch, useToastsState } from 'contexts/ToastsContext'

export default function Notes() {
  // CONTEXT PARAMS - FORM CONTROL
  const { disabled } = useFormControlState();
  const formControlDispatch = useFormControlDispatch();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  // STATE PARAMS
  const [notes, setNotes] = useStateCallback('');

  // REFERENCES
  const notesRef = useRef(null);

  useEffect(() => {
    setNotes(task?.notes ?? '');
  }, [task.notes]);

  const handleKeyDown = e => {
    ModalTaskClass.handleEscapeKey(e, task?.notes, setNotes);
  }
  
  const handleSubmit = e => {
    ModalTaskClass.handleSubmit(e, notesRef, { task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }, { update: stateUpdateFormControl, dispatch: formControlDispatch }, { toasts, toastsDispatch });
    // ModalTaskClass.handleInputSubmit(e, { task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }, { stateUpdateFormControl, formControlDispatch }, { toasts, toastsDispatch });
  }

  return (
    <section className="mb-3">
      <h6 className="mb-1">
        <FontAwesomeIcon icon={['far', 'notes']} fixedWidth /> NOTES
      </h6>

      { task?.notes === undefined
        ? <Skeleton count={9} />
        : <Form 
            autoComplete="off"
            className="my-1"
            noValidate 
            onSubmit={handleSubmit}
          >
            <Form.Control 
              as="textarea"
              className="transparentBorder"
              disabled={Boolean(Number(task?.archived)) || disabled}
              name="notes"
              onBlur={handleSubmit}
              onChange={e => setNotes(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Notes..."
              ref={notesRef}
              style={{ height: '12rem' }}
              value={notes}
            />
          </Form>
      }
    </section>
  )
}