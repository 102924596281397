import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'assets/fontawesome/js/all.js'
import 'assets/fontawesome/scss/fontawesome.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
