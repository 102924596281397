export default class DateTime {
  static #getDatetimeObj(datetime) {
    return {
      year: new Date(datetime).getUTCFullYear(),
      month: new Date(datetime).getUTCMonth(),
      day: new Date(datetime).getUTCDate(),
    }
  }

  static isPast(datetime) {
    const now = DateTime.#getDatetimeObj(new Date());
    const dt = DateTime.#getDatetimeObj(datetime);

    if(dt.year < now.year) return true;
    
    if(
      dt.year === now.year &&
      dt.month < now.month
    ) return true;

    if(
      dt.year === now.year &&
      dt.month === now.month &&
      dt.day < now.day
    ) return true;

    return false;
  }

  static isPresent(datetime) {
    const now = DateTime.#getDatetimeObj(new Date());
    const dt = DateTime.#getDatetimeObj(datetime);

    if(
      dt.year === now.year &&
      dt.month === now.month &&
      dt.day === now.day
    ) return true;

    return false;
  }

  static isFuture(datetime) {
    const now = DateTime.#getDatetimeObj(new Date());
    const dt = DateTime.#getDatetimeObj(datetime);

    if(dt.year > now.year) return true;
    
    if(
      dt.year === now.year &&
      dt.month > now.month
    ) return true;

    if(
      dt.year === now.year &&
      dt.month === now.month &&
      dt.day > now.day
    ) return true;

    return false;
  }
}