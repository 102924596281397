import { useEffect } from 'react'
import StripePaymentIntent from 'assets/js/classes/StripePaymentIntent'
import { useToastsDispatch, useToastsState } from 'contexts/ToastsContext'

export default function useUpdatePaymentIntent({ amount, paymentIntentId }) {
  // CONTEXT PARAMS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();
  
  useEffect(() => {
    (async () => {
      
      if(paymentIntentId) {
        console.log('UPDATING PAYMENT INTENT', { paymentIntentId, amount });

        const response = await StripePaymentIntent.update(paymentIntentId, { amount }, { toasts, toastsDispatch });

        if(response?.data?.payment_intent?.status === 'requires_payment_method') {
          // const { error } = await elements.fetchUpdates();
          // console.error(error);
        }
      }
    })();
  }, [amount]);
}