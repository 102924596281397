import Main from 'layouts/Main'
import { Card } from 'react-bootstrap'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'
import CreateUserForm from './CreateUserForm'

export default function CreateUser() {
  return (
    <Main title="Create New" subtitle="User" icon="user-plus">
      <Card>
        <Card.Body>
          <SubmitBtnProvider>
            <CreateUserForm />
          </SubmitBtnProvider>
        </Card.Body>
      </Card>
    </Main>
  )
}