import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Main from 'layouts/Main'
import { Button, Card, Col, FloatingLabel, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import projectsArchived from 'data/projectsArchived'
import { useNavigate } from 'react-router-dom'
import useDebounce from 'hooks/useDebounce'
import { escapeInput } from 'assets/js/utilities/regex';

// ID BUCKET TITLE CLIENT DEADLINE
const columns = [
  {
    name: 'ID',
    selector: row => row.id,
    sortable: true,
  },
  {
    name: 'Group',
    selector: row => row.bucket?.title,
    sortable: true,
  },
  {
    name: 'Title',
    selector: row => row.title,
    sortable: true,
  },
  {
    name: 'Client',
    selector: row => row.client?.name,
    sortable: true,
  },
  {
    name: 'Deadline',
    selector: row => row.deadline,
    sortable: true,
    format: row => moment(row.deadline).format('DD/MM/YYYY'),
  },
];

export default function Archive() {
  const navigate = useNavigate();

  // STATE PARAMS
  const [tableData, setTableData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  
  useEffect(() => {
    setTableData(projectsArchived);
  }, []);

  useDebounce(() => {
    if(searchQuery === '') {
      setTableData(projectsArchived);
      return;
    }

    const filteredDataset = projectsArchived?.filter(item => {
      const propsToSearch = [item.title, item.bucket?.title, item.client?.name, item.client?.email, item.client?.phone, item.notes];

      return propsToSearch.some(val => val && val.toLowerCase().includes(escapeInput(searchQuery).toLowerCase()));
    });
    setTableData(filteredDataset);

  }, 100, [searchQuery]);

  return (
    <Main title="Archive" subtitle="Projects" icon={'box-archive'}>
      
      <Row>
        <Col>
          <Card className="mb-2">
            <Card.Body className="px-0">
              <Row className="flex-row-reverse px-3">
                <Col xs={12} sm={8} md={6} lg={3}>
                  <Form noValidate autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <InputGroup>
                      <FloatingLabel label="Search">
                        <Form.Control 
                          type="text" 
                          placeholder="Search..." 
                          value={searchQuery} 
                          onChange={e => setSearchQuery(e.target.value.trim())} 
                          disabled={!tableData} />
                      </FloatingLabel>

                      <Button variant={searchQuery.length > 0 ? 'warning' : 'light'} onClick={() => setSearchQuery('')} disabled={!tableData}>
                        <FontAwesomeIcon icon={['far', searchQuery.length > 0 ? 'rectangle-xmark' : 'magnifying-glass']} fixedWidth />
                      </Button>
                    </InputGroup>
                  </Form>
                </Col>
              </Row>
              

              <DataTable
                columns={columns}
                data={tableData ?? []}
                progressPending={!tableData}
                progressComponent={<Spinner animation="border" size="lg" />}
                highlightOnHover
                pointerOnHover
                onRowClicked={row => navigate(`task/${row.id}`)}
                pagination
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Main>
  )
}