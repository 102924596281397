import { useEffect } from 'react'
import StripePaymentIntent from 'assets/js/classes/StripePaymentIntent'
import { useToastsDispatch, useToastsState } from 'contexts/ToastsContext'

export default function useCreatePaymentIntent({ amount, description, setClientSecret, setPaymentIntentId }) {
  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  useEffect(() => {
    let payment_intent_id = null;  // Store payment intent ID for cleanup function

    (async () => {  // CREATE PAYMENT INTENT
      console.log('CREATING PAYMENT INTENT');

      const response = await StripePaymentIntent.create(amount, description, { toasts, toastsDispatch });
      if(response?.data?.payment_intent) {
        payment_intent_id = response?.data?.payment_intent?.id;
        setClientSecret(response?.data?.payment_intent?.client_secret);
        setPaymentIntentId(response?.data?.payment_intent?.id);
        console.log({ client_secret: response?.data?.payment_intent?.client_secret, payment_intent: response?.data?.payment_intent?.id });
      }
    })();

    return () => {  // CLEAN UP STRIPE PAYMENT INTENT IF USER NAVIGATES AWAY
      console.log({ payment_intent_id });

      (async () => {
        if(payment_intent_id) {
          console.log('CANCELING PAYMENT');
          console.log({ payment_intent_id });

          await StripePaymentIntent.cancel(payment_intent_id, { toasts, toastsDispatch });
        }
      })();
    }
    // eslint-disable-next-line
  }, []);
}