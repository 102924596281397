import SB from 'assets/js/classes/SubmitBtn'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import handleApiCall from 'assets/js/utilities/handleApiCall'

export default class Dashboard {
  static async handleNewBucket(e, modelContext, formControlContext, toastsContext) {
    // DISABLE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.LOADING });

    // API CALL
    const response = await handleApiCall({
      apiMethod: AxiosMethods.POST, 
      apiRoute: ApiRoutes.BUCKETS,
      callback: ({ bucket }) => {
        [modelContext.dashboard, modelContext.searchbar].forEach(({ dataset, dispatch, prop, update }) => {
          dataset.splice(0, 0, bucket);
          update(dispatch, { [prop]: dataset });
        });

        // FIRE SYNTHETIC EVENT
        e.target.dispatchEvent(new Event(`${AxiosMethods.POST}::${ApiRoutes.BUCKETS}`));
      },
      formData: { title: "New List/Dashboard" },
      ...toastsContext
    });

    // DISPLAY RESPONSE
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.RESPONSE, response });

    // RESTORE INPUT
    await formControlContext.update(formControlContext.dispatch, { btnState: SB.DEFAULT });
  }
}