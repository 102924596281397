import { useState } from 'react'
import { Form } from 'react-bootstrap'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { AxiosMethods } from 'assets/js/utilities/axios'
import handleToast from 'assets/js/utilities/handleToast'
import ValidationAlert from 'components/ui/ValidationAlert'
import InputGroupFloating from 'components/forms/InputGroupFloating'
import SubmitBtn from 'components/forms/SubmitBtn'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'

export default function CreateUserForm() {
  // CONTEXT PARAMS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();
  
  // SUBMIT BUTTON CONTEXT
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  // VALIDATION PARAMS
  const [formValidated, setFormValidated] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState({});

  // FORM PARAMS
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [invalidFeedback, setInvalidFeedback] = useState({});

  const handleSubmit = async e => {
    // RESET INVALID FEEDBACK
    setInvalidFeedback({});

    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.POST, route: ApiRoutes.USERS });

    await submitter.validate({setFormValidated, setShowValidationAlert, setValidationMessage, setInvalidFeedback});

    if(submitter?.success) {
      const user = submitter?.response?.data?.user;
      
      // THROW TOAST
      handleToast({
        title: 'Changes Saved',
        message: `Created a user account for ${user?.name} with email ${user?.email} and username ${user?.username}`,
      }, { toasts, toastsDispatch, updateToasts });

      // RESET FORM
      [setName, setEmail].map(fn => fn(''));

      document.activeElement.blur()
    }
  }

  return (
    <Form 
      autoComplete="off" 
      noValidate 
      onSubmit={handleSubmit}
      validated={Object.keys(invalidFeedback).length === 0 && formValidated} 
    >
      <InputGroupFloating 
        className="mb-4" 
        icon={['far', 'user']} 
        invalidFeedback={invalidFeedback}
        label="Name" 
        name="name" 
        onChange={e => setName(e.target.value)} 
        required 
        type="text" 
        value={name} 
      />
      
      <InputGroupFloating 
        className="mb-4" 
        icon={['far', 'at']} 
        invalidFeedback={invalidFeedback}
        label="Email Address" 
        name="email" 
        onChange={e => setEmail(e.target.value.trim())}
        required 
        type="email" 
        value={email} 
      />
      
      <InputGroupFloating 
        className="mb-4" 
        disabled 
        icon={['far', 'id-badge']} 
        invalidFeedback={invalidFeedback}
        label="Username" 
        name="username" 
        type="text" 
        value={name.match(/\w+\s?/gi)?.join('').toLowerCase().replaceAll(' ', '.') ?? ''} 
      />

      <ValidationAlert 
        showValidationAlert={showValidationAlert} 
        validationMessage={validationMessage} />

      <SubmitBtn 
        icon={['fas', 'floppy-disk']} 
        text="Save"
        state={btnState}
        response={response} />
    </Form>
  )
}