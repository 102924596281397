import React from 'react';

const TaskModalStateContext = React.createContext();
const TaskModalDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useTaskModalState() {
  const context = React.useContext(TaskModalStateContext);
  if(!context) throw new Error('useTaskModalState must be used in TaskModalProvider');
  return context;
}

function useTaskModalDispatch() {
  const context = React.useContext(TaskModalDispatchContext);
  if(!context) throw new Error('useTaskModalDispatch must be used in TaskModalProvider');
  return context;
}

function TaskModalProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { task: {}, showClientEmailModal: false, showClientPhoneModal: false, showQrCodeModal: false });

  return (
    <TaskModalStateContext.Provider value={state}>
      <TaskModalDispatchContext.Provider value={dispatch}>
        {props.children}
      </TaskModalDispatchContext.Provider>
    </TaskModalStateContext.Provider>
  )
}

function stateUpdateTaskModal(dispatch, { task }) {
  dispatch({ task });
}

function stateUpdateClientEmailModal(dispatch, { showClientEmailModal }) {
  dispatch({ showClientEmailModal });
}

function stateUpdateClientPhoneModal(dispatch, { showClientPhoneModal }) {
  dispatch({ showClientPhoneModal });
}

function stateUpdateQrCodeModal(dispatch, { showQrCodeModal }) {
  dispatch({ showQrCodeModal });
}

export { TaskModalProvider, stateUpdateTaskModal, stateUpdateClientEmailModal, stateUpdateClientPhoneModal, stateUpdateQrCodeModal, useTaskModalDispatch, useTaskModalState };