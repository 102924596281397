import { Link } from 'react-router-dom'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'
import ResetPasswordForm from './ResetPasswordForm'
import FrontendRoutes from 'assets/js/classes/FrontendRoutes'

export default function ResetPassword() {  
  return (
    <>
      <h4 className="mb-0 d-flex flex-column">
        Reset your password
        <span>Check your email for instructions to reset your password.</span>
      </h4>

      <div className="divider row"></div>

      <SubmitBtnProvider>
        <ResetPasswordForm />
      </SubmitBtnProvider>

      <div className="mt-2 d-flex flex-row-reverse">
        <Link to={FrontendRoutes.ACCOUNT_LOGIN}>Go Back</Link>
      </div>
    </>
  );
};