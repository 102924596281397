import { useEffect } from 'react'

export default function useRegisterEventListeners(ref, eventListeners) {
  useEffect(() => {
    eventListeners?.forEach(({ event, listener }) => ref.current?.addEventListener(event, listener));

    return () => eventListeners?.forEach(({ event, listener }) => ref.current?.removeEventListener(event, listener));
    
    // eslint-disable-next-line
  }, []);
}