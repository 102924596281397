import React from 'react';
import { Card, Col } from 'react-bootstrap';
import StaticBoardTask from './Task';
import DateTime from 'assets/js/classes/DateTime';

export default function StaticBoardColumn(props) {
  return (
    <Col xs={12} md={6} lg={5} xl={4}>
      <Card className={`bucketCard border-0 pt-1 my-2 align-self-start shadow-sm`}>
        <span className="d-flex align-items-stretch">
          <div className="fw-bold p-2 ml-1 flex-fill">{props.header}</div>
        </span>

        <div className="bucketDropZone p-2 overflow-hidden">
          {props.bucket.tasks.map((task, index) => {
            const evalDate = [
              DateTime.isPast(task.deadline),
              DateTime.isPresent(task.deadline),
              DateTime.isFuture(task.deadline),
            ]

            return (
              evalDate[props.order] && 
              <StaticBoardTask key={task.id} task={task} index={index} />
            )
          })}
        </div>
      </Card>
    </Col>
  )
}