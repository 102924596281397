import handleApiCall from 'assets/js/utilities/handleApiCall'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'

export default class StripePaymentIntent {
  static cancel = async (payment_intent_id, { toasts, toastsDispatch }) => {
    const response = await handleApiCall({
      apiMethod: AxiosMethods.DELETE, 
      apiRoute: `${ApiRoutes.CASHIER_PAYMENT_INTENT}/${payment_intent_id}`, 
      formData: {},  // No form data for delete method
      toasts,
      toastsDispatch,
    });

    return response;
  }

  static create = async (amount, description, { toasts, toastsDispatch }) => {
    const response = await handleApiCall({
      apiMethod: AxiosMethods.POST, 
      apiRoute: ApiRoutes.CASHIER_PAYMENT_INTENT, 
      formData: { amount, description, },
      toasts,
      toastsDispatch,
    });

    return response;
  }

  static update = async (payment_intent_id, { amount }, { toasts, toastsDispatch }) => {
    const response = await handleApiCall({
      apiMethod: AxiosMethods.PATCH, 
      apiRoute: `${ApiRoutes.CASHIER_PAYMENT_INTENT}/${payment_intent_id}`, 
      formData: { amount },
      toasts,
      toastsDispatch,
    });

    return response;

    // return new Promise(resolve => resolve({ id: payment_intent, ...params }));
  }
}