import { Button, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import SB from 'assets/js/classes/SubmitBtn'
import { Sentiment } from 'assets/js/classes/BootstrapStyles'

export default function SubmitButton({ className, disabled, grid, icon, loadStatus, response, size, state, text, variant }) {
  const [buttonDisabled, setButtonDisabled] = useState(disabled ?? false);
  const [buttonState, setButtonState] = useState(state ?? SB.DEFAULT);
  const [buttonVariant, setButtonVariant] = useState(variant ?? Sentiment.PRIMARY);

  useEffect(() => {
    switch(state) {

      case SB.LOADING:
        setButtonState(state);
        setButtonDisabled(true);
        setButtonVariant(variant ?? Sentiment.PRIMARY);
      break;

      case SB.RESPONSE:
        setButtonState(state);
        setButtonDisabled(true);
        setButtonVariant(response?.status >= 200 && response?.status < 300 ? Sentiment.SUCCESS : Sentiment.DANGER);
      break;

      case SB.RATE_LIMIT:
        setButtonState(state);
        setButtonDisabled(true);
        setButtonVariant(variant ?? Sentiment.PRIMARY);
      break;

      case SB.DEFAULT:
      default:
        setButtonState(SB.DEFAULT);
        setButtonDisabled(disabled ?? false);
        setButtonVariant(variant ?? Sentiment.PRIMARY);
        
    }
  }, [disabled, response, state]);

  return (
    <div className={(grid === undefined || grid) ? 'd-grid' : ''}>
      <Button disabled={buttonDisabled} type="submit" variant={buttonVariant} size={size ?? 'lg'} className={className}>
        <>
          {buttonState === SB.DEFAULT && 
            <>
              {icon 
                ? <><FontAwesomeIcon icon={icon} fixedWidth className={text === undefined ? '' : "mr-1"} />{text}</>
                : text
              }
            </>
          }

          {buttonState === SB.LOADING &&
            <><Spinner animation="border" size="sm" className={text === undefined ? '' : "mr-1"} />{text === undefined ? '' : loadStatus ?? 'Saving ...'}</>
          }

          {buttonState === SB.RESPONSE &&
            <>
              <FontAwesomeIcon 
                icon={['fas', response?.status >= 200 && response?.status < 300 ? 'circle-check' : 'circle-xmark']} 
                fixedWidth className={text === undefined ? '' : "mr-1"}
              />{text === undefined ? '' : response?.status >= 200 && response?.status < 300 ? 'Success!' : 'Failed!'}
            </>
          }

          {buttonState === SB.RATE_LIMIT &&
            <><Spinner animation="border" size="sm" className={text === undefined ? '' : "mr-1"} />{text === undefined ? '' : loadStatus ?? 'Saving ...'}</>
          }

        </>
      </Button>
    </div>
  );
}