import { forwardRef, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { Form } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import MemberButton from './MemberButton'
import useApiCall from 'hooks/useApiCall'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'

// ACCORDION PARAMS
const accordionEventKey = 0;
const AccordionButton = forwardRef((props, ref) => {
  const decoratedOnClick = useAccordionButton(accordionEventKey);
  
  return (
    <Button {...props} ref={ref} onClick={decoratedOnClick} />
  )
});

export default function Members() {
  // STATE PARAMS
  const [users, setUsers] = useState([]);

  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  useApiCall({
    apiMethod: AxiosMethods.GET, 
    apiRoute: ApiRoutes.USERS, 
    callback: ({ users }) => setUsers(users ?? []), 
  });

  return (
    <section className="mb-1">
      <Accordion>
        <AccordionButton
          className="border-0 w-100 text-left"
          disabled={!Boolean(Object.values(task).length) || Boolean(Number(task?.archived))}
          variant="light"
        >
          <FontAwesomeIcon icon={['far', 'circle-user']} fixedWidth className="mr-1" />
          Members
        </AccordionButton>

        <Accordion.Collapse
          className="d-flex justify-content-center"
          eventKey={accordionEventKey}
        >
          <div 
            className="mt-1 w-100 border rounded p-1"
            id="members"
          > {/* Accordion Collapse requires one child only. */}
            { users?.map(user => <MemberButton key={user.id} user={user} />) }
            { users?.length === 0 &&
              <Button 
                className="w-100"
                disabled
                variant="light"
              >No users</Button>
            }
          </div>
        </Accordion.Collapse>
      </Accordion>
    </section>
  )
}