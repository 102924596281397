import { useState } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { AxiosMethods } from 'assets/js/utilities/axios'
import SB from 'assets/js/classes/SubmitBtn'
import SubmitBtn from 'components/forms/SubmitBtn'
import ValidationAlert from 'components/ui/ValidationAlert'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'

export default function ForgotPasswordForm() {
  // SUBMIT BUTTON CONTEXT
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  // VALIDATION PARAMS
  const [formValidated, setFormValidated] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState(null);
  
  // CONFIRMATION PARAMS
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(null);

  const handleSubmit = async e => {
    setShowConfirmationAlert(false);

    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.POST, route: ApiRoutes.ACCOUNT_PASSWORD_FORGOT });

    await submitter.validate({setFormValidated, setShowValidationAlert, setValidationMessage});

    if(submitter.response?.status === 200) {
      setShowConfirmationAlert(true);
      setConfirmationMessage(submitter.response?.data?.message);
      // stateUpdateSubmitBtn(submitBtnDispatch, { btnState: SB.RATE_LIMIT, rateLimitSeconds: submitter.response?.data?.rate_limit_seconds });
    }
  }

  return (
    <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
      {/* EMAIL */}
      <Form.Group controlId="formEmail" className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control required type="email" name="email" placeholder="Email" />
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </Form.Group>

      <ValidationAlert showValidationAlert={showValidationAlert} validationMessage={validationMessage} />

      {/* CONFIRMATION ALERT */}
      <Alert show={showConfirmationAlert} variant="info" className="mb-3 d-flex align-items-center">
        <FontAwesomeIcon icon={['far', 'paper-plane']} fixedWidth size="2x" />
        <div className="ml-4">
          <p className="m-0" style={{ whiteSpace: 'break-spaces' }}>{confirmationMessage}</p>
        </div>
      </Alert>
      
      <SubmitBtn 
        text="Send Reset Link"
        state={btnState}
        response={response} />
    </Form>
  )
}