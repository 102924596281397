export default class PasswordStrength {
  #pw;
  #pwConfirm;
  minLength = 8;

  constructor(password, passwordConfirm) {
    this.strength   = 100;
    this.pwMsg      = [];
    this.#pw        = password;
    this.#pwConfirm = passwordConfirm;
    this.weaknesses = this.#calculateWeaknesses();

    this.#calculatePasswordStrength();
  }

  #calculatePasswordStrength() {
    this.weaknesses.forEach(weakness => {
      if(weakness == null) return;
      this.strength -= weakness.deduction;
      this.pwMsg.push(weakness.message);
    });

    if(this.strength < 10) this.strength = 10;
  }

  #calculateWeaknesses() {
    const weaknesses = [];
    weaknesses.push( this.#lengtWeakness(this.#pw) );
    weaknesses.push( this.#lowercaseWeakness(this.#pw) );
    weaknesses.push( this.#uppercaseWeakness(this.#pw) );
    weaknesses.push( this.#numberWeakness(this.#pw) );
    // weaknesses.push( this.#specialCharactersWeakness(this.#pw) );
    weaknesses.push( this.#repeatCharactersWeakness(this.#pw) );
    weaknesses.push( this.#passwordConfirm(this.#pw, this.#pwConfirm) );
    return weaknesses;
  }

  #lengtWeakness(pw) {
    if(pw.length < this.minLength) {
      return {
        message: 'Your password is too short.',
        deduction: 40,
      };
    } else {
      return null;
    }
  }

  #lowercaseWeakness(pw) {
    return this.#characterTypeWeakness(pw, /[a-z]/g, 'lowercase characters') || null;
  }

  #uppercaseWeakness(pw) {
    return this.#characterTypeWeakness(pw, /[A-Z]/g, 'uppercase characters') || null;
  }

  #numberWeakness(pw) {
    return this.#characterTypeWeakness(pw, /[0-9]/g, 'numbers') || null;
  }

  #specialCharactersWeakness(pw) {
    return this.#characterTypeWeakness(pw, /[^0-9a-zA-Z\s]/g, 'special characters (e.g., !@#$%^&*)') || null;
  }

  #passwordConfirm(pw, pwConfirm) {
    return pw === pwConfirm ? null : {
      message: 'Your passwords do not match.',
      deduction: 0,
    };
  }

  #repeatCharactersWeakness(pw) {
    const matches = pw.match(/(.)\1{2,}/g) || [];
    if(matches.length > 0) {
      return {
        message: 'Your password has repeat characters.',
        deduction: matches.length * 10,
      };
    } else {
      return null;
    }
  }

  #characterTypeWeakness(pw, regex, type) {
    const matches = pw.match(regex) || [];
    if(matches.length === 0) {
      return {
        message: `Your password has no ${type}.`,
        deduction: 26,
      };
    }
  }
}