import { useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { getCountryCallingCode } from 'react-phone-number-input'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import { stateUpdateFormControl, useFormControlDispatch, useFormControlState } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import { useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import useStateCallback from 'hooks/useStateCallback'
import InputPhone from 'components/forms/InputPhone'

export default function ClientPhone({  }) {
  // CONTEXT PARAMS - FORM CONTROL
  const { disabled } = useFormControlState();
  const formControlDispatch = useFormControlDispatch();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  // STATE PARAMS
  const [phone, setPhone] = useStateCallback(`+${getCountryCallingCode('IE')}`);

  // REFERENCES
  const phoneRef = useRef(null);

  useEffect(() => {
    task?.client?.phone && setPhone(`+${task.client.phone}`);
  }, [task?.client?.phone]);

  const handleKeyDown = e => {
    ModalTaskClass.handleEscapeKey(e, task?.client?.phone, (oldVal, callback) => setPhone(`+${task.client.phone}`, callback));
  }

  const handleSubmit = e => {
    ModalTaskClass.handleInputSubmit(e, phoneRef, task?.client?.phone, (oldVal, callback) => setPhone(`+${task.client.phone}`, callback), { task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }, { update: stateUpdateFormControl, dispatch: formControlDispatch }, { toasts, toastsDispatch });
  }

  return (
    <>
      { task?.client
        ? <Form 
            autoComplete="off"
            className="my-1"
            noValidate 
            onSubmit={handleSubmit}
          >
            <InputPhone 
              disabled={Boolean(Number(task?.archived)) || disabled}
              label="Client Phone..."
              name="client.phone"
              onBlur={handleSubmit}
              onKeyDown={handleKeyDown}
              phone={phone}
              setPhone={setPhone}
              validated={true}
            >
              <input 
                type="hidden" 
                ref={phoneRef} 
                readOnly 
                value={phone.match(/\d/g)?.join('') ?? ''} 
                name="client.phone"
              />
            </InputPhone>
          </Form>
        : <Skeleton height={38} />
      }
    </>
  )
}