import capitalizeFirstLetter from "./capitalizeFirstLetter"

export default function titleFromPath(path) {
  let title = '';
  
  path.forEach(phrase => {
    phrase.split('-').forEach(word => {
      title += capitalizeFirstLetter(word) + ' ';
    });

    if(phrase !== '' && path.indexOf(phrase) !== path.length - 1) title += '— ';
  })

  return title;
}