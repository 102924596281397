import InputGroupLabelled from 'components/forms/InputGroupLabelled'
import InputPhoneLabelled from 'components/forms/InputPhoneLabelled'

export default function ClientDetails({ validated }) {
  return (
    <section className="mb-3">

      <InputGroupLabelled
        icon={['fas', 'user']}
        label="Client Name"
        name="name"
        required={true}
      />

      <InputGroupLabelled
        icon={['fas', 'at']}
        label="Client Email"
        name="email"
        type="email"
      />

      <InputPhoneLabelled 
        label="Client Phone"
        validated={validated}
      />

    </section>
  )
}