import { useEffect, useState } from 'react'
import Swal from 'react-bootstrap-sweetalert'

export default function SweetAlertOnLine() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const onLineChk = setInterval(() => setShow(!navigator?.onLine), 1000);

    return () => clearInterval(onLineChk);
  }, []);

  return (
    <>
      <Swal 
        type="info"
        title="You are offline"
        allowEscape={false}
        disabled={true}
        show={show}
        showCancel={false}
        showConfirm={false}
        onConfirm={() => null}
        openAnim={false}>
      </Swal>
    </>
  )
}