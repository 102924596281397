import { Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ValidationAlert({ showValidationAlert, validationMessage }) {
  return (
    <Alert 
      className="mb-3 d-flex align-items-center text-break"
      show={showValidationAlert} 
      variant="danger" 
    >
      <FontAwesomeIcon icon={['far', 'triangle-exclamation']} fixedWidth size="2x" />
      <div className="ml-4">
        <p>{validationMessage?.title ?? 'Error!'}</p>
        <p 
          className="m-0" 
          style={{ whiteSpace: 'break-spaces' }}
        >{validationMessage?.body}</p>
      </div>
    </Alert>
  );
};