import { Link } from 'react-router-dom'
import LogInForm from './LogInForm'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'
import FrontendRoutes from 'assets/js/classes/FrontendRoutes'

export default function Login() {
  return (
    <>
      <h4 className="mb-0 d-flex flex-column">
        Welcome back.
        <span>Please sign in to your account.</span>
      </h4>

      <div className="divider row"></div>
      
      <SubmitBtnProvider>
        <LogInForm />
      </SubmitBtnProvider>

      <div className="mt-2 d-flex flex-row-reverse">
        <Link to={FrontendRoutes.ACCOUNT_PASSWORD_FORGOT}>Forgot Password</Link>
      </div>
    </>
  );
};