import React from 'react';

const DynamicBoardStateContext = React.createContext();
const DynamicBoardDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useDynamicBoardState() {
  const context = React.useContext(DynamicBoardStateContext);
  if(!context) throw new Error('useDynamicBoardState must be used in DynamicBoardProvider');
  return context;
}

function useDynamicBoardDispatch() {
  const context = React.useContext(DynamicBoardDispatchContext);
  if(!context) throw new Error('useDynamicBoardDispatch must be used in DynamicBoardProvider');
  return context;
}

function DynamicBoardProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { buckets: [], scrollSnap: true });

  return (
    <DynamicBoardStateContext.Provider value={state}>
      <DynamicBoardDispatchContext.Provider value={dispatch}>
        {props.children}
      </DynamicBoardDispatchContext.Provider>
    </DynamicBoardStateContext.Provider>
  )
}

function stateUpdateBuckets(dispatch, { buckets }) {
  dispatch({ buckets });
}

function stateSetScrollSnap(dispatch, scrollSnap) {
  dispatch({ scrollSnap });
}

export { DynamicBoardProvider, stateSetScrollSnap, stateUpdateBuckets, useDynamicBoardDispatch, useDynamicBoardState };