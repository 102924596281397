import { useEffect } from 'react'

export default function useCreatePaymentRequest({ paymentItem, setPaymentRequest, stripe }) {
  useEffect(() => {
    if(stripe) {
      const pr = stripe.paymentRequest({
        country: 'IE',
        currency: 'eur',
        total: { ...paymentItem },
        requestPayerName: true,  // Collects the payer’s billing address for Apple Pay
        requestPayerEmail: true,
      });

      pr.canMakePayment()  // Check the availability of the Payment Request API
        .then(result => {
          if(result) setPaymentRequest(pr);
        });
    }
  }, [stripe]);
}