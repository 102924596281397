import handleToast from './handleToast'

export default function eventFrontendVersionUpdated(data, { toasts, toastsDispatch, updateToasts }) {
  if( !window.localStorage.getItem('frontend-version-update') ) {
    handleToast({
      title: 'New Update Available', 
      message: 'An update for the web app is available. Please refresh the page to get the latest version.', 
      autohide: false,
      label: 'Refresh', 
      callback: () => window.location.reload(), 
    }, { toasts, toastsDispatch, updateToasts });
  }
  window.localStorage.setItem('frontend-version-update', data?.version);
}