import { Button, Modal } from 'react-bootstrap'
import QRCode from 'react-qr-code'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { stateUpdateQrCodeModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext';

export default function ModalQrCode({ task }) {
  // CONTEXT PARAMS
  const { showQrCodeModal } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  const printQr = () => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    mywindow.document.write('<style>* { text-align: center; margin: 0 auto; font-size: 24px; font-family: sans-serif }</style>');
    mywindow.document.write('</head><body>');
    // mywindow.document.write('<h1>' + document.title  + '</h1>');
    mywindow.document.write(document.getElementById('qrCodeBody').innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();
  }

  return (
    <Modal id="taskModalQrCode" show={showQrCodeModal} onHide={() => stateUpdateQrCodeModal(taskModalDispatch, { showQrCodeModal: false })} size="lg">
      <Modal.Header closeButton className="align-items-center">
        <Modal.Title>
          QR Code
        </Modal.Title>
      </Modal.Header>

      <Modal.Body id="qrCodeBody" className="align-items-center d-flex flex-column">
        <div id="qrCode" className="bg-light p-4">
          <QRCode size={256} value={window.location.href} />
        </div>

        { task.client?.name !== '' &&
          <h2 className="mt-2 mb-0">{task.client?.name}</h2>
        }

        <h5 className="mt-2 mb-0">#{task.id}</h5>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={printQr}>
          <FontAwesomeIcon icon={['far', 'print']} fixedWidth />
          Print QR Code
        </Button>
      </Modal.Footer>
    </Modal>
  )
}