import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { stateUpdateQrCodeModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'

export default function QRCode() {
  // CONTEXT PARAMS
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  return (
    <section className="mb-1">
      <Button
        className="border-0 w-100 text-left"
        disabled={!Boolean(Object.values(task).length) || Boolean(Number(task?.archived))}
        onClick={() => stateUpdateQrCodeModal(taskModalDispatch, { showQrCodeModal: true })}
        variant="light"
      >
        <FontAwesomeIcon icon={['far', 'qrcode']} fixedWidth className="mr-1" />
        QR Code
      </Button>
    </section>
  )
}