import React from 'react';

const SweetAlertStateContext = React.createContext();
const SweetAlertDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useSweetAlertState() {
  const context = React.useContext(SweetAlertStateContext);
  if(!context) throw new Error('useSweetAlertState must be used in SweetAlertProvider');
  return context;
}

function useSweetAlertDispatch() {
  const context = React.useContext(SweetAlertDispatchContext);
  if(!context) throw new Error('useSweetAlertDispatch must be used in SweetAlertProvider');
  return context;
}

function SweetAlertProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { alert: {} });

  return (
    <SweetAlertStateContext.Provider value={state}>
      <SweetAlertDispatchContext.Provider value={dispatch}>
        {props.children}
      </SweetAlertDispatchContext.Provider>
    </SweetAlertStateContext.Provider>
  )
}

function updateSweetAlert(dispatch, { alert }) {
  dispatch({ alert });
}

export { SweetAlertProvider, updateSweetAlert, useSweetAlertDispatch, useSweetAlertState };