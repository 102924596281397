import { Card } from 'react-bootstrap'
import { SubmitBtnProvider } from 'contexts/SubmitBtnContext'
import { UserEditProvider } from 'contexts/UserEditContext'
import Main from 'layouts/Main'
import DeleteUserForm from './DeleteUserForm'
import EditUserForm from './EditUserForm'
import SelectUserForm from './SelectUserForm'

export default function EditUser() {
  return (
    <Main title="Update/Remove" subtitle="User" icon={'user-pen'}>
      <UserEditProvider>
        <SelectUserForm />
        <Card>
          <Card.Body>

            <SubmitBtnProvider>
              <EditUserForm />
            </SubmitBtnProvider>

            <SubmitBtnProvider>
              <DeleteUserForm />
            </SubmitBtnProvider>

          </Card.Body>
        </Card>
      </UserEditProvider>
    </Main>
  )
}