import React from 'react';
import { DynamicBoardProvider } from 'contexts/DynamicBoardContext';
import { SearchBarProvider } from 'contexts/SearchBarContext';

export default function DynamicBoardWrapper(props) {
  return (
    <DynamicBoardProvider>
      <SearchBarProvider>
        {props.children}
      </SearchBarProvider>
    </DynamicBoardProvider>
  )
}