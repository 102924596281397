import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

export default function SidebarDropdown({ children, icon, title }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <li>
      <a onClick={() => setDropdownOpen(!dropdownOpen)} className={`overflow-hidden ${dropdownOpen ? 'active' : ''}`}>
        <FontAwesomeIcon icon={['fad', icon]} fixedWidth className="metismenu-icon" />
        <span>{title}</span>
        <FontAwesomeIcon icon={['far', 'angle-down']} fixedWidth className="metismenu-state-icon" />
      </a>
      <ul>
        {children}
      </ul>
    </li>
  )
}