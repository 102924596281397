import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { AddressElement, PaymentElement, PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js'
import SB from 'assets/js/classes/SubmitBtn'
import formatStripeError from 'assets/js/utilities/formatStripeError'
import { urlFrontend } from 'assets/js/utilities/helpers'
import SubmitBtn from 'components/forms/SubmitBtn'
import ValidationAlert from 'components/ui/ValidationAlert'
import { useAuthState } from 'contexts/AuthContext'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'
import { useTenantState } from 'contexts/TenantContext'
import useCreatePaymentRequest from 'hooks/stripe/useCreatePaymentRequest'
import useListenerPaymentMethod from 'hooks/stripe/useListenerPaymentMethod'
import useUpdatePaymentRequest from 'hooks/stripe/useUpdatePaymentRequest'

export default function Checkout({ amount, clientSecret, setConfirmationMessage }) {
  // STRIPE PARAMS
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const paymentItem = { amount, label: 'SMS Top Up', pending: false };

  // STATE PARAMS
  const [traditionalForm, setTraditionalForm] = useState(false);
  const [validationMessage, setValidationMessage] = useState({});

  // CONTEXT PARAMS - AUTH
  const { tenant } = useTenantState();
  const { user } = useAuthState();

  // CONTEXT PARAMS - SUBMIT BUTTON
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  useCreatePaymentRequest({ paymentItem, setPaymentRequest, stripe });
  useUpdatePaymentRequest({ paymentItem, paymentRequest });
  useListenerPaymentMethod({ clientSecret, paymentRequest, stripe, setConfirmationMessage, setValidationMessage })

  const handleTraditionalPaymentSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    // RESET INVALID FEEDBACK
    setValidationMessage({});

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // DISABLE BUTTON
    stateUpdateSubmitBtn(submitBtnDispatch, { btnState: SB.LOADING });

    const result = await stripe.confirmPayment({
      elements,  // `Elements` instance that was used to create the Payment Element
      confirmParams: {
        return_url: urlFrontend() + `sms/top-up/confirmation`,
      },
    });

    console.log({result});
    await stateUpdateSubmitBtn(submitBtnDispatch, { btnState: SB.RESPONSE, response: result });

    // DISPLAY ERROR MESSAGE
    // setShowValidationAlert(Object.hasOwn(response, 'error'));
    // setValidationMessage(response.data?.message || response.error_message || response.error?.message);

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error);
      setValidationMessage({
        title: result.error.code,
        body: formatStripeError(result.error),
      });
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    // RESTORE BUTTON
    stateUpdateSubmitBtn(submitBtnDispatch, { btnState: SB.DEFAULT });
  }

  return (
    <>
      { paymentRequest && !traditionalForm
        ? <>
            <ValidationAlert 
              showValidationAlert={Object.keys(validationMessage).length > 0} 
              validationMessage={validationMessage} />
            <PaymentRequestButtonElement onClick={() => setValidationMessage({})} options={{ paymentRequest }} />
          </>
        : <Form onSubmit={handleTraditionalPaymentSubmit}>
            <AddressElement className="mb-2" options={{
              mode: 'billing', 
              defaultValues: { name: user.name, address: tenant?.address?.billing ?? tenant?.address?.shipping }
            }} />
            
            <PaymentElement className="mb-2" />

            <ValidationAlert 
              showValidationAlert={Object.keys(validationMessage).length > 0} 
              validationMessage={validationMessage} 
              />

            <SubmitBtn 
              disabled={!stripe || !elements} 
              icon={['far', 'credit-card']} 
              loadStatus="Processing ..."
              response={response}
              state={btnState}
              text={`Pay €${(amount / 100).toFixed(0)}`}
              />
          </Form>
      }
      <div className="d-grid mt-3">
        <Button 
          onClick={() => setTraditionalForm(state => !state)}
          size="lg" 
          type="button" 
          variant="light" 
        >Change Payment Method</Button>
      </div>
    </>
  )
}