import { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import capitalizeFirstLetter from 'assets/js/utilities/capitalizeFirstLetter'
import { stripSmsHistoryModalFromPath } from 'assets/js/utilities/stripPaths'
import useApiCall from 'hooks/useApiCall'

export default function SmsDetailsModal() {
  // ROUTER DOM PARAMS
  const params = useParams();

  // STATE PARAMS
  const [sms, setSms] = useState(null);

  // CLOSE MODAL REDIRECT
  const navigate = useNavigate();
  const handleClose = () => navigate(stripSmsHistoryModalFromPath());

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: `${ApiRoutes.SMS}/${params.sid}`,
    callback: ({ sms }) => setSms(sms),
  });

  return (
    <Modal id="smsHistoryModal" show={true} onHide={handleClose} size="lg" keyboard={false}>
      <Modal.Header closeButton className="bg-white align-items-center">
        <Modal.Title className="flex-fill">
          {sms?.status === undefined
            ? <Skeleton count={1} />
            : <>{capitalizeFirstLetter(sms?.status ?? '')} <FontAwesomeIcon icon={['far', 'arrow-right']} fixedWidth className="mr-1" /> {sms?.recipient}</>
          }
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {sms?.body === undefined
          ? <Skeleton count={5} />
          : <Form.Control as="textarea" style={{height: '6rem'}} value={sms?.body ?? ''} disabled />
        }
      </Modal.Body>
    </Modal>
  )
}