import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import { Status as Stat } from 'assets/js/classes/Task'
import FormSelect from 'components/forms/FormSelect'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import { FormControlProvider } from 'contexts/FormControlContext'

export default function Status() {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();
  
  return (
    <section className="mb-3">
      <h6 className="mb-1">
        <FontAwesomeIcon icon={['far', 'spinner-third']} fixedWidth /> STATUS
      </h6>
      
      <Form 
        autoComplete="off"
        noValidate 
        onSubmit={undefined}
      >
        <FormControlProvider>
          <FormSelect
            disabledProp={!Boolean(Object.values(task).length) || Boolean(Number(task?.archived))}
            modelContext={{ task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
            name="status"
            onChange={ModalTaskClass.handleInputSubmit}
            value={task?.status}
          >
            <option value={Stat.NEW}>{Stat.NEW}</option>
            <option value={Stat.IN_PROGRESS}>{Stat.IN_PROGRESS}</option>
            <option value={Stat.ON_HOLD}>{Stat.ON_HOLD}</option>
            <option value={Stat.COMPLETE}>{Stat.COMPLETE}</option>
          </FormSelect>
        </FormControlProvider>
      </Form>
    </section>
  )
}