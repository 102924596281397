import React from 'react'
import { Link } from 'react-router-dom'
import SidebarToggle from '../Sidebar/SidebarToggle'
import LoggedInAs from './LoggedInAs'
import Logout from './Logout'
import Logo from 'components/ui/Logo'

export default function Header() {
  return (
    <div className="app-header header-shadow bg-transparent">

      {/* HEADER BUTTON 1 */}
      <div className="app-header__logo">
        <Link to="/" className="text-decoration-none">
          <Logo className="mr-2 logo-src" />
          {/* <div className="logo-src"></div> */}
          <span className="m-0 logo-src">Collaboro</span>
        </Link>

        <div className="header__pane ml-auto">
          <SidebarToggle />
        </div>
      </div>

      {/* HEADER BUTTON 2 */}
      <div className="app-header__mobile-menu">
        <SidebarToggle className="mobile-toggle-nav" />
        <Link to="/" className="text-decoration-none">
          <Logo className="ml-4 logo-src" />
        </Link>
      </div>

      <div className="app-header__content">
        <div className="app-header-left"></div>

        <div className="app-header-right d-none d-md-inline">
          <div className="header-btn-lg p-0">
            <div className="widget-content p-0">
              <div className="widget-content-wrapper">
                {/* <LanguageDropdown /> */}
                {/* <AccountDropdown /> */}
                <LoggedInAs />
                <Logout />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}