import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { AxiosMethods } from 'assets/js/utilities/axios'
import InputGroupFloating from 'components/forms/InputGroupFloating'
import SubmitBtn from 'components/forms/SubmitBtn'
import ValidationAlert from 'components/ui/ValidationAlert'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'
import { updateUserEdit, useUserEditDispatch, useUserEditState } from 'contexts/UserEditContext'

export default function EditUserForm() {
  // CONTEXT PARAMS - UserEdit
  const { user } = useUserEditState();
  const userEditDispatch = useUserEditDispatch();

  // VALIDATION PARAMS
  const [formValidated, setFormValidated] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState({});

  // FORM PARAMS
  const [name, setName] = useState(user?.name ?? '');
  const [email, setEmail] = useState(user?.email ?? '');
  const [username, setUsername] = useState(user?.username ?? '');
  const [invalidFeedback, setInvalidFeedback] = useState({});
  
  // SUBMIT BUTTON CONTEXT
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  const handleSubmit = async e => {
    // RESET INVALID FEEDBACK
    setInvalidFeedback({});

    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.PATCH, route: `${ApiRoutes.USERS}/${user?.id}` });

    await submitter.validate({setFormValidated, setShowValidationAlert, setValidationMessage, setInvalidFeedback});

    if(submitter?.success) {
      // RESET FORM/CONTEXT
      updateUserEdit(userEditDispatch, { user: null });
    }
  }

  useEffect(() => {
    setName(user?.name ?? '');
    setEmail(user?.email ?? '');
    setUsername(user?.username ?? '');
  }, [user])

  return (
    <Form 
      autoComplete="off" 
      noValidate 
      onSubmit={handleSubmit}
      validated={Object.keys(invalidFeedback).length === 0 && formValidated} 
    >
      <InputGroupFloating 
        className="mb-4" 
        disabled={user?.id === undefined}
        icon={['far', 'user']} 
        invalidFeedback={invalidFeedback}
        label="Name" 
        name="name" 
        onChange={e => setName(e.target.value)} 
        required
        type="text" 
        value={name} 
      />
      
      <InputGroupFloating 
        className="mb-4" 
        disabled={user?.id === undefined}
        icon={['far', 'at']} 
        invalidFeedback={invalidFeedback}
        label="Email Address" 
        name="email" 
        onChange={e => setEmail(e.target.value.trim())}
        required
        type="email" 
        value={email} 
      />
      
      <InputGroupFloating 
        className="mb-4" 
        disabled={user?.id === undefined}
        icon={['far', 'id-badge']} 
        invalidFeedback={invalidFeedback}
        label="Username" 
        name="username" 
        onChange={e => setUsername(e.target.value.trim())}
        required
        type="text" 
        value={username} 
      />

      <ValidationAlert 
        showValidationAlert={showValidationAlert} 
        validationMessage={validationMessage} />

      <SubmitBtn 
        className="mb-3" 
        disabled={user?.id === undefined}
        icon={['fas', 'floppy-disk']} 
        response={response} 
        state={btnState}
        text="Save"
      />
    </Form>
  )
}