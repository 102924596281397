import { useEffect } from 'react'
import { createSearchParams, Navigate, Outlet, useLocation } from 'react-router-dom'
import Header from '../Header'
import AppContainer from '../AppContainer'
import Sidebar from '../Sidebar'
import Toasts from 'components/ui/Toasts'
import SweetAlert from 'components/ui/SweetAlert'
import SweetAlertOnLine from 'components/ui/SweetAlertOnLine'
import { useAuthState } from 'contexts/AuthContext'
import { useTenantDispatch, updateTenant, useTenantState } from 'contexts/TenantContext'
import { SidebarProvider } from 'contexts/SidebarContext'
import { ToastsProvider } from 'contexts/ToastsContext'
import { SweetAlertProvider } from 'contexts/SweetAlertContext'
import SidebarOverlay from '../Sidebar/SidebarOverlay'
import t from 'data/tenant.json'
import useNavigateUnauthenticated from 'hooks/useNavigateUnauthenticated'
import { axiosClient, AxiosMethods } from 'assets/js/utilities/axios'
import useApiCall from 'hooks/useApiCall'
import ApiRoutes from 'assets/js/classes/ApiRoutes'

export default function Authenticated() {
  // CONTEXT PARAMS
  const { user } = useAuthState();
  const { tenant } = useTenantState();
  const tenantDispatch = useTenantDispatch();

  // RETRIEVE TENANT
  useApiCall({ 
    apiMethod: AxiosMethods.GET, 
    apiRoute: ApiRoutes.TENANT, 
    callback: ({tenant}) => updateTenant(tenantDispatch, { tenant: tenant ?? {} }),
  });

  return useNavigateUnauthenticated(user) || (
    <SidebarProvider>
      {/* <ToastsProvider> */}
        <SweetAlertProvider>
          <AppContainer>
            <SweetAlertOnLine />
            {/* <Toasts /> */}
            <SweetAlert />
            <Header />
            <div className="app-main d-flex">
              <Sidebar />
              <div className="app-main__outer overflow-hidden">
                <SidebarOverlay />
                <Outlet />
              </div>
            </div>
          </AppContainer>
        </SweetAlertProvider>
      {/* </ToastsProvider> */}
    </SidebarProvider>
  );
}