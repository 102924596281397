import { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { stateToggleSidebar, useSidebarDispatch, useSidebarState } from 'contexts/SidebarContext'
import Responsive from 'assets/js/classes/Responsive'

export default function SidebarToggle({ className }) {
  // SIDEBAR CONTEXT
  const sidebarState = useSidebarState();
  const sidebarDispatch = useSidebarDispatch();

  useEffect(() => {
    let width = window.innerWidth;
    const handleResize = () => {
      if(sidebarState.open && window.innerWidth < Responsive.MOBILE_WIDTH_MAX && window.innerWidth !== width) stateToggleSidebar(sidebarDispatch, { open: false });
    };
    window.addEventListener('resize', handleResize, false);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Button type="button" variant={null} 
      className={`hamburger hamburger--elastic close-sidebar-btn ${className ?? ''} ${sidebarState.open ? 'is-active' : ''}`}
      onClick={() => stateToggleSidebar(sidebarDispatch, { open: !sidebarState.open })}>
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </Button>
  )
}