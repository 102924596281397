import { useEffect } from 'react'
import formatStripeError from 'assets/js/utilities/formatStripeError'
import formatStripeSuccess from 'assets/js/utilities/formatStripeSuccess'

export default function useListenerPaymentMethod({ clientSecret, paymentRequest, stripe, setConfirmationMessage, setValidationMessage }) {
  useEffect(() => {
    if(! paymentRequest) return;
    
    paymentRequest.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
        clientSecret,
        {payment_method: ev.paymentMethod.id},
        {handleActions: false}
      );
    
      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');

        setValidationMessage({
          title: confirmError?.code,
          body: formatStripeError(confirmError),
        });
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const {error} = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
          } else {
            // The payment has succeeded -- show a success message to your customer.
          }
        } else {
          // The payment has succeeded -- show a success message to your customer.
        }

        setConfirmationMessage({
          title: paymentIntent?.status,
          body: formatStripeSuccess(paymentIntent),
        });
      }
    });
  }, [paymentRequest]);
}