import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { axiosClient } from 'assets/js/utilities/axios'
import { stateLogin, stateLogout, useAuthDispatch } from 'contexts/AuthContext'

const handleAuthUserCheck = async authDispatch => {
  // MAKE API CALL
  const response = await axiosClient.apiCall(() => axiosClient.get(ApiRoutes.ACCOUNT));

  if(response?.error?.response?.status === 401) stateLogout(authDispatch);

  const user = response?.data?.user;
  if(user) stateLogin(authDispatch, { user: { ...user } });
}

export default function useAuthUserCheck() {
  // AUTH CONTEXT
  const authDispatch = useAuthDispatch();

  // REACT ROUTER
  const location = useLocation();

  useLayoutEffect(() => {
    handleAuthUserCheck(authDispatch);
  }, [location]);
}