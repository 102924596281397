import Footer from 'layouts/Footer'
import Title from './Title'

export default function Main({ title, subtitle, icon, children }) {
  return (
    <>
      <div className="app-main__inner p-0">
        <Title title={title} subtitle={subtitle} icon={icon} />
        <div className="px-4">
          {children}
        </div>
      </div>
      <Footer />
    </>
  )
}