import { useState } from 'react'
import { Card } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApiChannels from 'assets/js/classes/ApiChannels'
import ApiEvents from 'assets/js/classes/ApiEvents'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import { AxiosMethods } from 'assets/js/utilities/axios'
import handleToast from 'assets/js/utilities/handleToast'
import { useTenantState } from 'contexts/TenantContext'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import useApiCall from 'hooks/useApiCall'
import useWebSocket from 'hooks/useWebSocket'

export default function Balance() {
  // STATE PARAMS
  const [balance, setBalance] = useState(undefined);
  const [loading, setLoading] = useState(true);

  // CONTEXT PARAMS - TENANT
  const { tenant } = useTenantState();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: ApiRoutes.CASHIER_CUSTOMER_BALANCE,
    callback: ({ balance }) => setBalance(balance),
    setLoading,
  });

  useWebSocket(ApiChannels.PRIVATE_TENANT(tenant?.id), [
    {
      events: [ApiEvents.CUSTOMER_BALANCE_CREDITED, ApiEvents.CUSTOMER_BALANCE_DEBITED],
      callback: ({ transaction }) => setBalance(balance => transaction?.ending_balance ?? 0),
    },
    {
      events: [ApiEvents.CUSTOMER_BALANCE_CREDITED],
      callback: ({ transaction }) => handleToast({
          title: 'Balance Updated',
          message: `A payment for "${transaction?.description}" was received and your balance has updated.`,
          autohide: true
        }, { toasts, toastsDispatch, updateToasts }),
    },
    {
      events: [ApiEvents.CUSTOMER_BALANCE_DEBITED],
      callback: ({ transaction }) => handleToast({
          title: 'Balance Updated',
          message: `An expense "${transaction?.description}" was processed and your balance has updated.`,
          autohide: true
        }, { toasts, toastsDispatch, updateToasts }),
    },
  ]);

  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={['far', 'wallet']} fixedWidth className="mr-1" /> Balance
      </Card.Header>
      <Card.Body>
        Your current balance is 
        { loading
          ? <Skeleton height={43} />
          : <h2>&euro;{(balance * -1 / 100).toFixed(2)}</h2>
        }
      </Card.Body>
    </Card>
  )
}