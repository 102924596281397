import { Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import FormButton from 'components/forms/FormButton'
import { FormControlProvider } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'

export default function Checklist() {
  // CONTEXT PARAMS - TASK MODAL
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  return (
    <section className="mb-1">
      <Form onSubmit={e => e.preventDefault()}>
        <FormControlProvider>
          <FormButton
            className="border-0 w-100 text-left"
            disabledProp={!Boolean(Object.values(task).length) || Boolean(Number(task?.archived))}
            modelContext={{ task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
            onClick={ModalTaskClass.handleCreateChecklist}
            variant="light" 
          ><FontAwesomeIcon icon={['fas', 'list-check']} fixedWidth className="mr-1" />
            Checklist
          </FormButton>
        </FormControlProvider>
      </Form>
    </section>
  )
}