import { escapeInput } from '../utilities/regex';

export default class Buckets {
  static filterByQuery(buckets, query) {
    if(query === '') return structuredClone(buckets);  // CLONE BUCKETS SO WE DONT MUTATE ORIGINAL DATA

    const escapedQuery = escapeInput(query);

    const bucketsClone = structuredClone(buckets);  // CLONE BUCKETS SO WE DONT MUTATE ORIGINAL DATA

    return bucketsClone.filter(bucket => {
      bucket.tasks = bucket.tasks?.filter(task => {

        return escapedQuery.split(' ').every(word => {
          const propsToSearch = [
            task.id,
            task.title,
            task.client?.name,
            task.client?.email,
            task.client?.phone,
            task.status,
            task.priority,
            task.notes,
            task.assignees?.map(user => user?.username).join(),
          ];

          return propsToSearch.some(val => String(val) && String(val).match(new RegExp(`${word}`, 'gi')));
          return propsToSearch.some(val => String(val) && String(val).match(new RegExp(`^${word}`, 'gi')));

          // MATCH ID
          if( String(task?.id).match(new RegExp(`^${word}`, 'gi')) )
            return true;

          // MATCH TITLE
          if( task?.title.match(new RegExp(`${word}`, 'gi')) )
            return true;

          // MATCH CLIENT NAME
          if( task?.client?.name.match(new RegExp(`${word}`, 'gi')) )
            return true;

          return false;
        });
      });
      return true;
    });
  }
}