import { useEffect, useRef } from 'react'
import { Form } from 'react-bootstrap'
import ModalTaskClass from 'assets/js/classes/ModalTask'
import { stateUpdateFormControl, useFormControlDispatch, useFormControlState } from 'contexts/FormControlContext'
import { useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import useStateCallback from 'hooks/useStateCallback'

export default function FormSelect({ className, children, modelContext, disabledProp, name, onChange, required, value }) {
  // CONTEXT PARAMS - FORM CONTROL
  const { disabled } = useFormControlState();
  const formControlDispatch = useFormControlDispatch();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();

  // STATE PARAMS
  const [val, setVal] = useStateCallback(value);

  const ref = useRef(null);

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <Form.Select
      className={className}
      disabled={disabled || disabledProp}
      name={name}
      // onChange={e => setVal(e.target.value, () => onChange(e, modelContext, { dispatch: formControlDispatch, update: stateUpdateFormControl }, { toasts, toastsDispatch }))}
      onChange={e => setVal(e.target.value, () => onChange(e, ref, val, setVal, modelContext, { dispatch: formControlDispatch, update: stateUpdateFormControl }, { toasts, toastsDispatch }))}
      ref={ref}
      required={required}
      value={val}
    >{children}
    </Form.Select>
  )
}