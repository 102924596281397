import { axiosClient, AxiosMethods } from 'assets/js/utilities/axios'
import handleApiError from 'assets/js/utilities/handleApiError'
import { updateToasts } from 'contexts/ToastsContext'

export default async function handleApiCall({ apiMethod, apiRoute, callback = (args) => undefined, formData, toasts, toastsDispatch } = {}) {
  let response;
  console.log({ apiMethod, apiRoute, formData })
  
  switch(apiMethod) {
    case AxiosMethods.GET:
      response = await axiosClient.apiCall(() => axiosClient.get(apiRoute));
    break;
    case AxiosMethods.DELETE:
      response = await axiosClient.apiCall(() => axiosClient.delete(apiRoute));
    break;
    case AxiosMethods.HEAD:
      response = await axiosClient.apiCall(() => axiosClient.head(apiRoute));
    break;
    case AxiosMethods.OPTIONS:
      response = await axiosClient.apiCall(() => axiosClient.options(apiRoute));
    break;
    case AxiosMethods.POST:
      response = await axiosClient.apiCall(() => axiosClient.post(apiRoute, formData));
    break;
    case AxiosMethods.PUT:
      response = await axiosClient.apiCall(() => axiosClient.put(apiRoute, formData));
    break;
    case AxiosMethods.PATCH:
      response = await axiosClient.apiCall(() => axiosClient.patch(apiRoute, formData));
    break;
  }

  if(response?.error) {
    handleApiError(response.error, { toasts, toastsDispatch, updateToasts });
  } else callback(response?.data);
  
  return response;
}