import { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import ApiChannels from 'assets/js/classes/ApiChannels'
import ApiEvents from 'assets/js/classes/ApiEvents'
import ApiRoutes from 'assets/js/classes/ApiRoutes'
import Submitter from 'assets/js/classes/Submitter'
import { AxiosMethods } from 'assets/js/utilities/axios'
import eventAuthUserUpdated from 'assets/js/utilities/eventAuthUserUpdated'
import ValidationAlert from 'components/ui/ValidationAlert'
import InputGroupFloating from 'components/forms/InputGroupFloating'
import SubmitBtn from 'components/forms/SubmitBtn'
import { stateUpdateSubmitBtn, useSubmitBtnDispatch, useSubmitBtnState } from 'contexts/SubmitBtnContext'
import { stateLogin, useAuthDispatch, useAuthState } from 'contexts/AuthContext'
import { useTenantState } from 'contexts/TenantContext'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'
import useApiCall from 'hooks/useApiCall'
import useWebSocket from 'hooks/useWebSocket'

export default function MyDetailsForm() {
  // CONTEXT PARAMS - AUTH USER
  const { user } = useAuthState();
  const authDispatch = useAuthDispatch();

  // CONTEXT PARAMS - TENANT
  const { tenant } = useTenantState();

  // CONTEXT PARAMS - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();
  
  // SUBMIT BUTTON CONTEXT
  const { btnState, response } = useSubmitBtnState();
  const submitBtnDispatch = useSubmitBtnDispatch();

  // VALIDATION PARAMS
  const [formValidated, setFormValidated] = useState(false);
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState({});

  // FORM PARAMS
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [invalidFeedback, setInvalidFeedback] = useState({});

  const handleSubmit = async e => {
    // RESET INVALID FEEDBACK
    setInvalidFeedback({});

    const submitter = new Submitter(e, 
      { stateUpdateSubmitBtn, submitBtnDispatch }, 
      { method: AxiosMethods.PATCH, route: ApiRoutes.ACCOUNT });

    await submitter.validate({setFormValidated, setShowValidationAlert, setValidationMessage, setInvalidFeedback});

    if(submitter?.success) {
      const user = submitter?.response?.data?.user;
      
      // UPDATE AUTH CONTEXT
      stateLogin(authDispatch, { user });
    }
  }

  useApiCall({
    apiMethod: AxiosMethods.GET,
    apiRoute: ApiRoutes.ACCOUNT,
    callback: ({ user }) => stateLogin(authDispatch, { user }),
    setLoading,
  });

  useWebSocket(ApiChannels.PRIVATE_TENANT_USER(tenant?.id, user?.id), [
    {
      events: [ApiEvents.USER_UPDATED],
      callback: 
        ({ author, user }) => eventAuthUserUpdated(
          { author, user, callback: () => stateLogin(authDispatch, { user }) }, 
          { toasts, toastsDispatch, updateToasts }
        ),
    },
  ]);

  useEffect(() => {
    setName(user?.name ?? '');
    setEmail(user?.email ?? '');
    setUsername(user?.username ?? '');
  }, [user]);

  return (
    <Form noValidate validated={invalidFeedback.length > 0 || formValidated} autoComplete="off" onSubmit={handleSubmit}>
      { loading
        ? <Skeleton className="mb-4" height={54} />
        : <InputGroupFloating 
            className="mb-4" 
            icon={['far', 'user']} 
            label="Name" 
            type="text" 
            name="name" 
            value={name} 
            onChange={e => setName(e.target.value)} 
            invalidFeedback={invalidFeedback}
            required />
      }
      
      { loading
        ? <Skeleton className="mb-4" height={54} />
        : <InputGroupFloating 
            className="mb-4" 
            icon={['far', 'at']} 
            label="Email Address" 
            type="email" 
            name="email" 
            value={email} 
            onChange={e => setEmail(e.target.value.trim())}
            invalidFeedback={invalidFeedback}
            required />
      }
      
      { loading
        ? <Skeleton className="mb-4" height={54} />
        : <InputGroupFloating 
            className="mb-4" 
            icon={['far', 'id-badge']} 
            label="Username" 
            type="text" 
            name="username" 
            value={username} 
            invalidFeedback={invalidFeedback}
            disabled />
      }

      <ValidationAlert 
        showValidationAlert={showValidationAlert} 
        validationMessage={validationMessage} />

      <SubmitBtn 
        disabled={loading}
        icon={['fas', 'floppy-disk']} 
        text="Save"
        state={btnState}
        response={response} />
    </Form>
  )
}