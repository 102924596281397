import { Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormButton from 'components/forms/FormButton'
import { FormControlProvider } from 'contexts/FormControlContext'
import { stateUpdateTaskModal, useTaskModalDispatch, useTaskModalState } from 'contexts/TaskModalContext'
import ModalTaskClass from 'assets/js/classes/ModalTask'

export default function Archive() {
  // CONTEXT PARAMS
  const { task } = useTaskModalState();
  const taskModalDispatch = useTaskModalDispatch();

  return (
    <section className="mb-1">
      <Form onSubmit={e => e.preventDefault()}>
        <input 
          name="archived"
          type="hidden"
          value={Number(!Boolean(Number(task?.archived)))}
        />
        <FormControlProvider>
          <FormButton
            alert={{
              title: Boolean(Number(task?.archived)) ? 'Restore task from archive?' : 'Move task to archive?',
              message: task?.title,
              options: {
                confirmBtnBsStyle: 'warning',
              }
            }}
            className="border-0 w-100 text-left"
            disabledProp={!Boolean(Object.values(task).length)}
            loadStatus={Boolean(Number(task?.archived)) ? 'Restoring...' : 'Archiving...'}
            modelContext={{ task, update: stateUpdateTaskModal, dispatch: taskModalDispatch }}
            onClick={ModalTaskClass.handleArchive}
            variant="warning" 
          ><FontAwesomeIcon icon={['far', Boolean(Number(task?.archived)) ? 'inbox-out' : 'box-archive']} fixedWidth className="mr-1" /> {Boolean(Number(task?.archived)) ? 'Restore from archive' : 'Archive'}
          </FormButton>
        </FormControlProvider>
      </Form>
    </section>
  )
}